import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Asset, EntityId } from "src/app/models/entity.model";
import { FarmerService } from "src/app/services/device.service";
import { EntityService } from "src/app/services/entity.service";
import { ISensorType, SENSOR_TYPE, TEMPERATURE_SENSOR } from "src/app/services/types.service";
import { UnitService } from "src/app/services/unit";
import { Index, indexColor, LoadingState } from "src/app/util";
import { IChartInfo, ITimeserie } from "../ag-plot/ag-plot";
import { ISensorSelection, ITableCell } from "./farmer-silo-table";
import { FarmerSilo } from "./farmer-silo.model";

// [checked]="isChecked(cell)" (ionChange)="onCheckChange(cell, $event)" [checked]="cell.isSelected"
@Component({
  selector: 'farmer-silo-history',
  styleUrls: ['farmer-silo.scss'],
  styles: [`
  
.cell-checkbox {
  display: block;
  width: 25px; height: 25px;
  margin: 5px;
}
  `],
  template: `

<ng-template #cellTemplate let-cell="cell" > 
  <ion-checkbox class="cell-checkbox" (ionChange)="onCheckChange(cell, $event)" [(ngModel)]="selection[cell.column.entityId.id + ':' + cell.key]" 
  ></ion-checkbox>
</ng-template>

<div class="ag-card flex-auto" style="padding: 8px 4px 4px;" fxFlex fxLayout="column">
  <div fxFlex class="temp-history-container md-padding">
    
    <ag-plot 
      [height]="260" [figures]="sensorChart.figures" 
      [entity]="silo.asset.id" [axis]="sensorChart.axes"
      [emptyMessage]="sensorChart.timeseries?.length == 0 ? 'Select cable sensors above to show history' : null"
      [timeseries]="sensorChart.timeseries"></ag-plot>
  </div>

  <farmer-silo-table 
    (onSelect)="onSensorSelection($event)"
    [minValue]="0" [maxValue]="50"
    [cellTemplate]="cellTemplate"
    [silo]="silo" [sensorType]="sensorType.id" ></farmer-silo-table>

  <div *ngIf="getSensorTypes().length > 1" class="sensors" style="display: flex">
    <div *ngFor="let type of getSensorTypes()" class="sensor" [class.active]="sensorType.id == type.id" (click)="setSensorType(sensorType)">{{sensorType.name}}</div>
  </div>
</div>  
  `
})
export class FarmerSiloHistory {
  @Input() silo: FarmerSilo
  @ViewChild('cellTemplate') cellTemplate: TemplateRef<any>
  
  sensorType: ISensorType = TEMPERATURE_SENSOR
  
  //selection: ITableCell[] = []
  selection: Index<boolean> = {}

  sensorChart: IChartInfo = {
    name: 'Sensor History', figures: [],
    timeseries: [], axes: [
      {show: true, position: 'left'},
      {show: true, position: 'bottom'}
    ]
  }
  constructor (
    private unit: UnitService, private route: ActivatedRoute
  ) {}

  getSensorTypes () { return this.silo.getSensorTypes() }

  ngOnInit () {
    this.route.queryParams.subscribe(params => {
      let selection = params['k']
      if (selection) {
        this.selection[selection] = true
      }
      if (params['t']) {
        let type = this.getSensorTypes().find(x => x.id == params['t'])
        if (type)
          this.setSensorType(type)
      }
    })
  }

  isTemperature () { return this.sensorType.id == SENSOR_TYPE.TEMP }
  isMoisture () { return this.sensorType.id == SENSOR_TYPE.HUM }

  isChecked (cell: ITableCell) {
  }

  cells: Index<ITableCell> = {}
  onCheckChange (cell: ITableCell, e) {
    let isChecked = e.detail.checked
    let key = cell.column.entityId.id + ':' + cell.key
    this.cells[key] = cell
    

    this.refresh()
  }

  refresh () {
    let selection: ITableCell[] = []
    for (var k in this.selection) {
      if (this.selection[k] && this.cells[k]) {
        selection.push(this.cells[k])
      }
    }
    //let selection = this.selection
    let timeseries: ITimeserie[] = []
    selection.map((cell, i) => {
      let is_temperature = cell.key.startsWith('temperature-')
      let unit = is_temperature ? this.unit.temperatureUnitSymbol() : this.unit.moistureUnitSymbol()
      timeseries.push({
        name: cell.key, label: cell.column.name + ' ' + cell.key, entityId: cell.column.entityId,
        color: indexColor(i), unit: unit
      })
    })
    Object.assign(this.sensorChart, {timeseries: timeseries})
  }

  setSensorType (type: ISensorType) {
    this.sensorType = type
  }
  onSensorSelection (selection: ISensorSelection) {
    this.selection = {}
    selection.sensors.map(s => {
      let cell = s.cell
      let key = cell.column.entityId.id + ':' + cell.key
      this.selection[key] = true
    })
    this.refresh()
  }
}



@Component({
  selector: 'farmer-silo-history-page',
  styleUrls: ['farmer-silo.scss'],
  template: `

<ion-header>
  <ion-toolbar>

  <ion-buttons slot="start">
    <ion-back-button defaultHref="/"></ion-back-button>
  </ion-buttons>
  <ion-title>{{asset?.label}} History</ion-title>
  <!--<ion-title>{{title}}</ion-title>-->
  <ion-buttons class="page-tab-list">
  </ion-buttons>
  <ion-buttons slot="primary">
      
  </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div *ngIf="loading.is_success()">
    <farmer-silo-history *ngIf="silo" [silo]="silo"></farmer-silo-history>
  </div>
</ion-content>
  `
})
export class FarmerSiloHistoryPage {
  @Input() entityId: string
  silo: FarmerSilo
  loading = new LoadingState()
  asset: Asset
  
  constructor (
    private farmer: FarmerService,
    private entityService: EntityService,
    private router: Router,
    private route: ActivatedRoute) {}

  _subscriptions = []
  ngOnInit () {
    this.loading.loading(true)
    
    this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    });
    this.reload()
  }
  async reload () {
    this.asset = await this.entityService.getAssetById(this.entityId)
    this.silo = this.farmer.getSilo(this.asset)
    this._subscriptions.push(this.silo.updated.subscribe(() => {
      //this.setTitle(this.asset.label)
      this.reload()
    }))
    this.reload()
    this.loading.success()
  }
}