import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { captureMessage } from "@sentry/angular";
import { AuthService } from "src/app/services/auth.service";
import { FarmerService, IActivateResult } from "src/app/services/device.service";
import { AGUserService } from "src/app/services/user.services";
import { AppState, selectIsAuthenticated } from "src/app/state";
import { TEXT } from "src/app/texts";


@Component({
  template: `
<ion-content class="dialog">
    
    <div class="dialog-background"></div>
    
    <div class="dialog-content">
      <div class='activate-content'>
        <div *ngIf="message" class='activate-message'>{{message|translate}}</div>
        <div *ngIf="error" class='activate-message'>{{error|translate}}</div>
        <div *ngIf="errorMessage" class='activate-message'>{{errorMessage}}</div>
        <div *ngIf="showRedirect">
          <ion-button (click)="redirect()" [translate]="text.general.continue"></ion-button>
        </div>
      </div>
    </div>

</ion-content>
  `,
  styles: [`

.activate-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.activate-message {
  display: flex;
  font-size: 20px;
  font-weight: bold;
}
  `]
})
export class CustomerActivateComponent {
  constructor (private farmer: FarmerService,
    private route: ActivatedRoute, private router: Router,
    private auth: AuthService, private user: AGUserService,
    private store: Store<AppState>
  ) {}
  message = ''
  errorMessage = ''
  error = ''
  text = TEXT
  showRedirect = false
  ngOnInit () {
    this.store.pipe(select(selectIsAuthenticated)).subscribe(
      (isAuthenticated) => {
        if (isAuthenticated) this.redirect()
      }
    );
    this.route.queryParams.subscribe(params => {
      let token = params['token'];
      if (token) {
        this.activate(token)
      }
    });
  }
  redirect () {
    this.router.navigateByUrl('/auth')
  }
  async activate (token: string) {
    this.message = this.text.profile.activating
    let authToken: IActivateResult | null = null
    try {
      authToken = await this.farmer.activateUser(token)
      this.auth.setUserFromJwtToken(authToken.token, authToken.refreshToken, true);
      this.message = this.text.profile.activated // 'Activated!'
      window.setTimeout(() => {this.showRedirect = true}, 1500)
    } catch (err) {
      captureMessage('account-activation failed', {extra: {'email-token': authToken}, level: 'info'})
      this.message = ''
      this.error = this.text.profile.activate_failed
      if (err.error?.message) {
        this.errorMessage = err.error.message
      }
      this.showRedirect = true
    }
    
  }
}


@Component({
  template: `
  <ion-content >
    <div class="login-page">
      <div class="ag-card login-frame">
        <div class="login-frame-inner">
          <div class="login-form-header" [translate]="text.profile.reset_password"></div>
          <div class="login-content">
            <form (ngSubmit)="resetPassword()" >
              <div class="login-field-label">{{text.profile.new_password|translate}}:</div>
              <div style="margin-top: 0" class="login-field">
                <input name="password" placeholder="New Password" tabIndex="1" autocomplete="off" [type]="passwordVisible ? 'input' : 'password'" [(ngModel)]="settings.newPassword" autocapitalize="none" />
                <ion-icon class="input-icon" (mousedown)="setPasswordVisible($event, true)" [hidden]="passwordVisible" name="eye-off"></ion-icon>
                <ion-icon class="input-icon" (mousedown)="setPasswordVisible($event, false)" [hidden]="!passwordVisible" name="eye"></ion-icon>
              </div>
              <div class="form-footer">
                <ion-button name="reset-button"  [disabled]="!canResetPassword()" type="submit" [translate]="text.profile.set_password"></ion-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
  

  `,
  styleUrls: ['login-page.scss'],
  styles: [`

  .form-footer {
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;
  }
  .login-field-label {
    font-weight: bold;
    font-size: 14px;
    padding: 10px 2px;
  }
  .login-form-header {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 15px;
  }
.activate-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.activate-message {
  display: flex;
  font-size: 20px;
  font-weight: bold;
}
  `]
})
export class ResetPasswordComponent {
  settings = {newPassword: ''}
  token: string
  ready = false
  error = false
  passwordVisible = false
  text = TEXT
  constructor (
    private store: Store<AppState>,
    private farmer: FarmerService,
    private route: ActivatedRoute, 
    private router: Router,
    public alertController: AlertController,
    private translate: TranslateService
  ) {}

  setPasswordVisible (evt: MouseEvent, v: boolean) {
    evt.stopPropagation()
    evt.preventDefault()
    this.passwordVisible = v
    return false
  }

  canResetPassword () { return this.settings.newPassword.length > 5 }

  async resetPassword () {
    if (!this.token) return this.on_failure() // TODO: error message
    try {
      let result = await this.farmer.resetPassword(this.token, this.settings.newPassword)
      this.redirect()    
    } catch (err) {
      captureMessage('user failed to reset password', {extra: {'email-token': this.token}, level: 'info'})
      this.on_failure()
    }
  }
  
  async on_failure () {
    let msg = this.translate.instant(this.text.profile.reset_failed)
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.profile.reset_failed_title),
      message: msg,
      buttons: [
        {
          text: this.translate.instant(this.text.general.ok),
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });

    await alert.present();
  }

  async redirect () {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.profile.reset_success_title),
      message: this.translate.instant(this.text.profile.reset_success),
      buttons: [
        {
          text: this.translate.instant(this.text.general.ok),
          cssClass: 'secondary',
          handler: () => {
            this.router.navigateByUrl('/auth')
          }
        }
      ]
    });
    await alert.present();
  }
  ngOnInit () {
    this.store.pipe(select(selectIsAuthenticated)).subscribe(
      // TODO: should we redirect if authenticated ??
      /*(isAuthenticated) => {
        if (isAuthenticated) this.redirect()
      }*/
    );
    this.route.queryParams.subscribe(params => {
      let token = params['token'];
      this.token = token
      this.ready = true
      
    });
  }
}