import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState, selectAuth, selectAuthUser } from '../state';


@Injectable()
export class AGUserService {
  
  constructor (
    private store: Store<AppState>
  ) {}

  selectAuthUser () { return this.store.select(selectAuthUser) }

  getAuthUser () {
    return this.store.select(state => state.auth?.authUser)
  }
  getUserDetails () {
    return this.store.select(selectAuth).subscribe(a => a.userDetails)
  }

  getCurrentUserDetails() {
    return this.getAuthUser();
  }
  
  
}

