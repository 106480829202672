import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { agTypes } from "src/app/ag-types";
import { Asset, Device, Entity } from "src/app/models/entity.model";
import { AggregationType } from "src/app/models/telemetry.model";
import { AlarmService } from "src/app/services/alarm.service";
import { FarmerService } from "src/app/services/device.service";
import { EntityService } from "src/app/services/entity.service";
import { TelemetryService } from "src/app/services/telemetry";



@Component({
  template: `
<div *ngIf="loaded" style="height: 100%">
<ag-plot style="height: 100%" [entity]="entity.id" [timeseries]="timeseries" [axis]="flotSettings.axes" ></ag-plot>
</div>
`,
  selector: 'test-building-telemetry'
})
export class TestBuildingTelemetry {
  entityId: string
  entity: Asset
  loaded = false

  flotSettings: any = {
    shadowSize: 0,
    axes: [
      {
        position: 'left', showLabels: false
      },
      {
        position: 'bottom',
        showLabels: false
      }
    ],
    grid: {
      outlineWidth: 0,
      margin: 0,
      minBorderMargin: 0
    },
    smoothLines: true
  };
  timeseries: any = [
    {
      name: 'test-telemetry',
      label: " Test Telemetry",
      color: agTypes.colors.maxColor
    }
  ]

  constructor (
    private farmer: FarmerService, 
    private telemetry: TelemetryService,
    private entityService: EntityService,
    private alarmService: AlarmService,
    private route: ActivatedRoute) {}

  async load () {
    this.entity = await this.entityService.getAssetById(this.entityId)

    //this.farmer.listEntities()
    let assetEntities = await this.entityService.getCustomerAssets()
    let deviceEntities = (await this.entityService.getDevicesByType()).data
    let entities: Entity<any>[] = [].concat(assetEntities).concat(deviceEntities)
    setTimeout(() => {
      let fentities = []
      fentities = entities.slice(0,4)
      fentities = [entities[0], entities[3]]
      fentities = [entities[1], entities[2]].concat(entities.slice(4, 8))
      //fentities = [entities[3]]
      fentities.map(entity => {
        this.alarmService.addEntity(entity)
      })
      //this.alarmService._addEntity(this.entity)
    }, 3000)
    this.alarmService.subscribe(alarms => {
      console.log('alarms updated:', alarms)
    })

    let building = this.farmer.getBuilding(this.entity)
    //this.alarmService._addEntity(this.entity)
    building.subscribe(() => {
      console.log('building view update')
    })

    /*this.telemetry.subscribe(this.entity.id, {
      agg: AggregationType.NONE, keys: ['current_avg_emc'], limit: 1,
      //interval: 1000 * 1000, timeWindow: 1000 * 1000 * 10
      interval: 1000, timeWindow: 1000 * 60, startTs: 0
      
    }, {
      onData: resp => {
        return true
      },
      onLoading: () => 0
    })*/
    

    this.loaded = true
  }

  ngOnInit () {
    /*let now = new Date().getTime()
    this.telemetry.updateSettings({
      agg: AggregationType.AVG, interval: 1000 * 10, timeWindow: 1000 * 60 * 30,
      mode: 'TIME-WINDOW', startTs: 0, endTs: 0
    })*/
    this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    });
    this.load()
  }
}