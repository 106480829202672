import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { Geolocation, PositionError } from "@ionic-native/geolocation/ngx";
import { Platform, ToastController } from "@ionic/angular";
import { BehaviorSubject, Subscription } from "rxjs";
import { first, debounceTime } from "rxjs/operators";
import { LocationAccuracy } from '@ionic-native/location-accuracy';
import { AuthService } from "./auth.service";
import { onAuthenticationChange, onResume } from "../util";
import { AppState } from "../state";
import { Store } from "@ngrx/store";
import { AttributeService } from "./entity.service";
import { AttributeScope } from "../models/telemetry.model";
import { PermissionService } from "../menus";
import { OpenNativeSettings } from "@awesome-cordova-plugins/open-native-settings/ngx";

import * as Geo from '@capacitor/geolocation'

export interface ILocation {
  coords?: {latitude: number, longitude: number, accuracy: number}, error?: number
  type?: string, ts?: number, date?: string
}

// GeolocationPosition is not available: https://stackoverflow.com/questions/65916073/angular-11-why-cant-the-compiler-find-geolocationposition-during-compiling
function parseLoc (loc: any, type: string): ILocation {
  let c = loc.coords
  return {
    coords: {
      latitude: c.latitude, longitude: c.longitude,
      accuracy: c.accuracy
    },
    type: type,
    ts: loc.timestamp, date: new Date(loc.timestamp).toString()
  }
}

function isPositionError (x): x is PositionError {
  return x && x.code != undefined
}


@Injectable()
export class LocationService {
  onLocation = new BehaviorSubject<ILocation>({})
  userId

  constructor (
    private geolocation: Geolocation, 
    private permissions: AndroidPermissions,
    private toast: ToastController,
    private store: Store<AppState>,
    private attributes: AttributeService,
    private nativeSettings: OpenNativeSettings,
    private platform: Platform,
    private permService: PermissionService
  ) {
    this.store.select(state => state.auth?.userDetails?.id).subscribe(userId => {
      if (userId != this.userId) {
        this.userId = userId
        this.logLocation()
      }
    })
    this.onLocation.subscribe(x => {
      let userId = this.userId
      if (!x.coords?.latitude || !x.coords?.longitude || !userId) return
      this.attributes.saveEntityAttributes(
        userId, AttributeScope.SERVER_SCOPE, [
          {key: 'latitude', value: x.coords.latitude},
          {key: 'longitude', value: x.coords.longitude}
        ]
      ).toPromise()
    })
    this.onLocation.subscribe(x => {
      if (x.coords) {
        localStorage.setItem('__LOCATION__', JSON.stringify(x))
      }
    })
    try {
      let data = localStorage.getItem('__LOCATION__')
      if (data) {
        let loc = JSON.parse(data)
        this.onLocation.next(loc)
      }
    } catch (err) {
      console.error('failed to load inital location')
    }
    this.autoLogLocation()
    onResume().subscribe(x => this.logLocation())
  }

  async logLocation () {
    let p = await this.checkPermissions()
    if (p == 'granted') {
      this.getCurrentLocation()
    }
  }

  async autoLogLocation () {
    let ahour = 1000 * 60 * 60 * 24
    await this.logLocation()
    setTimeout(() => this.autoLogLocation(), ahour)
  }

  get current () { return this.onLocation.getValue() }
  
  userErrorMessage () {
    if (!this.current.error) return ''
    else if (this.current.error == 1) return "gps location is blocked in app"
    return "unable to get gps location"
  }

  async requestWebPermission () {
    let state = await this.getWebPermissionState()
    if (state == 'granted' || state == 'prompt') {
      return true
    } else {
      return false
    }
  }

  async getWebPermissionState (): Promise<string> {
    if (navigator?.permissions?.query) {
      let state = await navigator.permissions.query({
        name: 'geolocation'
      })
      return state.state
    } else if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          pos => {
            this.onLocation.next(parseLoc(pos, 'BROWSER'))
            resolve('granted')
            
          },
          e => {
            resolve('denied')
          }
        );
      })
    } else {
      return 'denied'
    }
  }
  
  emitError () {
    //if (err?.error == 1)
    //this.onLocation.next({error: 1})
  }
  /*emitLocation (loc: ILocation) {
    this.onLocation.next({
      coords: {latitude: coord.latitude, longitude: coord.longitude, accuracy: 1}
    })
  }*/

  async getCurrentLocation (): Promise<ILocation | null> {
    let success = await this.activateGPS()
    this.permService.setPermission('GPS', success)
    if (success) {
      let pos: ILocation
      try {
        if (Capacitor.isNativePlatform()) {
          pos = await this.getMobileLocation()
        } else {
          pos = await this.getBrowserLocation()
        }
      } catch (err) {
        console.error('failed to get geo location')
        this.emitError()
        return null
      }
      //let loc = parseLoc(pos, 'BROWSER')
      this.onLocation.next(pos)
      return pos
    } else {
      this.emitError()
    }
    return null
  }

  private async getMobileLocation () {
    let pos = await Geo.Geolocation.getCurrentPosition()
    return parseLoc(pos, 'BROWSER')
  }

  private async getBrowserLocation () {
    
    return new Promise<ILocation>((_resolve, _reject) => {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            _resolve(parseLoc(position, 'BROWSER'))
          },
          (err) => {
            console.error('gps error', err)
            _resolve({error: err.code, type:'BROWSER'})
            //_reject('Geolocation: ' + err.message)
          }, { enableHighAccuracy: true, timeout: 8000, maximumAge: 0}
        );
      } else {
        _reject("Your browser doesn't support geolocation")
      }
    })
  }

  async checkPermissions () {
    if (Capacitor.isNativePlatform()) {
      let p = await Geo.Geolocation.checkPermissions()
      return p.location
    } else {
      let state = await this.getWebPermissionState()
      return state
    }
  }

  // https://enappd.com/blog/ionic-5-complete-guide-on-geolocation/141/#15f0
  async activateGPS () {
    if (Capacitor.isNativePlatform()) {
      let r = await Geo.Geolocation.requestPermissions()
      return r.location == 'granted'
    } else {
      return await this.requestWebPermission()
    }
  }
  
}
