import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ALARM_TYPES } from "src/app/constant";
import { PermissionService } from "src/app/menus";
import { EntityId, IAlarm, Index } from "src/app/models/entity.model";
import { AlarmService, IAlarmItem } from "src/app/services/alarm.service";
import { MessagingService } from "src/app/services/messaging.service";
import { TEXT } from "src/app/texts";
import { LoadingState } from "src/app/util";

export function createAlarmGroups (service: AlarmService, alarms: IAlarm[]) {
  let groups: Index<IAlarmGroup> = {}
  let foundTypes = []
  let validAlarms = alarms.filter(alarm => {
    //if (foundTypes.includes(alarm.type)) return false
    //foundTypes.push(alarm.type)
    return ALARM_TYPES.find(x => x.key == alarm.type) != undefined
  })
  
  

  let alarmItems = validAlarms.map(alarm => {
    let alarmType = ALARM_TYPES.find(x => x.key == alarm.type)
    let item: IAlarmItem = {type: alarmType, alarm: alarm, ts: alarm.createdTime, details: {}}
    try {
      let details = JSON.parse(alarm.details.message)
      item.details.sensorKey = details.sensorKey
      item.details.sensorId = details.sensorId
    } catch (err) {

    }
    if (!groups[alarm.originator.id]) {
      let name = service.getEntity(alarm.originator.id)?.label || alarm.originatorName
      groups[alarm.originator.id] = {
        name: name, alarms: [], id: alarm.originator, 
      }
    }
    groups[alarm.originator.id].alarms.push(item)
    return item
  })
  let result = Object.values(groups).sort((a, b) => a.name.localeCompare(b.name))
  return {alarms: alarmItems, groups: result}
}

export interface IAlarmGroup {
  id: EntityId<any>, name: string, alarms: IAlarmItem[]
}

// openEntity(group.id)
@Component({
  selector: 'entity-alarm-group',
  template: `
<div  fxLayout="column" class="ag-card alarm-card flex-auto" style="position: relative" >
  <ag-loading [loading]="clearing" [inline]="true" message=""></ag-loading>
  <div fxFlex class="ag-alarm-header flex-auto">
    <div (click)="onClick.emit()" fxFlex class="ag-alarm-header-title">{{name}}</div>
    <button class='small-button' [disabled]="isClearing" (click)="clearAlarms()">{{text.alarm.clear | translate}}</button>
  </div>
  <div>
    <div (click)="onClick.emit()" class="ag-alarm flex-auto" *ngFor="let alarm of alarms; trackBy:alarmKey" fxLayout="column">
      <div class="ag-alarm-content" class="flex-auto" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <ion-icon *ngIf="alarm.type.key == 'NO_TELEMETRY_ALARM'" class="ag-alarm-icon" name="wifi-outline"></ion-icon>
        <ion-icon *ngIf="alarm.type.key != 'NO_TELEMETRY_ALARM'" class="ag-alarm-icon" [ngStyle]="{fill: alarm.type.color, color: alarm.type.color}" [src]="alarm.type.icon" ></ion-icon>
        <div fxFlex class="ag-alarm-message">
          <div *ngIf="alarm.value" class="ag-alarm-value">{{alarm.value | number: '1.1-1'}} {{alarm.type.unit}}</div>
          <div fxFlex fxLayout="row" style="justify-content: space-between;" class="flex-auto ag-alarm-message">
            <div >{{alarm.type.title | translate:{sensorId: alarm.details?.sensorId} }}</div>
            <ag-time-since style="font-size: 12px; align-self: center;" [ts]="alarm.ts"></ag-time-since>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  `,
  styleUrls: ['alarm-page.scss']
})
export class EntityAlarmGroup {
  @Input('name') name: string
  @Input('alarms') alarms: IAlarmItem[]
  @Output('onClick') onClick = new EventEmitter()

  text = TEXT
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }


  clearing = new LoadingState()
  constructor (
    private alarmService: AlarmService, 
    private router: Router
  ) {}

  alarmKey (index: number, item: IAlarmItem) {
    return item.alarm.id
  }

  isClearing = false
  ngOnInit () {
    this.clearing.success()
    this.listen(this.clearing.events.subscribe(x => {
      this.isClearing = x.is_loading()
    }))
  }

  async clearAlarms () {
    this.clearing.loading(true)
    try {
      await this.alarmService.clearAlarms(this.alarms.map(x => x.alarm))
    } finally {
      this.clearing.success()
    }
  }
}

@Component({
  selector: 'alarm-page',
  templateUrl: 'alarm-page.html',
  styleUrls: ['alarm-page.scss']
})
export class AlarmPageComponent {
  alarms: IAlarmItem[] = []
  groups: IAlarmGroup[] = []
  text = TEXT
  loading = new LoadingState()
  
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  constructor (
    private alarmService: AlarmService, 
    private router: Router,
    private permissions: PermissionService,
    private translate: TranslateService,
    private fcm: MessagingService
  ) {}

  
  hasPermission = true
  

  populate (alarms: IAlarm[]) {
    let result = createAlarmGroups(this.alarmService, alarms)
    this.alarms = result.alarms
    this.groups = result.groups
  }
  

  ngOnInit () {
    this.loading.success()
    this.listen(
      this.alarmService.onUpdate.subscribe((alarms) => this.populate(alarms))
    )
    this.populate(this.alarmService.listAlarms())
    this.listen(
      this.permissions.state.subscribe(x => {
        this.hasPermission = x['NOTIFICATION']
      })
    )
    this.fcm.checkPermissions()
  }

  clickDeviceGroup(id: string) {
    this.router.navigateByUrl('/device/' + id)
  }

  openEntity (id: EntityId<any>) {
    if (id.entityType == 'ASSET') {
      this.router.navigateByUrl('/building/' + id.id)
    } else {
      this.router.navigateByUrl('/device/' + id.id)
    }
  }

  async clearAlarms (alarms: IAlarm[]) {
    this.loading.loading(true)
    try {
      await this.alarmService.clearAlarms(alarms)
    } finally {
      this.loading.success()
    }
  }
  clearAll () {
    this.clearAlarms(this.alarms.map(alarm => alarm.alarm))
  }
}
