import { Injectable } from '@angular/core';
import {types} from '../types'

@Injectable()
export class UnitService {

  temperatureUnit = types.tempUnits.celsius.value;

  
  updateTemperatureUnit(newTemperatureUnit) {
    this.temperatureUnit = newTemperatureUnit;
  }

  getTemperatureUnit() {
    return this.temperatureUnit;
  }

  moistureUnitSymbol () {
    return '%';
  }

  humidityUnitSymbol () {
    return '% (RH)'
  }

  temperatureUnitSymbol() {
      return types.tempUnits[this.temperatureUnit].symbol;
  }

  toUserTemperature(temperature, absolute?) {
      if (this.temperatureUnit == types.tempUnits.celsius.value) {
          return temperature;
      } else {
          if (temperature == undefined || temperature == null) {
              return temperature;
          } else {
              //from celsius to fahrenheit
              var result = temperature * 1.8;
              if (!absolute) {
                  result += 32;
              }
              return Math.round(result);
          }
      }
  }

  fromUserTemperature(temperature, absolute) {
      if (this.temperatureUnit == types.tempUnits.celsius.value) {
          return temperature;
      } else {
          if (temperature == undefined || temperature == null) {
              return temperature;
          } else {
              //from fahrenheit to celsius\
              var result = temperature;
              if (!absolute) {
                  result -= 32;
              }
              return Math.round(result / 1.8);
          }
      }
  }

  temperaturePostprocessingFuncBody(absolute) {
      if (this.temperatureUnit == types.tempUnits.celsius.value) {
          return celciusPostFuncBody;
      } else {
          if (absolute) {
              return absoluteFahrenheitPostFuncBody;
          } else {
              return fahrenheitPostFuncBody;
          }
      }
  }

  round(value) {
      return parseFloat((value).toFixed(3));
  }

}

const celciusPostFuncBody = 'return value;';

const fahrenheitPostFuncBody = 'if ((typeof value === undefined) || value == null) ' +
    '{ ' +
    'return value; ' +
    '} ' +
    'else { ' +
    'var result = (value * 1.8) + 32;' +
    'return parseFloat((result).toFixed(3));' +
    '}';

const absoluteFahrenheitPostFuncBody = 'if ((typeof value === undefined) || value == null) ' +
    '{ ' +
    'return value; ' +
    '} ' +
    'else { ' +
    'var result = value * 1.8;' +
    'return parseFloat((result).toFixed(3));' +
    '}';
