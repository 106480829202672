<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="openHistoryPage($event)">
        <ion-icon slot="icon-only" name="stats-chart-outline"></ion-icon>
      </ion-button>
      <!--<ion-button (click)="openDeviceList()">
        <img style="width: 20px;" src="/assets/svg/spear_sensor.svg" />
      </ion-button>-->
      <ion-button (click)="openTimeWindowMenu($event)">
        <ion-icon slot="icon-only" name="time-outline" ></ion-icon>
      </ion-button>
      <ion-button (click)="openSettings()" >
        <ion-icon slot="icon-only" name="settings-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ag-loading [loading]="loading" message="{{text.building.loading | translate}}" (retry)="reload()"></ag-loading>
  <div *ngIf="isLoaded && building.devices.length == 0">
    <ion-fab vertical="center" horizontal="center" slot="fixed" (click)="openAddDeviceDialog()">
      <ion-fab-button color="primary">
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
      <ion-button color="primary" style="transform: translate(-40%, 0); text-transform: uppercase;" [translate]="text.building.add_device"></ion-button>
    </ion-fab>
  </div>
  <div *ngIf="isLoaded && building.devices.length > 0" class="flex-auto" style="padding: 8px 4px 4px;" fxFlex fxLayout="column">
    <!--<div *ngIf="!building.hasDevices" class="ag-card flex-auto stats-container" fxFlex fxLayout="column">
      <div>Building has no devices! </div>
      <div>Open Settings to assign devices to building</div>
      <ion-button style="margin-top: 10px;" fill="clear"  (click)="openSettings()">
        <ion-icon style="margin-right: 5px;" slot="icon-only" name="settings-outline"></ion-icon>
        Settings</ion-button>
    </div>-->
    <div *ngIf="isPaid == false" class="ag-card flex-auto" style="padding: 10px">
      {{text.building.has_unsubscribed_devices | translate}}
      <button (click)="checkout()" [translate]="text.general.checkout"></button>
    </div>

    <entity-alarm-group *ngIf="alarmItems.length > 0" [alarms]="alarmItems" [name]="text.general.alarms|translate"></entity-alarm-group>

    <div *ngIf="isPaid == true" fxLayout="column">
      <div *ngIf="hasTemperature" class="ag-card flex-auto stats-container" fxFlex fxLayout="column">
        <div fxFlex fxLayout="row" class="flex-auto">
          <div class="ag-value-header" fxFlex [translate]="text.general.latest_temperature"></div>
          <div style="font-size: 12px;"><ag-time-since [ts]="building.latestTemperatureTs"></ag-time-since></div>
        </div>
        <div class="ag-values-row flex-auto" fxFlex fxLayout="row">
          <ag-sensor-value [prefix]="text.general.min" type='TEMPERATURE' [value]="data.current_min_temperature" ></ag-sensor-value>
          <ag-sensor-value [prefix]="text.general.max" type='TEMPERATURE' [value]="data.current_max_temperature" ></ag-sensor-value>
        </div>
        
      </div>
      <div *ngIf="hasMoisture" class="ag-card flex-auto stats-container" fxFlex fxLayout="column" > 
        <div fxFlex fxLayout="row" class="flex-auto">
          <div *ngIf="isMoisture" class="ag-value-header" fxFlex [translate]="text.general.latest_emc"></div>
          <div *ngIf="isHumidity" class="ag-value-header" fxFlex [translate]="text.general.latest_humidity"></div>
          <div style="font-size: 12px;"><ag-time-since [ts]="building.latestMoistureTs"></ag-time-since></div>
        </div>
        <div class="ag-values-row flex-auto" fxFlex fxLayout="row">
          <ag-sensor-value [prefix]="text.general.min" [cropType]="data.crop_type" type='MOISTURE' [value]="data.current_min_emc" ></ag-sensor-value>
          <ag-sensor-value [prefix]="text.general.max" [cropType]="data.crop_type" type='MOISTURE' [value]="data.current_max_emc" ></ag-sensor-value>
        </div>
      </div>

      <div *ngFor="let chart of charts; trackBy:chartKey" id="cellular-chart-card" 
        [class]="{'ag-plot-zoom': false, 'ag-plot-no-zoom': true}" 
        class="ag-card" fxLayout="column"> 
        
        <div class="md-padding chart-heading" [translate]="chart.name"></div>
        <!--<ion-icon name="expand-outline" (click)="showChart()" style="position: absolute; right: 20px; top: 20px;"></ion-icon>-->
        <div fxFlex class="temp-history-container md-padding">
          <ag-plot [height]="260" [figures]="chart.figures" [entity]="asset.id" [axis]="chart.axes" [timeseries]="chart.timeseries"></ag-plot>
        </div>
        <div class='ag-plot-bottom'>
          <div class="ag-plot-legend">
            <div class="ag-legend-item" *ngFor="let ts of chart.timeseries">
              <div class="ag-legend-icon" [style.background]="ts.color"></div>
              <div class="ag-legend-value">{{ts.label|translate}}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="hasTemperature" style="padding: 10px; position: relative;" class="ag-card flex-auto" fxFlex fxLayout="row">
        <div class="card-icon-button" (click)="openEditPlacements()">
          <ion-icon name="create-outline"></ion-icon>
        </div>
        <div style="width: 100%; position: relative">
          <div class="ag-card-header" [translate]="text.building.temperature_sensor_map"></div>
          
          <building-sensor-map type="TEMPERATURE" [building]="building"></building-sensor-map>
        </div>
      </div>
      <div *ngIf="hasMoisture" style="padding: 10px; position: relative;" class="ag-card flex-auto" fxFlex fxLayout="row">
        <div class="card-icon-button" (click)="openEditPlacements()">
          <ion-icon name="create-outline" ></ion-icon>
        </div>
        <div style="width: 100%; position: relative">
          <div *ngIf="isMoisture" class="ag-card-header" [translate]="text.building.moisture_sensor_map"></div>
          <div *ngIf="isHumidity" class="ag-card-header" [translate]="text.building.humidity_sensor_map"></div>
          <building-sensor-map type="MOISTURE" [building]="building"></building-sensor-map>
        </div>
      </div>
    </div>

    <div class="ag-card" style="height: 300px;">
      <devices-map [devices]="[building.entity]" ></devices-map>
    </div>
    
    <div style="padding: 10px; position: relative;" class="ag-card flex-auto">
      <div class="card-icon-button" (click)="openDeviceList()">
        <ion-icon name="list-outline"></ion-icon>
      </div>
      <div class="ag-card-header" [translate]="text.building.assigned_devices"></div>
      <div *ngFor="let device of building.devices; trackBy:deviceKey">
        <building-device-item [building]="building" [deviceId]="device.id.id"></building-device-item>
      </div>
      <div style="margin: 8px; display: flex;">
        <ion-button (click)="openAddDeviceDialog()">
          <ion-icon name="add-circle-outline"></ion-icon>
          <span style="margin-left: 10px;" [translate]="text.building.add_device"></span>
        </ion-button>
      </div>
    </div>

    <div style="margin-top: 100px"></div>
  </div>

</ion-content>