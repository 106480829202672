import { Component, Input } from "@angular/core";
import { Entity } from 'src/app/models/entity.model';
import { AttributeScope } from 'src/app/models/telemetry.model';
import { AttributeService } from 'src/app/services/entity.service';
import { AGUserService } from 'src/app/services/user.services';
import { AuthUser } from 'src/app/state';
import { fromJson, toJson } from 'src/app/util';


interface IAssetComment {
  timestamp: number, commentValue: string, userName: string, edited: boolean
}

@Component({
  selector: 'asset-comments',
  templateUrl: 'asset-comments.html',
  styleUrls: ['asset-comments.scss']
})
export class AssetCommentsComponent { 

  constructor (
    private userService: AGUserService,
    private attributeService: AttributeService
    ) {}

  @Input() asset: Entity<any>

  showData = true 
  allowCommentsEditing = true;
  loading = true
  commentEditMode = false
  newComment = ''
  assetComments: IAssetComment[] = []
  sending = false
  user: AuthUser
  editedCommentValue = ''
  editedComment: IAssetComment
  originalCommentvalue = ''

  ngOnInit () {
    this.userService.selectAuthUser().subscribe(user => {
      this.user = user
      this.loadComments()
    })
  }
  
  loadComments () {
    this.attributeService.getEntityAttributes(
      this.asset.id, AttributeScope.SERVER_SCOPE, ["comments"]).subscribe(data => {
        this.assetComments = data.length ? fromJson(data[0].value) : [];
        this.loading = false;
      }
    );
  }

  sendComment () {
    if (this.newComment) {
      this.sending = true;
      let user = this.user
      let userName = (user.firstName || user.lastName) ? user.firstName + " " + user.lastName : 'annonymous';
      var comment: IAssetComment = {
        timestamp: new Date().getTime(),
        commentValue: this.newComment,
        userName: userName, edited: false
      };
      this.assetComments.unshift(comment);
      var editedComments = toJson(this.assetComments);
      this.newComment = "";

      this.attributeService.saveEntityAttributes(
        this.asset.id, AttributeScope.SERVER_SCOPE, [{
          key: "comments",
          value: editedComments
      }]).subscribe(value => {
        this.sending = false
      })
    }
  }

  editComment() {
    if (this.editedCommentValue) {
      this.editedComment.commentValue = this.editedCommentValue;
      if (this.originalCommentvalue != this.editedComment.commentValue) {
        this.editedComment.edited = true;
      } else {
        this.commentEditMode = false;
        return;
      }
      this.commentEditMode = false;
      this.sending = true;
      var editedComments = toJson(this.assetComments);

      this.attributeService.saveEntityAttributes(
        this.asset.id, AttributeScope.SERVER_SCOPE, [{
          key: "comments",
          value: editedComments
        }]
      ).subscribe(value => {
        this.sending = false;
      });
    }
  }

  deleteComment($event, comment) {
    this.commentEditMode = false;
    this.sending = true;
    var index = this.assetComments.indexOf(comment);
    this.assetComments.splice(index, 1);
    var editedComments = toJson(this.assetComments);
    this.attributeService.saveEntityAttributes(
      this.asset.id, AttributeScope.SERVER_SCOPE, [{
        key: "comments",
        value: editedComments
      }]
    ).subscribe(value => {
      this.sending = false;   
    })
  }

  enterCommentEditMode (comment: IAssetComment) {
    this.editedComment = comment;
    this.commentEditMode = true;
    this.originalCommentvalue = comment.commentValue;
    this.editedCommentValue = comment.commentValue;
  }

  cancelCommentEditMode() {
    this.commentEditMode = false;
  }

  linebreaks (text: string) {
    return text.replace(/\n/g, "<br>");
  }

}

