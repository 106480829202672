import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { agTypes } from "src/app/ag-types";
import { Device } from "src/app/models/entity.model";
import { FarmerService } from "src/app/services/device.service";
import { AgModal } from "src/app/util";
import { AgTooltipService } from "../ag-plot/ag-plot";
import { DeviceSelector } from "../farmer-building/farmer-building";
import { getEventCoords } from "../farmer-building/farmer-building.model";
import { FarmerSilo } from "./farmer-silo.model";


export interface ICableMarker {
  x: number, y: number, id: string, device: Device
  realPos?: {x: number, y: number}
}

@Component({
  template: `


<div>
  <ng-template #cableTooltip let-cable="cable" > 
    <strong>{{cable.name}}</strong>
    <button (click)="removeDevice(cable)">remove</button>
  </ng-template>
  <div>
    <svg #svgRef height="100%" width="100%" viewBox="-55 -55 110 110">
    <circle cx="0" cy="0" r="50" stroke="gray" stroke-width="1" fill="lightgray" />
    <circle 
        (touchstart)="startDrag(marker.id, $event)"
        (mousedown)="startDrag(marker.id, $event)"
        *ngFor="let marker of markers" [attr.cx]="marker.x" [attr.cy]="marker.y" r="5" fill="red" />
      
    </svg>
  </div>
  <div>
    <div style="display: flex;">
      <ion-button style="margin-left: auto; margin-right: auto;" (click)="openSelectDeviceDialog($event)">Add Cable</ion-button>
    </div>
  </div>
</div>
  `,
  selector: 'farmer-cable-placement',
  styleUrls: []
})
export class FarmerCablePlacementComponent {
  @Input() silo: FarmerSilo
  @Input() diameter: number
  @Output() onMarkersUpdated: EventEmitter<ICableMarker[]> = new EventEmitter()
  markers: ICableMarker[] = []
  devices: Device[] = []

  @ViewChild('svgRef') svgRef: ElementRef<SVGElement>
  @ViewChild('cableTooltip') cableTooltip: TemplateRef<any>
  
  constructor (
    private farmer: FarmerService,
    private agtooltip: AgTooltipService,
    private modalController: ModalController,
    private agModal: AgModal
  ) {}

  ngOnInit () {
  }

  getMarker (id: string) {
    return this.markers.find(x => x.id == id)
  }

  getSvgBound () {
    return {x: 0, y: 0, width: 100, height: 100}
  }

  _keepWithin (value: number, min: number, max: number) {
    if (value < min) return min
    if (value > max) return max
    return value
  }
  toSvgPosition (pos: {x: number, y: number}) {
    let bound = this.getSvgBound()
    let unitsPerMeter = bound.width / this.diameter
    let result = {
      x: this._keepWithin(pos.x * unitsPerMeter, -50, 50), 
      y: this._keepWithin(pos.y * unitsPerMeter, -50, 50)
    }
    return result
  }
  toRealPosition (pos: {x: number, y: number}) {

  }

  addDevice (device: Device) {
    this.devices.push(device)
    this.layout()
    this.onMarkersUpdated.emit(this.markers)
  }
  removeDevice (device: Device) {
    this.devices = this.devices.filter(x => x.id.id != device.id.id)
    this.layout()
    this.onMarkersUpdated.emit(this.markers)
  }
  updateDiameter (prev: number) {
    this.markers = this.markers.map(marker => {
      let [x, y] = [marker.realPos.x, marker.realPos.y]
      let viewPos = this.toSvgPosition({x: x, y: y})
      let newMarker = {...marker,
        x: viewPos.x, y: viewPos.y
      }
      return newMarker
    })
    this.onMarkersUpdated.emit()
  }
  async layout () {
    let markers: ICableMarker[] = []
    this.devices.map(device => {
      let cable = this.farmer.getSiloCable(device)
      let id = device.id.id
      let marker = this.getMarker(id)
      if (!marker) {
        let x = cable.settings.pos_x
        let y = cable.settings.pos_y
        let viewPos = this.toSvgPosition({x: x, y: y})
        marker = {
          x: viewPos.x, y: viewPos.y,
          device: device, id: id,
          realPos: {x: x, y: y}
        }
      }
      markers.push(marker)
    })
    this.markers = markers
  }

  async openSelectDeviceDialog (event) {
    const modal = await this.modalController.create({
      component: DeviceSelector,
      cssClass: 'my-custom-class',
      showBackdrop: true,
      componentProps: {
        types: [
          agTypes.farmerDeviceTypes.slmCable.value,
          agTypes.farmerDeviceTypes.sltCable.value
        ],
        ignore: this.devices, include: this.silo.devices
      },
      presentingElement: await this.modalController.getTop()
    });
    const { data } = await this.agModal.openModal(modal)
    if (data) {
      this.addDevice(data)
    } 
  }

  onElementResize () {
    this.layout()
  }

  _view_subscription
  _layout_interval
  _redraw_interval
  _element_size
  _subscription: Subscription
  ngAfterViewInit () {
    window.addEventListener('mouseup', e => {
      this.stopDrag()
    }, {capture: true})
    window.addEventListener('touchend', e => {
      this.stopDrag()
    }, {capture: true})
    this._view_subscription = this.silo.updated.subscribe(e => {
      this.onSiloUpdated()
    })
    
    this.onElementResize()
    this.onSiloUpdated()
  }
  onSiloUpdated () {
    this.devices = this.silo.devices.slice(0)
    this.layout()
  }
  ngOnDestroy () {
    clearInterval(this._redraw_interval)
    if (this._subscription) {
      this._subscription.unsubscribe()
    }
    if (this._layout_interval) window.clearInterval(this._layout_interval)
    if (this._view_subscription) {
      this._view_subscription.unsubscribe()
    }
  }
  
  ngOnChanges (changes) {    
    if (changes.diameter && !changes.diameter.firstChange) {
      this.updateDiameter(changes.diameter.previousValue)
    }
  }


  selection: string
  dragEvent: MouseEvent | TouchEvent | null = null
  dragHandler = this.onMouseDrag.bind(this)

  stopDrag () {
    if (!this.dragEvent) return // NOTE: IMPORTANT, to avoid other sensor-maps from reacting
    this.dragEvent = null
    //window.removeEventListener('mousemove', this.dragHandler, {capture: true})
    //window.removeEventListener('touchmove', this.dragHandler, {capture: true})
  }

  startDrag (id: string, evt: MouseEvent | TouchEvent) {
    if (this.dragEvent) return
    this.selection = id
    this.agtooltip.open(
      evt.target as HTMLElement, this.cableTooltip, 
      {cable: this.getMarker(id).device}
    )
    evt.stopPropagation()
    this.dragEvent = evt
    // TODO: ADD THIS
    //window.addEventListener('mousemove', this.dragHandler, {capture: true,passive: false})
    //window.addEventListener('touchmove', this.dragHandler, {capture: true,passive: false})
  }

  onMouseDrag (e: MouseEvent | TouchEvent) {
    e.stopPropagation()
    e.stopImmediatePropagation()
    e.preventDefault()
    let lastPos = getEventCoords(this.dragEvent)
    let currPos = getEventCoords(e)
    let vector = {
      x: currPos.x - lastPos.x, y: currPos.y - lastPos.y
    }
    this.moveMarker(this.selectedMarker, vector)
    this.dragEvent = e
    this.agtooltip.open(
      e.target as HTMLElement, this.cableTooltip, 
      {cable: this.selectedMarker.device}
    )
    
    return false
  }

  updateMarkerPositions () {
    let svgBound = this.getSvgBound()
    this.markers.map(marker => {
      let metersPerUnit = this.diameter / svgBound.width
      marker.realPos = {
        x: marker.x * metersPerUnit, y: marker.y * metersPerUnit
      }
    })
    this.onMarkersUpdated.emit(this.markers) 
  }

  moveMarker (marker: ICableMarker, vector: {x: number, y: number}) {
    let viewBound = this.svgRef.nativeElement.getBoundingClientRect()
    let svgBound = this.getSvgBound()
    let unitPerPixel = svgBound.width / viewBound.width
    marker.x += vector.x * unitPerPixel
    marker.y += vector.y * unitPerPixel
    this.updateMarkerPositions()
  }

  get selectedMarker () {
    return this.markers.find(loc => loc.id == this.selection)
  }

}


@Component({
  template: `<canvas #canvas></canvas>`,
  selector: 'silo-top-drawing',
  styleUrls: []
})
export class FarmerSiloTopDrawing {
  @Input() silo: FarmerSilo
}


@Component({
  template: `
<div>
  <div *ngFor="let cable of cables">
    <div>{{cable.name}}</div>
  </div>
</div>`,
  selector: 'select-silo-cable',
  styleUrls: []
})
export class SelectSiloCable {
  cables: Device[] = []

  constructor (private farmer: FarmerService) {}

  ngOnInit () {
  }
}

