import { EventEmitter } from "@angular/core"
import moment from "moment"
import { Subscription, Subject } from "rxjs"
import { debounceTime } from "rxjs/operators"
import { CROP_TYPE, METRIC, SENSORS, SENSOR_METRICS } from "src/app/constant"
import { Device, EntityType } from "src/app/models/entity.model"
import { ITelemetryValue } from "src/app/models/telemetry.model"
import { EntityModel, FarmerService } from "src/app/services/device.service"
import { ISensorData, ISpearSettings, SENSOR_TYPE } from "src/app/services/types.service"
import { CropType } from "src/app/util"


export class FarmerDeviceSensor {
  title: string
  constructor (
    private device: FarmerCellularSpear, 
    private entity: Device, private state: ISensorData) {
    this.title = SENSORS.find(x => x.key == state.key && x.type == state.type).name
    this.farmer.settings.subscribe(x => {
      this.setAmbientIsHumidity(x.is_ambient_humidity)
    })
  }

  

  //get title () { return getSensorTranslateKey(this.state) }
  get type () { return this.state.type }

  get key () { return this.state.key }
  get name () { return this.state.name }
  get farmer () { return this.device.farmer }


  
  emcKey (crop: CROP_TYPE) {
    return 'emc-' + crop.replace(/\s/g, '_').toLowerCase() + '-' + this.key
  }
  moistureKey () {
    return this.cropType ? this.emcKey(this.cropType) : 'humidity-' + this.key
  }
  temperatureKey () {
    return 'temperature-' + this.key
  }
  humidityKey () {
    return 'humidity-' + this.key
  }
  errorKeys () {
    return ['humidity-error-' + this.key, 'temperature-error-' + this.key]
  }

  get sensorType () { return this.state.type }
  hasSensorType (type: SENSOR_TYPE) {
    if (this.hasTemperature() && (type == SENSOR_TYPE.TEMP || type == SENSOR_TYPE.TEMP_AND_HUM))
      return true
    else if (this.hasMoisture() && (type == SENSOR_TYPE.HUM || type == SENSOR_TYPE.TEMP_AND_HUM))
      return true
    return false
  }
  
  // TODO: try remove these two function, use "isTemperatureType" instead
  hasTemperature () {
    return this.state.type == SENSOR_TYPE.TEMP_AND_HUM || this.state.type == SENSOR_TYPE.TEMP
  }
  hasMoisture () {
    return this.state.type == SENSOR_TYPE.HUM || this.state.type == SENSOR_TYPE.TEMP_AND_HUM
  }

  isAmbientHumidity = false
  isTemperatureType = false
  isMoistureType = false

  latestSubscriber
  latestTs: number | null = null
  cropType: CROP_TYPE | null = null
  temperatureData: ITelemetryValue<number>
  moistureData: ITelemetryValue<number>
  humidityData: ITelemetryValue<number>
  loaded = false

  getValues () {
    let result: ITelemetryValue<any>[] = []
    if (this.temperatureData != null) result.push(this.temperatureData)
    if (this.moistureData != null) result.push(this.moistureData)
    return result
  }

  onDataUpdate = new EventEmitter<FarmerDeviceSensor>()

  setAmbientIsHumidity (isAmbientHumidity: boolean) {
    this.isAmbientHumidity = isAmbientHumidity
    this.dataUpdated()
  }

  dataUpdated () {
    let ts = [this.temperatureData?.ts, this.moistureData?.ts].filter(x => x != undefined)
    if (this.state.key == 'ambient' && this.isAmbientHumidity) {
      ts = [this.temperatureData?.ts, this.humidityData?.ts].filter(x => x != undefined)
    }
    this.latestTs = ts.length > 0 ? Math.max(...ts) : null
    this.isTemperatureType = this.hasTemperature()
    this.isMoistureType = this.hasMoisture()

    this.onDataUpdate.emit()
    this.device.dataUpdated()
  }

  // TODO: rewrite this to be more generic
  updateCropType (cropType: CROP_TYPE | null) {
    this.cropType = CropType(cropType) as CROP_TYPE
    let tempKey = this.temperatureKey()
    let moistureKey = this.moistureKey()
    let humidityKey = this.humidityKey()
    // NOTE: always include humidity to support showing ambient as humidity
    let keys = [tempKey, moistureKey, humidityKey].concat(this.errorKeys())

    if (this.latestSubscriber) {
      this.latestSubscriber.unsubscribe()
    }
    
    this.latestSubscriber = this.farmer.globalContext.latest(this.entity.id, {
      keys: keys
    }, {
      onData: (resp) => {
        
        for (var key in resp.data) {
          let data = resp.data[key]
          let ts = data[0][0]
          let value = data[0][1]
          if (key == tempKey) {
            if (value != undefined)
              this.temperatureData = {key: tempKey, ts: ts, value: parseFloat(value), status: 'ok'}
          } 
          if (key == moistureKey) {
            if (value != undefined)
              this.moistureData = {key: moistureKey, ts: ts, value: parseFloat(value), status: 'ok'}
          } 
          if (key == humidityKey) {
            this.humidityData = {key: humidityKey, ts: ts, value: parseFloat(value), status: 'ok'}
          }
        }
        for (var key in resp.data) {
          let data = resp.data[key]
          let ts = data[0][0]
          let value = data[0][1]
          if (value != null && key.includes('-error')) {
            let type = key.split('-')[0]
            if (type == 'temperature') {
              if (this.temperatureData == null) {
                this.temperatureData = {key: tempKey, ts: 0, value: null}
              }
              this.temperatureData.errorTs = ts
              if (ts > this.temperatureData.ts) {
                this.temperatureData.status = 'error'
              }
            } else if (type == 'humidity') {
              if (this.humidityData == null) {
                this.humidityData = {key: humidityKey, ts: 0, value: null}
              }
              this.humidityData.errorTs = ts
              if (ts > this.humidityData.ts) {
                this.humidityData.status = 'error'
              }

              if (this.moistureData == null) {
                this.moistureData = {key: moistureKey, ts: 0, value: null}
              }
              this.moistureData.errorTs = ts
              if (ts > this.moistureData.ts) {
                this.moistureData.status = 'error'
              }
            }
          }
        }
        
        
        
        this.dataUpdated()
        return true
      }, 
      onLoading (isLoading) {
        this.loaded = !isLoading
      }
    })
    this.dataUpdated()
    return this.latestSubscriber
  }
}



export class FarmerCellularSpear {
  /*onUpdate = new EventEmitter<FarmerCellularSpear>()
  onDataUpdate = new EventEmitter<FarmerCellularSpear>()
  onLoaded = new EventEmitter<this>()*/

  onUpdate: Subject<FarmerCellularSpear>
  onDataUpdate = new Subject<FarmerCellularSpear>()
  onLoaded = new Subject<this>()
  
  // new EntityModel(this, this.entity, SpearAttributes)
  public model: EntityModel<EntityType.DEVICE, ISpearSettings>
  

  public sensors: FarmerDeviceSensor[] = []

  async getQrValue () {
    return await this.model.fetchQrValue()
  }

  getValues (): ITelemetryValue<number>[] {
    if (!this.sensors || !this.sensors.length) return []
    return this.sensors[0].getValues()
  }

  get settings () { return this.model.settings }

  get battery_level () { return this.settings.battery_level }
  get signal_strength () { return this.settings.signal_strength }
  
  constructor (
    public farmer: FarmerService, public entity: Device) {
      this.onUpdate = new Subject<FarmerCellularSpear>()
      this.model = this.farmer.createModel(this.farmer.types.celluarSpear, this.entity)
      this.onEntityUpdated(entity)
      this.model.onUpdate.subscribe(e => {
        this.onAttributesUpdated(e)
      })
      this.model.load()
    }

  onBuildingUpdated () {

  }

  subscribe (cb: ()=>void) {
    try {cb()} catch (err) {}
    return this.onUpdate.pipe(
      debounceTime(500)
    ).subscribe(cb)
  }

  set_entity (entity: Device) {
    this.entity = entity
  }

  async saveSettings (settings: Partial<ISpearSettings>) {
    await this.model.saveSettings(settings)
  }

  // TODO: typescript for buildingModel
  public buildingModel: EntityModel<any,any> | null = null
  buildingSubscription: Subscription
  setBuildingModel (model: EntityModel<any,any> | null) {
    if (this.buildingSubscription) {
      this.buildingSubscription.unsubscribe()
    }
    this.buildingModel = model
    if (this.buildingModel) {
      this.buildingSubscription = this.buildingModel.onUpdate.subscribe(e => {
        this.onAttributesUpdated({crop_type: e.crop_type})
      })
    }
    this.updateCropType()
  }

  dataUpdated () {
    //this.onDataUpdate.emit(this)
    this.onDataUpdate.next(this)
  }

  onEntityUpdated (entity: Device) {
    this.entity = entity
    /*Object.assign(this.settings, {
      name: this.entity.name, label: this.entity.label
    })*/
    //this.onUpdate.emit(this)
    this.onUpdate.next(this)
  }

  onAttributesUpdated (updated: Partial<ISpearSettings>) {
    if (Object.keys(updated).length > 0) {
      if (updated.sensors != undefined) {
        this.updateSensors()
      } else if (updated.crop_type != undefined) {
        this.updateCropType()
      }

      //this.onUpdate.emit(this)
      this.onUpdate.next(this)
    }
  }
  
  isLoaded () { return this.model.loading.is_success() }
  async waitLoaded () {
    if (this.sensors.length > 0) return
    return new Promise<void>((resolve, reject) => {
      let sub = this.onUpdate.subscribe(e => {
        if (this.sensors.length > 0 && this.model.loading.is_complete()) {
          sub.unsubscribe()
          if (this.model.loading.is_failed()) reject()
          else resolve()
        }
      })
    })
  }

  load () {
    return this.onUpdate
  }

  get id () { return this.entity.id }

  updateCropType () {
    this.sensors.map(sensor => {
      if (this.buildingModel) {
        sensor.updateCropType(this.buildingModel.settings.crop_type)
      } else {
        sensor.updateCropType(this.settings.crop_type)
      }
    })
  }

  updateSensors () {
    let sensors = this.settings.sensors
    this.sensors = sensors.map(sensor => {
      return this.makeSensor(sensor)
    })
    this.updateCropType()
  }

  hasTemperature () {
    return this.sensors.find(x => x.hasTemperature()) != undefined
  }
  hasMoisture () {
    return this.sensors.find(x => x.hasMoisture()) != undefined
  }

  private makeSensor (data: ISensorData) {
    let sensor = this.sensors.find(s => s.key == data.key)
    if (sensor) return sensor
    if (!data.type) data.type = SENSOR_TYPE.TEMP_AND_HUM
    return new FarmerDeviceSensor(this, this.entity, data)
  }
  

}

