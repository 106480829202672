import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { FarmerService } from "src/app/services/device.service";
import { gradientColorValue } from "src/app/util";
import { FarmerSilo, FarmerSiloCable } from "./farmer-silo.model";
import { agTypes } from '../../ag-types'
import moment from "moment-es6";
import { Device, EntityId } from "src/app/models/entity.model";
import { SENSOR_TYPE } from "src/app/services/types.service";

export interface ITableCell {
  value: number, style: object, type: SENSOR_TYPE
  rowIndex: number, columnIndex: number,
  ts: number, key: string, column: ITableColumn, row: ITableRow
  selectable?: boolean, isSelected?: boolean
}
interface ITableRow {
  name: string, index: number
}
interface ITableColumn {
  name: string, cells: ITableCell[], entityId?: EntityId<any>
  selectable?: boolean, index: number
}

interface ISensorSelectionItem {
  entityId: EntityId<any>, name: string, key: string, cell?: ITableCell
}

export interface ISensorSelection {
  sensors: ISensorSelectionItem[]
  columns: string[]
  event?: MouseEvent
}


@Component({
  selector: 'farmer-silo-table',
  template: `
<div class="silo-table" >
  <div class='table-column'>
    <div class="table-column-header"></div>
    <div (click)="selectRow(row)" *ngFor="let row of rows | reverse" class='table-row table-cell'>
      {{row.name}}      
    </div>
  </div>
  <div class="table-column" *ngFor="let column of columns">
    <div (click)="selectColumn(column)" class="table-column-header">{{column.name}}</div>
    <div *ngFor="let row of rows | reverse" [class]="row.index + '-' + column.index">
      <div *ngIf="!column.cells[row.index]" class='table-cell'></div>
      <div *ngIf="column.cells[row.index] && cellTemplate" >
        <ng-template [ngTemplateOutlet]="cellTemplate" [ngTemplateOutletContext]="{cell: column.cells[row.index]}"></ng-template>
      </div>
      <div *ngIf="column.cells[row.index] && !cellTemplate">
        <div  (click)="onCellClick(column.cells[row.index], $event)" class='table-cell' [style]="column.cells[row.index].style"
            [class.selected]="isSelected(column.cells[row.index])">
          {{column.cells[row.index].value ? column.cells[row.index].value.toFixed() : ' '}}
        </div>
      </div>
    </div>
  </div>
</div>
`,
  styleUrls: ['farmer-silo.scss']
})
export class FarmerSiloTable {
  @Input() silo: FarmerSilo
  @Input() devices?: Device[]
  @Input() sensorType: SENSOR_TYPE = SENSOR_TYPE.TEMP
  @Input() minValue: number
  @Input() maxValue: number
  @Input() mode: 'SELECT' | 'VALUE' = 'VALUE'
  @Input() singleSelection: boolean = false
  @Input() cellTemplate: TemplateRef<any>
  @Output() onSelect: EventEmitter<ISensorSelection> = new EventEmitter()

  columns: ITableColumn[] = []
  rows: ITableRow[] = []
  selection: ISensorSelection = {sensors: [], columns: []}

  constructor (private farmer: FarmerService) {}


  ngOnInit () {
    this.silo.updated.subscribe(silo => {
      this.buildTable()
    })
    this.silo.load()
  }

  ngOnDestroy () {}

  _clearSelection (e) {
    this.selection.sensors = []
    this.onSelect.emit(this.selection)
  }
  clearSelection = this._clearSelection.bind(this)

  timeDisplay (ts: number) {
    if (!ts) return "-"
    return moment(ts).format("MMMM Do YYYY, HH:mm")
  }

  selectColumn (column: ITableColumn) {
    this.selection.event = null
    let cells = column.cells.slice(0)
    this.toggleSelect(cells)
  }
  getAllCells () {
    let cells = []
    this.columns.map(c => {
      cells.push(...c.cells)
    })
    return cells
  }
  selectRow (row: ITableRow) {
    this.selection.event = null
    let cells = this.getAllCells().filter(x => x.row == row)
    this.toggleSelect(cells)
  }
  toggleSelect (cells: ITableCell[]) {
    let hasSelection = cells.find(c => this.isSelected(c))
    if (!hasSelection) {
      this.select(cells)
    } else {
      this.clearSelection()
    }
  }
  onCellClick (cell: ITableCell, $event) {
    this.selection.event = $event
    this.toggleSelect([cell])
  }
  select (cells: ITableCell[]) {
    this.selection.sensors = []
    cells.map(cell => {
      if (!cell.selectable) return
      this.selection.sensors.push({
        entityId: cell.column.entityId, key: cell.key, name: cell.column.name,
        cell: cell
      })
    })
    this.onSelect.emit(this.selection)
  }
  isSelected (cell: ITableCell) {
    return this.selection.sensors.find(
      s => s.entityId?.id == cell.column.entityId?.id && cell.key == s.key
    ) != undefined
  }

  ngOnChanges (changes) {
    this.buildTable()
  }

  getColor (value: number) {
    let matrix = agTypes.colors.tempMatrixColors
    if (this.sensorType == SENSOR_TYPE.HUM) matrix = agTypes.colors.moistureMatrixColors
    return gradientColorValue(value, this.minValue, this.maxValue, matrix)
  }
  getValueKey () {
    if (this.sensorType == SENSOR_TYPE.HUM) { 
      let crop = this.silo.settings.crop_type
      return crop ? 'emc-' + crop : 'humidity'
    } else return 'temperature'
  }

  buildTable () {
    /*let nameColumn: ITableColumn = {
      name: '', cells: []
    }*/
    let allKeys = {}
    let columns: ITableColumn[] = [] // [nameColumn]
    let rows: ITableRow[] = []
    let devices = this.devices || this.silo.devices
    let cables = devices.map(d => this.farmer.getSiloCable(d))
    cables.sort((a, b) => a.settings.uid - b.settings.uid)
    cables.map((cable, colIndex) => {
      let column: ITableColumn = {
        selectable: true, index: colIndex,
        name: cable.shortName(), cells: [], entityId: cable.device.id
      }
      for (var i=0; i<cable.settings.sensor_count; i++) {
        
        let sensorNum = i + 1
        if (!rows[i]) {
          rows[i] = {name: 'Sensor ' + sensorNum, index: i}
        }
        let row = rows[i]
        
        let key = this.getValueKey() + '-' + sensorNum
        allKeys[cable.device.id+':'+key] = true
        let value = cable.latest[key]
        let color = 'lightgray'
        let ts: number = null
        let display: number
        if (value && value.value && value.ts) {
          let rgb = this.getColor(value.value)
          color = 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')'
          display = value.value
          ts = value.ts
        }
        if (value && !cable.isValueValid(value)) {
          color = 'lightgray'
        }
        let type: SENSOR_TYPE = this.sensorType
        column.cells.push({
          value: display, type: type, style: {
            backgroundColor: color, 
          }, ts: ts, column: column, row: row, 
          key: key, selectable: true, rowIndex: i, columnIndex: colIndex,
        })
      }
      columns.push(column)
    })
    this.columns = columns
    this.rows = rows
  }
}