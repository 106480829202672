import { Component, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AppService } from "src/app/app.component";
import { LANGUAGES } from "src/app/constant";
import { User } from "src/app/models/entity.model";
import { AttributeScope } from "src/app/models/telemetry.model";
import { AuthService } from "src/app/services/auth.service";
import { FarmerService } from "src/app/services/device.service";
import { AttributeService } from "src/app/services/entity.service";
import { PaymentService } from "src/app/services/payment.service";
import { TEXT } from "src/app/texts";
import { environment } from "src/environments/environment";
import { CropCallibrationComponent } from "./callibration";
import { AgModal } from "src/app/util";


const ENABLE_EMAIL_ATTRIBUTE = 'enable_email'
const LOCALE_ATTRIBUTE = 'locale'
const IS_AMBIENT_HUMIDITY = 'is_ambient_humidity'

export interface IProfileSettings {
  is_ambient_humidity: boolean
  locale: string | null
  enable_email: boolean
}

@Injectable()
export class ProfileService {
  settings: BehaviorSubject<IProfileSettings> = new BehaviorSubject(
    {enable_email: false, locale: null, is_ambient_humidity: false}
  )

  constructor (
    private auth: AuthService,
    private attributes: AttributeService
  ) {
    this.load()
  }

  load () {
    this.auth.getAuth().pipe(
      switchMap(auth => {
        let state = auth.userDetails
        //this.userState = state
        return this.attributes.getEntityAttributes(
          state.id, AttributeScope.SERVER_SCOPE, 
          [ENABLE_EMAIL_ATTRIBUTE, LOCALE_ATTRIBUTE, IS_AMBIENT_HUMIDITY])
      })
    ).subscribe(attrs => {
      let newSettings = this.settings.value
      attrs.map(attr => {
        if (attr.key == ENABLE_EMAIL_ATTRIBUTE) {
          newSettings.enable_email = attr.value ? attr.value : false
        }
        if (attr.key == LOCALE_ATTRIBUTE) {
          newSettings.locale = attr.value
        }
        if (attr.key == IS_AMBIENT_HUMIDITY) {
          newSettings.is_ambient_humidity = attr.value ? attr.value : false
        }
      })
      this.settings.next(newSettings)
      //this.loading = false
    })
  }

  async saveSettings (settings: IProfileSettings) {
    await this.auth.getAuth().pipe(
      switchMap(auth => {
        let settings = this.settings.value
        return this.attributes.saveEntityAttributes(
          auth.userDetails.id, AttributeScope.SERVER_SCOPE, [
            {key: ENABLE_EMAIL_ATTRIBUTE, value: settings.enable_email},
            {key: LOCALE_ATTRIBUTE, value: settings.locale},
            {key: IS_AMBIENT_HUMIDITY, value: settings.is_ambient_humidity}
          ]
        )
      })
    ).toPromise()
    this.settings.next(settings)
  }
}

@Component({
  template: `
  <ion-header>
  <ion-toolbar>
  <ion-title [translate]="text.profile.my_profile"></ion-title>
  <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" >
          <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      </ion-buttons>
  </ion-toolbar>
  </ion-header>
  <ion-content class="dialog">
    <div *ngIf="loading" [translate]="text.general.loading"></div>
    <div *ngIf="!loading" class="dialog-content">

      <ion-list>
        <ion-item *ngIf="userState">
          <ion-label>
            <div>{{userState.firstName}} {{userState.lastName}}</div>
            <div>{{userState.email}}</div>
          </ion-label>
        </ion-item>
        <!--<ion-item lines="none" *ngIf="userState">
          <ion-label>
            {{userState.email}}
          </ion-label>  
        </ion-item>-->
        <ion-item >
          <ion-label [translate]="text.profile.email_notifications"></ion-label>
          <ion-checkbox (ionChange)="onToggleEmail($event)" [(ngModel)]="settings.enable_email"></ion-checkbox>
        </ion-item>
        <!--
        <ion-item >
          <ion-label *ngIf="reportConfig != null" [translate]="text.profile.email_weekly_report"></ion-label>
          <ion-checkbox *ngIf="reportConfig != null" (ionChange)="onWeeklyReportChange($event)" [(ngModel)]="reportConfig.subscribed"></ion-checkbox>
        </ion-item>
        -->
        <ion-item>
          <ion-select [(ngModel)]="settings.locale" (ngModelChange)="updateLocale()" interface="popover" >
            <ion-select-option value="" [translate]="text.general.select_language"></ion-select-option>
            <ion-select-option *ngFor="let lang of languages | sortLocale:'name_en'" [value]="lang.locale">{{lang.name_en}}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item button (click)="openCalibrations()">
          <ion-label>{{text.profile.moisture_calibration | translate}}</ion-label>
          <!--<ion-button (click)="openCalibrations()" color="medium">{{text.profile.moisture_calibration | translate}}</ion-button>-->
        </ion-item>
        <ion-item>
        <ion-label [translate]="text.profile.show_ambient_as_humidity"></ion-label>
          <ion-checkbox (ionChange)="saveSettings()" [(ngModel)]="settings.is_ambient_humidity"></ion-checkbox>
        </ion-item>

        <ion-item>
          <ion-button color="primary" (click)="logout()" [translate]="text.profile.logout"></ion-button>
        </ion-item>

        <ion-accordion-group style="margin-top: 20px">
          <ion-accordion value="advanced">
            <ion-item slot="header" >
              <ion-label>{{text.profile.advanced | translate}}</ion-label>
            </ion-item>
            <div class="ion-padding" slot="content">
            <!--<ion-item button (click)="downloadReport()">
              <ion-label [translate]="text.profile.send_telemetry_data_title"></ion-label>
            </ion-item>-->
            <ion-item button (click)="showChangePassword()">
              <ion-label  [translate]="text.profile.change_password"></ion-label>
            </ion-item>
              <ion-item>
                <ion-button style="margin-left: auto;" color="danger" (click)="deleteAccount()" [translate]="text.profile.delete_account"></ion-button>
              </ion-item>
            </div>
          </ion-accordion>
        </ion-accordion-group>
        
      </ion-list>
      
    </div>
  </ion-content>
  `,
  styleUrls: ['login-page.scss']
})
export class ProfilePageComponent {

  constructor (
    private farmer: FarmerService, 
    private dialogRef: ModalController,
    private auth: AuthService,
    private alertController: AlertController, 
    private payment: PaymentService,
    private attributes: AttributeService,
    private translate: TranslateService,
    private appService: AppService,
    private router: Router,
    private modalController: ModalController,
    private agModal: AgModal,
    private profile: ProfileService
  ) {}

  text = TEXT
  loading = true
  
  userState: User
  languages = LANGUAGES

  isProduction = environment.key == 'live'
  settings: IProfileSettings

  async updateLocale () {
    this.appService.setLocale(this.settings.locale)
    await this.saveSettings()
    await this.payment.updateCustomer(this.settings.locale)
  }

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  ngOnInit () {
    this.loadReport()
    this.listen(
      this.profile.settings.subscribe(settings => {
        this.settings = settings
        this.loading = false
      })
    )
    this.listen(
      this.auth.getAuth().subscribe(auth => {
        this.userState = auth.userDetails
      })
    )
  }

  reportConfig
  async loadReport () {
    let result = await this.payment._request('GET', 'report/config')
    this.reportConfig = result
  }
  async onWeeklyReportChange (evt) {
    let checked = evt.target.checked
    if (!this.reportConfig) throw new Error('no report config')
    this.reportConfig.subscribed = checked
    await this.saveReport()
  }
  async saveReport () {
    await this.payment._request('POST', 'report/config', this.reportConfig)
    await this.loadReport()
  }

  async downloadReport () {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.profile.send_telemetry_data_title),
      message: this.translate.instant(this.text.profile.send_telemetry_data_info),
      inputs: [
        {
          name: 'email',
          value: this.userState.email,
        }
      ],
      buttons: [
        {
          text: this.translate.instant(this.text.general.cancel),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        }, 
        {
          text: this.translate.instant(this.text.general.ok),
          handler: async (data) => {
            try {
              await this.payment._request('POST', 'report/build', {full: true, email: data.email})
              const errorAlert = await this.alertController.create({
                message: this.translate.instant(this.text.profile.send_telemetry_data_success)
              })
              await errorAlert.present()
            } catch (err) {
              const errorAlert = await this.alertController.create({
                message: this.translate.instant(this.text.profile.send_telemetry_data_failed)
              })
              await errorAlert.present()
            }
            
          }
        }
      ]
    })
    await alert.present()
  }

  async saveSettings () {
    await this.profile.saveSettings(this.settings)
  }

  async openCalibrations () {
    const dialogRef = await this.modalController.create({
      component: CropCallibrationComponent, componentProps: {}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }
  

  

  async onToggleEmail (evt) {
    let checked = evt.target.checked
    this.settings.enable_email = checked
    await this.saveSettings()
  }

  async showChangePassword () {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.profile.change_password),
      inputs: [
        {
          name: 'currentPassword',
          type: 'password',
          placeholder: this.translate.instant(this.text.profile.current_password)
        },
        {
          name: 'newPassword',
          type: 'password',
          placeholder: this.translate.instant(this.text.profile.new_password)
        }
      ],
      buttons: [
        {
          text: this.translate.instant(this.text.general.cancel),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, 
        {
          text: this.translate.instant(this.text.general.ok),
          handler: async (data) => {
            if (!data.currentPassword || !data.newPassword) return false
            try {
              this.farmer.changePassword(data)
            } catch (err) {
              const errorAlert = await this.alertController.create({
                message: this.translate.instant(this.text.profile.change_password_failed)
              })
              errorAlert.present()
            }
          }
        }
      ]
    })
    await alert.present();
  }

  async deleteAccount () {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.profile.delete_account_title),
      message: this.translate.instant(this.text.profile.delete_account_confirm),
      buttons: [
        {
          text: this.translate.instant(this.text.general.cancel),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, 
        {
          text: this.translate.instant(this.text.general.yes),
          handler: async (data) => {
            let message = this.translate.instant(this.text.profile.delete_account_success) // "Your request have been received. You will receive a confirmation mail when your request for deletion have been processed. Please contact sales@supertech.dk for any question."
            //let header = "Request received"
            try {
              await this.payment.sendDeletionRequest()
            } catch (err) {
              message = this.translate.instant(this.text.profile.delete_account_failed) // "Failed to send request. Please contact us directly on sales@supertech.dk"
            }
            let confirmAlert = await this.alertController.create({
              cssClass: 'my-custom-class',
              header: this.translate.instant(this.text.profile.delete_account_title),
              message: message,
              buttons: [
                {
                  text: this.translate.instant(this.text.general.ok),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                  }
                }
              ]
            })
            await confirmAlert.present();
            
            /*
            let confirmAlert = await this.alertController.create({
              cssClass: 'my-custom-class',
              header: this.translate.instant(this.text.profile.delete_account_title),
              message: this.translate.instant(this.text.profile.delete_confirm_input_message),
              inputs: [
                {
                  placeholder: 'delete',
                  attributes: {},
                  handler: (input) => {
                  }
                }
              ],
              buttons: [
                {
                  text: this.translate.instant(this.text.general.cancel),
                  handler: () => {
                  }
                },
                {
                  text: this.translate.instant(this.text.general.confirm),
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: async (data) => {
                    
                  }
                }
              ]
            })
            await confirmAlert.present();*/
            
          }
        }
      ]
    })
    await alert.present();
  }

  logout () {
    //console.error('LOGOUT')
    this.auth.logout()
    this.dismiss()
  }

  dismiss () {
    this.dialogRef.dismiss()
  }
}
