import { Component, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Device } from "src/app/models/entity.model"
import { FarmerService } from "src/app/services/device.service"
import { EntityService } from "src/app/services/entity.service"
import { UnitService } from "src/app/services/unit"
import { TEXT } from "src/app/texts"
import { indexColor } from "src/app/util"
import { IChartInfo } from "../ag-plot/ag-plot"


@Component({
  selector: 'device-chart',
  template: `
  <!--<div style="background: white; width: 100%; height: 100%;">-->
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title>{{title}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <!--<ion-buttons slot="start">
  <ion-back-button defaultHref="/"></ion-back-button>
  </ion-buttons>-->
  <ion-content >
    <div *ngIf="chart && device" fxFlex fxLayout="column" style="padding: 15px; background-color: white; height: 100%"> 
      <div fxFlex class="temp-history-container md-padding">
        <ag-plot [entity]="device.id" [axis]="chart.axes" [timeseries]="chart.timeseries"></ag-plot>
      </div>
      <div class='ag-plot-bottom'>
        <div class="ag-plot-legend">
          <div class="ag-legend-item" style="margin: 5px 15px;" (click)="toggleTimeserie(ts.name)" *ngFor="let ts of chart.timeseries">
            <div class="ag-legend-icon" [style.background]="ts.disabled ? 'white' : ts.color"></div>
            <div class="ag-legend-value">{{ts.unit}} {{ts.label|translate}}</div>
          </div>
        </div>
      </div>
    </div>
  
  </ion-content>
`,
  styleUrls: ['../cellular-spear/spear-overview.scss']
})
export class DeviceChart {
  @Input() chart: IChartInfo
  @Input() device: Device


  constructor (
    private route: ActivatedRoute, 
    private entityService: EntityService,
    private farmer: FarmerService,
    private unit: UnitService
    ) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  title = ''

  async loadEntity (id: string) {
    this.device = await this.entityService.getDeviceById(id)
    this.listen(
      this.farmer.getCellularSpear(this.device).load().subscribe(e => this.setupChart())
    )
    this.setupChart()
  }

  toggleTimeserie (name: string) {
    this.chart.timeseries = this.chart.timeseries.map(ts => {
      if (ts.name == name) {
        return {...ts, disabled: !ts.disabled}
      }
      return ts
    })
  }
  setupChart () {
    let device = this.farmer.getCellularSpear(this.device)
    let sensors = device.sensors
    this.title = this.device.label + ' Timeserie'
    
    let chart: IChartInfo = {
      name: '', axes: [
        {show: true, position: 'left'}, 
        {show: true, position: 'right', tickFormatter: (val, obj) => val.toFixed() + this.unit.moistureUnitSymbol()},
        {show: true, position: 'bottom'}
      ], timeseries: [], figures: []
    }
    let index = 0
    sensors.map(sensor => {
      if (sensor.hasTemperature()) {
        chart.timeseries.push({
          name: sensor.temperatureKey(), label: sensor.title, // TEXT.chart.temperature_label,
          color: indexColor(index), unit: this.unit.temperatureUnitSymbol()
        })
        index += 1
      }
      if (sensor.hasMoisture()) {
        chart.timeseries.push({
          name: sensor.moistureKey(), label: sensor.title, // TEXT.chart.moisture_label,
          color: indexColor(index), yaxis: 2, unit: this.unit.moistureUnitSymbol()
        })
        index += 1
      }
    })
    this.chart = chart
  }

  ngOnInit () {
    this.listen(this.route.params.subscribe(params => {
      this.loadEntity(params['entityId'])
    }));
  }
}
