

<ion-content id="superpro-content">
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
              <ion-back-button defaultHref="/"></ion-back-button>
            </ion-buttons>
            <ion-title>{{title}}</ion-title>
            <ion-buttons slot="primary">
                <ion-button (click)="showMeasurementMap()">
                    <ion-icon name="map-sharp"></ion-icon>
                </ion-button>
                <ion-button (click)="showSettings()">
                    <ion-icon name="settings-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
          </ion-toolbar>
    </ion-header>
    <ag-loading [loading]="loading" message="{{text.general.loading | translate}}" (retry)="resubscribe()"></ag-loading>
    <div class="measurement-list">
        <div (click)="openAddMeasurementDialog(measurement)" class="measurement-card ag-card flex-auto" *ngFor="let measurement of measurements" fxLayout="column">
            <div fxFlex fxLayout="column" class="flex-auto" > 
                <div fxLayout="row" class="moisture-row flex-auto" fxLayoutAlign="start start">
                    <div fxLayout="row" class="moisture-row flex-auto">
                        <ion-icon class="icon" [ngStyle]="{fill: colors.minColor}" src="/assets/svg/iconset/ag-moisture.svg" ></ion-icon>  
                        <span class="moisture-value" >{{measurement.moistureContent | number:'1.1-1'}}%</span>
                    </div>
                    <div fxLayout="row" style="margin-left: 10px" class="moisture-row flex-auto" *ngIf="measurement.temperatureContent != null">
                        <ion-icon class="icon" [ngStyle]="{fill: colors.maxColor}" src="/assets/svg/iconset/ag-thermometer.svg" ></ion-icon>  
                        <span class="moisture-value" >{{measurement.temperatureContent | number:'1.1-1'}}°</span>
                    </div>
                </div>
                <div fxFlex fxLayout="row" class="flex-auto" >
                    <div *ngIf="deviceHasCrop()" class="moisture-crop">{{measurement.cropType | cropLabel | async}}</div>
                    
                    <div fxFlex fxLayout="column" fxLayoutAlign="end end">
                        <div class="measurement-date">{{measurement.ts | sinceNow}}</div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <ion-fab *ngIf="isLoaded && hasContent" vertical="bottom" horizontal="end" slot="fixed" (click)="openAddMeasurementDialog()">
        <ion-fab-button color="primary">
            <ion-icon name="add"></ion-icon>
        </ion-fab-button>
    </ion-fab>
    <ion-fab *ngIf="isLoaded && !hasContent" vertical="center" horizontal="center" slot="fixed" (click)="openAddMeasurementDialog()">
        <ion-fab-button color="primary">
            <ion-icon name="add"></ion-icon>
        </ion-fab-button>
        <ion-button color="primary" style="transform: translate(-35%, 0); text-transform: uppercase;" [translate]="text.general.add_measurement"></ion-button>
    </ion-fab>
</ion-content>
