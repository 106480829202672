<div class="tb-absolute-fill ag-asset-comments" fxLayout="column">
  <div flex class="ag-card asset-comments-container" fxLayout="column">
      <div id="asset-scroll-panel" class="md-whiteframe-z1 asset-comments-list">
          <div class="asset-comment-card" *ngFor="let comment of assetComments">
              <div flex class="asset-comment-header" fxLayout="row" fxLayoutAlign="space-between center">
                  <div flex fxLayout="row" fxLayoutXs='column' >
                      <div class="asset-comment-user">{{comment.userName}}</div>
                      <div class="asset-comment-time">{{comment.timestamp | date : "MMM d, y ' at ' h:mm a"}}</div>
                  </div>
                  <div flex fxLayout="row" fxLayoutAlign="end center">
                      <div *ngIf="comment.edited" class="asset-comment-edit-mark">
                        edited
                      </div>
                      <button *ngIf="allowCommentsEditing" class="md-icon-button" 
                              (click)="enterCommentEditMode(comment)">
                              <ion-icon name="create-outline"></ion-icon>
                      </button>
                      <button *ngIf="allowCommentsEditing" class="md-icon-button" 
                              (click)="deleteComment($event, comment)">
                              <ion-icon name="close-outline"></ion-icon>
                      </button>
                  </div>
              </div>
              <div  [innerHTML]="linebreaks(comment.commentValue)"></div>
          </div>
      </div>
      <div *ngIf="!commentEditMode" flex class="asset-comment-panel" fxLayout="column">
          <textarea flex class="asset-comment-input" [(ngModel)]="newComment"></textarea>
          <div fxLayout="row" style="margin:5px 0 5px 10px;">
              <span flex-xs flex-sm></span>
              <button (click)="sendComment()" [disabled]="sending || !newComment"
                         class="md-raised md-primary">
                  COMMENT 
              </button>
          </div>
      </div>
      <div *ngIf="commentEditMode" flex class="asset-comment-panel" fxLayout="column">
          <textarea flex id="asset-comment-edit" class="asset-comment-input"
                    [(ngModel)]="editedCommentValue"></textarea>
          <div fxLayout="row" style="margin:5px 0 5px 10px;">
              <span flex-xs flex-sm></span>
              <button [disabled]="!editedCommentValue" (click)="editComment()"
                         class="md-raised md-primary">
                  EDIT
              </button>
              <button (click)="cancelCommentEditMode()" style="margin-left:20px;">
                CANCEL 
              </button>
          </div>
      </div>
  </div>
</div>