
///
/// Copyright © 2016-2020 The Thingsboard Authors
///
/// Licensed under the Apache License, Version 2.0 (the "License");
/// you may not use this file except in compliance with the License.
/// You may obtain a copy of the License at
///
///     http://www.apache.org/licenses/LICENSE-2.0
///
/// Unless required by applicable law or agreed to in writing, software
/// distributed under the License is distributed on an "AS IS" BASIS,
/// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
/// See the License for the specific language governing permissions and
/// limitations under the License.
///

import { EventEmitter, Injectable, NgZone } from '@angular/core';
import {
  AggregationType,
  AlarmDataCmd,
  AlarmDataUnsubscribeCmd,
  AlarmDataUpdate,
  AttributeScope,
  AttributesSubscriptionCmd, EntityDataCmd, EntityDataUnsubscribeCmd, EntityDataUpdate,
  GetHistoryCmd, isAlarmDataUpdateMsg, isEntityDataUpdateMsg, 
  SubscriptionCmd,
  SubscriptionUpdate,
  TelemetryFeature,
  TelemetryPluginCmdsWrapper,
  TimeseriesSubscriptionCmd, WebsocketDataMsg
} from '../models/telemetry.model';
import { select, Store } from '@ngrx/store';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AppState, selectAuth } from '../state';
import { EntityId, EntityKeyType, EntityType, IAlarm, PageData } from '../models/entity.model';
import { AuthService } from './auth.service';
import { BehaviorSubject, Subject } from 'rxjs';
import moment from 'moment-es6';
import { clone, onAuthenticationChange, onConnectionState } from '../util';
import { debounceTime } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { ConnectionService } from './device.service';

const RECONNECT_INTERVAL = 2000;
const WS_IDLE_TIMEOUT = 90000;
const MAX_PUBLISH_COMMANDS = 10;

export interface IAlarmUpdate {
  data: PageData<IAlarm> | null
  update: IAlarm[]
  dataUpdateType: any
  errorCode: number
  errorMsg: string | null
  totalEntities: number
}

export interface TelemetrySubscriber {
  subscriptionCommands: any[]
  onReconnected()
  onEntityData(update: EntityDataUpdate)
  onData(update: SubscriptionUpdate): boolean
  onAlarmData (update: AlarmDataUpdate): boolean
}

export interface IWebsocketState {
  isActive: boolean, isOpening: boolean, isOpened: boolean, isReconnect: boolean
  errorMessage: string
}

type Unsubscriber = () => void


// @dynamic
/*
{
  providedIn: 'root'
}
*/
@Injectable()
export class TelemetryWebsocketService { //  implements TelemetryService

  static loaded = false

  isActive = false;
  isOpening = false;
  isOpened = false;
  isReconnect = false;
  errorMessage = ''

  socketCloseTimer: number;
  reconnectTimer: number;

  lastCmdId = 0;
  subscribersCount = 0;
  subscribersMap = new Map<number, TelemetrySubscriber>();

  reconnectSubscribers = new Set<TelemetrySubscriber>();

  cmdsWrapper = new TelemetryPluginCmdsWrapper();
  telemetryUri: string;

  dataStream: WebSocketSubject<TelemetryPluginCmdsWrapper | WebsocketDataMsg>;
  state = new BehaviorSubject<IWebsocketState>({
    isActive: false, isOpened: false, isOpening: false, isReconnect: false, errorMessage: ''
  })
  
  schedule = new Subject()
  online = false

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    public ngZone: NgZone
  ) {
    onAuthenticationChange(this.store).subscribe(x => {
      if (x) {
        this.reset(true)
      } else {
        this.closeSocket()
      }
    })
    onConnectionState().subscribe(x => {
      if (x != this.online) {
        this.online = x
      }
    })

    this.store.select((state) => state.host).subscribe(host => {
      this.telemetryUri = host.ws + "/api/ws/plugins/telemetry"
    })
    this._refresh = this.schedule.pipe(debounceTime(1000)).subscribe(x => {
      this.ngZone.run(() => {})
    })
  }
  _refresh
  
  emitState () {
    let state: IWebsocketState = {
      isActive: this.isActive, isOpened: this.isOpened, isOpening: this.isOpening, 
      isReconnect: this.isReconnect, errorMessage: this.errorMessage
    }
    if (!this.state.value || !isEqual(state, this.state.value))
      this.state.next(state)
  }

  public subscribe(subscriber: TelemetrySubscriber) {
    this.ngZone.runOutsideAngular(() => {
      this.isActive = true;
      subscriber.subscriptionCommands.forEach(
        (subscriptionCommand) => {
          const cmdId = this.nextCmdId();
          this.subscribersMap.set(cmdId, subscriber);
          subscriptionCommand.cmdId = cmdId;
          if (subscriptionCommand instanceof SubscriptionCmd) {
            if (subscriptionCommand.getType() === TelemetryFeature.TIMESERIES) {
              this.cmdsWrapper.tsSubCmds.push(subscriptionCommand as TimeseriesSubscriptionCmd);
            } else {
              this.cmdsWrapper.attrSubCmds.push(subscriptionCommand as AttributesSubscriptionCmd);
            }
          } else if (subscriptionCommand instanceof GetHistoryCmd) {
            this.cmdsWrapper.historyCmds.push(subscriptionCommand);
          } else if (subscriptionCommand instanceof EntityDataCmd) {
            this.cmdsWrapper.entityDataCmds.push(subscriptionCommand);
          } else if (subscriptionCommand instanceof AlarmDataCmd) {
            this.cmdsWrapper.alarmDataCmds.push(subscriptionCommand);
          } else {
            throw new Error('unknown subscription command: ' + subscriptionCommand)
          }
        }
      );
      this.subscribersCount++;
      this.publishCommands();
      this.emitState()
    })
  }

  public update(subscriber: TelemetrySubscriber) {
    if (!this.isReconnect) {
      subscriber.subscriptionCommands.forEach(
        (subscriptionCommand) => {
          if (subscriptionCommand.cmdId && subscriptionCommand instanceof EntityDataCmd) {
            this.cmdsWrapper.entityDataCmds.push(subscriptionCommand);
          }
        }
      );
      this.publishCommands();
    }
  }

  public unsubscribe(subscriber: TelemetrySubscriber) {
    if (this.isActive) {
      subscriber.subscriptionCommands.forEach(
        (subscriptionCommand) => {
          if (subscriptionCommand instanceof SubscriptionCmd) {
            subscriptionCommand.unsubscribe = true;
            if (subscriptionCommand.getType() === TelemetryFeature.TIMESERIES) {
              this.cmdsWrapper.tsSubCmds.push(subscriptionCommand as TimeseriesSubscriptionCmd);
            } else {
              this.cmdsWrapper.attrSubCmds.push(subscriptionCommand as AttributesSubscriptionCmd);
            }
          } else if (subscriptionCommand instanceof EntityDataCmd) {
            const entityDataUnsubscribeCmd = new EntityDataUnsubscribeCmd();
            entityDataUnsubscribeCmd.cmdId = subscriptionCommand.cmdId;
            this.cmdsWrapper.entityDataUnsubscribeCmds.push(entityDataUnsubscribeCmd);
          } else if (subscriptionCommand instanceof AlarmDataCmd) {
            const alarmDataUnsubscribeCmd = new AlarmDataUnsubscribeCmd();
            alarmDataUnsubscribeCmd.cmdId = subscriptionCommand.cmdId;
            this.cmdsWrapper.alarmDataUnsubscribeCmds.push(alarmDataUnsubscribeCmd);
          } else if (subscriptionCommand instanceof GetHistoryCmd) {
          } else {
            throw new Error('unknown subscription command: ' + subscriptionCommand)
          }
          const cmdId = subscriptionCommand.cmdId;
          if (cmdId) {
            this.subscribersMap.delete(cmdId);
          }
        }
      );
      this.reconnectSubscribers.delete(subscriber);
      this.subscribersCount--;
      this.publishCommands();
    }
  }

  private nextCmdId(): number {
    this.lastCmdId++;
    return this.lastCmdId;
  }

  private publishCommands() {
    while (this.isOpened && this.cmdsWrapper.hasCommands()) {
      let cmd = this.cmdsWrapper.preparePublishCommands(MAX_PUBLISH_COMMANDS)
      this.dataStream.next(cmd);
      this.checkToClose();
    }
    this.tryOpenSocket();
  }

  private checkToClose() {
    if (this.subscribersCount === 0 && this.isOpened) {
      if (!this.socketCloseTimer) {
        this.socketCloseTimer = window.setTimeout(
          () => this.closeSocket(), WS_IDLE_TIMEOUT);
      }
    }
  }

  private reset(close: boolean) {
    if (this.socketCloseTimer) {
      clearTimeout(this.socketCloseTimer);
      this.socketCloseTimer = null;
    }
    this.lastCmdId = 0;
    this.subscribersMap.clear();
    this.subscribersCount = 0;
    this.cmdsWrapper.clear();
    if (close) {
      this.closeSocket();
    }
    this.emitState()
  }

  private closeSocket() {
    this.isActive = false;
    if (this.isOpened) {
      this.dataStream.unsubscribe();
    }
    this.emitState()
  }

  private tryOpenSocket() {
    if (!this.online) {
      this.reconnectTimer = window.setTimeout(() => this.tryOpenSocket(), RECONNECT_INTERVAL);
      return
    }
    if (this.isActive) {
      
      if (!this.isOpened && !this.isOpening) {
        this.isOpening = true;
        if (AuthService.isJwtTokenValid()) { 
          this.openSocket(AuthService.getJwtToken());
        } else {
          this.authService.refreshJwtToken().subscribe(() => {
              this.openSocket(AuthService.getJwtToken());
            },
            () => {
              this.isOpening = false;
              this.authService.logout(true);
              this.emitState()
            }
          );
        }
        this.emitState()
      }
      if (this.socketCloseTimer) {
        clearTimeout(this.socketCloseTimer);
        this.socketCloseTimer = null;
      }
    }
  }

  private openSocket(token: string) {
    
    const uri = `${this.telemetryUri}?token=${token}`;
    this.dataStream = webSocket(
      {
        url: uri,
        openObserver: {
          next: (e: Event) => {
            this.onOpen();
          }
        },
        closeObserver: {
          next: (e: CloseEvent) => {
            this.onClose(e);
          }
        }
      }
    );

    this.dataStream.subscribe((message) => {
        this.ngZone.runOutsideAngular(() => {
          this.onMessage(message as WebsocketDataMsg);
          
        });
    },
    (error) => {
      this.onError(error);
    });
  }

  private onOpen() {
    this.isOpening = false;
    this.isOpened = true;
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }
    if (this.isReconnect) {
      this.isReconnect = false;
      this.reconnectSubscribers.forEach(
        (reconnectSubscriber) => {
          reconnectSubscriber.onReconnected();
          this.subscribe(reconnectSubscriber);
        }
      );
      this.reconnectSubscribers.clear();
    } else {
      this.publishCommands();
    }
    this.emitState()
  }

  private onMessage(message: WebsocketDataMsg) {
    if (message.errorCode) {
      this.showWsError(message.errorCode, message.errorMsg);
    } else {
      let subscriber: TelemetrySubscriber;
      let hasChanges = false
      if (isEntityDataUpdateMsg(message)) {
        subscriber = this.subscribersMap.get(message.cmdId);
        if (subscriber) {
          hasChanges = subscriber.onEntityData(new EntityDataUpdate(message));
        }
      } else if (isAlarmDataUpdateMsg(message)) {
        subscriber = this.subscribersMap.get(message.cmdId);
        if (subscriber) {
          hasChanges = subscriber.onAlarmData(new AlarmDataUpdate(message));
        }
      } else if (message.subscriptionId) {
        subscriber = this.subscribersMap.get(message.subscriptionId);
        if (subscriber) {
          hasChanges = subscriber.onData(new SubscriptionUpdate(message));
        }
      }
      if (hasChanges) {
        this.schedule.next(1)
      } else { 

      }
    }
    this.checkToClose();
    
  }

  private onError(errorEvent) {
    if (errorEvent) {
      this.errorMessage = errorEvent.toString()
    }
    this.isOpening = false;
    this.emitState()
  }

  private onClose(closeEvent: CloseEvent) {
    if (closeEvent && closeEvent.code > 1001 && closeEvent.code !== 1006) {
      this.showWsError(closeEvent.code, closeEvent.reason);
    }
    this.isOpening = false;
    this.isOpened = false;
    if (this.isActive) {
      if (!this.isReconnect) {
        this.reconnectSubscribers.clear();
        this.subscribersMap.forEach(
          (subscriber) => {
            this.reconnectSubscribers.add(subscriber);
          }
        );
        this.reset(false);
        this.isReconnect = true;
      }
      if (this.reconnectTimer) {
        clearTimeout(this.reconnectTimer);
      }
      this.reconnectTimer = window.setTimeout(() => this.tryOpenSocket(), RECONNECT_INTERVAL);
    }
    this.emitState()
  }

  private showWsError(errorCode: number, errorMsg: string) {
    let message = errorMsg;
    if (!message) {
      message += `WebSocket Error: error code - ${errorCode}.`;
    }
    this.errorMessage = message
    this.emitState()
    //console.error('IMLEMENT WEBSOCKET ERROR', message)
    //this.store.dispatch(new ActionNotificationShow(
    //  {
    //    message, type: 'error'
    //  }));
  }

}



//import { Injectable } from '@angular/core';
//import { AppState } from '../state';

//import { TelemetryWebsocketService } from 'src/thingsboard/core/ws/telemetry-websocket.service'

/*interface EntitySubscriper {
  keys?: string []
  onData(update: SubscriptionUpdate)
}*/

function now () { return new Date().getTime() }
export type TELEMETRY_MODE = 'HISTORY' | 'TIME-WINDOW'
export interface ITelemetrySettings {
  timeWindow?: number
  interval?: number
  agg?: AggregationType
  startTs?: number, endTs?: number
  mode?: TELEMETRY_MODE
}
export interface ITimeRange {
  min: number, max: number
}


@Injectable()
export class TelemetryService {
  
  onSettingUpdate = new EventEmitter()

  subscriptions: TimeseriesSubsciption[] = []
  settings: ITelemetrySettings = {
    timeWindow: (1000 * 60 * 60 * 24 * 1),
    interval: 1000 * 60 * 5,
    agg: AggregationType.AVG,
    mode: 'TIME-WINDOW', startTs: 0, endTs: 0
  }

  constructor (public socket: TelemetryWebsocketService, private ngZone: NgZone) {
    this.loadSettings()
  }

  loadSettings () {
    let data = JSON.parse(localStorage.getItem('_timewindow'))
    if (typeof data == 'object') {
      Object.assign(this.settings, data)
    }
  }

  updateSettings (opt: ITelemetrySettings) {
    this.settings = Object.assign({}, opt)
    localStorage.setItem('_timewindow', JSON.stringify(this.settings))
    let tsSubs = this.subscriptions.filter(s => s.isTimeseries() && s.isSubscribed())
    tsSubs.forEach(sub => {
      if (sub.isTimeseries()) {
        sub.update(this.settings)
      }
    })
    this.onSettingUpdate.emit(clone(this.settings))
  }
  latest (entityId: EntityId<any>, opt: ITelemetrySubscriptionOptions, callbacks: ISubscriptionCallbacks) {
    return this.subscribe(
      entityId, Object.assign({scope: 'LATEST_TELEMETRY'}, opt), callbacks, {}
    )
  }
  subscribe (entityId: EntityId<any>, opt: ITelemetrySubscriptionOptions, callbacks: ISubscriptionCallbacks, settings?: ITelemetrySettings) {
    if (!settings) settings = this.settings
    let subscription = new TimeseriesSubsciption(this.socket, settings)
    subscription.subscribe({
      entity: entityId, options: opt, callbacks: callbacks
    })
    this.subscriptions.push(subscription)
    return subscription
  }

  subscribeEntities () {
    let cmd = new EntityDataCmd()
    cmd.query = {
      pageLink: {
        page: 0, pageSize: 20,
        sortOrder: {
          key: {
            type: EntityKeyType.ENTITY_FIELD,
            key: 'createdTime'
          },
          direction: 'DESC'
        }
      }, //, timeWindow: 86400000},
      entityFilter: {
        type: 'entityList',
        entityType: 'CUSTOMER'
      },
      

      //searchPropagatedAlarms: true, severityList: ['CRITICAL'],
      // textSearch: null, 
      //statusList: [], typeList: [], // types.concat(['TEST']),
      /*sortOrder: {
        key: {
          key: 'createdTime', type: 'ALARM_FIELD'
        }, direction: 'DESC'
      } */
      //entityFields: [],
      /*{
        type: EntityKeyType.ALARM_FIELD, key: 'status'
      } */
      
      //keyFilters: [],
      //latestValues: [] //{type: 'ENTITY_FIELD', key: 'status'}]

    } as any
    let latestCmd = {
      keys: [{type: EntityKeyType.ALARM_FIELD, key: 'status'}]
    }
    let subscriber: TelemetrySubscriber = {
      onData: (v) => {console.error('ENTITY DATA', v); return false},
      onEntityData: (d) => {console.error('ENTITY DATA', d)},
      onReconnected: () => {
        //console.error('RECONNECT')
      },
      onAlarmData: (d) => { return false },
      subscriptionCommands: [cmd]
    }
    this.socket.subscribe(subscriber)
    return subscriber
  }

  /*subscribeEntityRelations (entityId: EntityId<any>) {
    {
      rootStateEntity?: boolean;
      stateEntityParamName?: string;
      defaultStateEntity?: EntityId;
      rootEntity?: EntityId;
      direction?: EntitySearchDirection;
      filters?: Array<EntityTypeFilter>;
      maxLevel?: number;
      fetchLastLevelOnly?: boolean;
    }
  }*/

  subscribeEntity (entityId: EntityId<any>, onData) {
    let cmd = new EntityDataCmd()
    let entityFilter = {
      //rootStateEntity: entityId,
      //stateEntityParamName: null,
      //defaultStateEntity: entityId,
      rootEntity: entityId,
      direction: 'FROM',
      //filters: [],
      //maxLevel: 1,
      //fetchLastLevelOnly: false,
      type: 'relationsQuery'
      /*singleEntity: {
        entityType: entityId.entityType,
        id: entityId.id
      },
      type: 'singleEntity'*/
    } as any
    let _entityFilter = {
      singleEntity: {
        entityType: entityId.entityType,
        id: entityId.id
      },
      type: 'singleEntity'
    }
    cmd.query = {
      pageLink: {
        page: 0, pageSize: 20,
        sortOrder: {
          key: {
            type: EntityKeyType.ENTITY_FIELD,
            key: 'createdTime'
          },
          direction: 'DESC'
        }
      }, //, timeWindow: 86400000},
      entityFilter: entityFilter,
      
      //searchPropagatedAlarms: true, severityList: ['CRITICAL'],
      // textSearch: null, 
      //statusList: [], typeList: [], // types.concat(['TEST']),
      /*sortOrder: {
        key: {
          key: 'createdTime', type: 'ALARM_FIELD'
        }, direction: 'DESC'
      } */
      entityFields: [{type: EntityKeyType.ENTITY_FIELD, key: 'name'}],
      /*{
        type: EntityKeyType.ALARM_FIELD, key: 'status'
      } */
      
      //keyFilters: [],
      latestValues: [] // [{type: EntityKeyType.TIME_SERIES, key: 'temperature'}]

    } as any
    let latestCmd = {
      keys: [{type: EntityKeyType.ALARM_FIELD, key: 'status'}]
    }
    let subscriber: TelemetrySubscriber = {
      onData: (v) => {console.error('ENTITY DATA', v); return false},
      onEntityData: (d) => {console.error('ENTITY DATA', d)},
      onReconnected: () => {
      },
      onAlarmData: (d) => {
        return onData(d.data)
      },
      subscriptionCommands: [cmd]
    }
    this.socket.subscribe(subscriber)
    return subscriber
  }

  subscribeAlarms (entityId: EntityId<any>, types: string[], onData: (v: AlarmDataUpdate) => boolean) {
    //let cmd = new EntityDataCmd()
    let cmd = new AlarmDataCmd() as any
    /*cmd.query = {
      pageLink: {
        page: 0, pageSize: 1024, timeWindow: 86400000,
        searchPropagatedAlarms: true, severityList: ['CRITICAL'],
        // textSearch: null, 
        statusList: [], typeList: [], // types.concat(['TEST']),
        sortOrder: {
          key: {
            key: 'createdTime', type: 'ALARM_FIELD'
          }, direction: 'DESC'
        }
      },
      entityFilter: {
        alarmFields: [
          {key: 'createdTime', type: 'ALARM_FIELD'},
          {type: 'ALARM_FIELD', key: 'status'}
        ],
        singleEntity: {
          entityType: entityId.entityType,
          id: entityId.id
        },
        type: 'singleEntity',
      },
      entityFields: [{
        type: 'ENTITY_FIELD', key: 'status'
      }],
      latestValues: [{
        type: 'ALARM_FIELD', key: 'createdTime'
      }, {
        type: 'ALARM_FIELD', key: 'status'
      }]
      
    }*/
    cmd.query = {
      alarmFields: [
        {
          type: "ALARM_FIELD",
          key: "createdTime"
        },
        {
          type: "ALARM_FIELD",
          key: "originator"
        },
        {
          type: "ALARM_FIELD",
          key: "type"
        },
        {
          type: "ALARM_FIELD",
          key: "severity"
        },
        {
          type: "ALARM_FIELD",
          key: "status"
        }
      ],
      entityFields: [],
      entityFilter: {
        singleEntity: {
          entityType: entityId.entityType,
          id: entityId.id
        },
        type: "singleEntity"
      },
      latestValues: [],
      pageLink: {
        page: 0,
        pageSize: 50,
        searchPropagatedAlarms: false,
        severityList: [],
        statusList: [],
        textSearch: null,
        timeWindow: 86400000 * 24 * 30,
        typeList: types,
        sortOrder: {
          key: {key: "createdTime", type: "ALARM_FIELD"},
          direction: "DESC"
        }
      }
    }
    let subscriber: TelemetrySubscriber = {
      onData: (v) => {console.error('DATA', v); return false},
      onEntityData: (d) => {console.error('DATA', d)},
      onReconnected: () => {},
      onAlarmData: (d) => {
        return onData(d)
      },
      subscriptionCommands: [cmd]
    }
    this.socket.subscribe(subscriber)
    return subscriber
  }

  toDataArray (data: ITelemetryData) {
    let values = data.data
    let result: ITelemetryDataArray = {data: []}
    for (var k in values) {
      result.data.push({
        dataKey: {name: k}, data: [values[k][0]]
      })
    }
    return result
  }
}

export class TelemetryContext {
  subscriptions: TimeseriesSubsciption[] = []

  constructor (
    private telemetry: TelemetryService, private key: string, 
    private settings: ITelemetrySettings) {}
  
  get socket () { return this.telemetry.socket }

  latest (entityId: EntityId<any>, opt: ITelemetrySubscriptionOptions, callbacks: ISubscriptionCallbacks) {
    return this.subscribe(
      entityId, Object.assign({scope: 'LATEST_TELEMETRY'}, opt), callbacks
    )
  }
  subscribe (entityId: EntityId<any>, opt: ITelemetrySubscriptionOptions, callbacks: ISubscriptionCallbacks) {
    let subscription = new TimeseriesSubsciption(this.socket, this.settings)
    subscription.subscribe({
      entity: entityId, options: opt, callbacks: callbacks
    })
    this.subscriptions.push(subscription)
    return subscription
  }
}

function sum (values: number[]) {
  return values.reduce((a,b) => a + b)
}

function average (values: (number)[]) {
  
  return sum(values) / values.length
}
function utcTimestamp () {
  let date = new Date()
  let ts = new Date(date.getTime() + date.getTimezoneOffset() * 60000).getTime();
  return Math.round(ts)
}

export class TimeseriesSubsciption {
  private firstData = true
  private data: {[key: string]: [number, string|number][]} = {}
  private realtime: {[key: string]: [number, string|number][]} = {}
  //private lastTs: null | number = null
  
  private info: ITelemetrySubscriptionInfo
  private options: ITelemetrySubscriptionOptions
  private subscriber: TelemetrySubscriber = null
  private isLoading = false

  private _s
  constructor (
    private websocket: TelemetryWebsocketService,
    private settings: ITelemetrySettings
    //private ngZone: NgZone
  ) {
    
  }
  
  update (settings: ITelemetrySettings) {
    this.settings = settings
    this.subscribe(this.info)
  }


  getTimerange (): ITimeRange {
    if (this.settings.mode == 'HISTORY') {
      return {min: this.settings.startTs, max: this.settings.endTs}
    } else {
      let maxTs = new Date().getTime() // utcTimestamp()
      let minTs = maxTs - this.settings.timeWindow
      return {
        min: minTs, max: maxTs
      }
    }
  }

  isTimeseries () {
    return this.info.options.scope != 'LATEST_TELEMETRY'
  }

  emitLoading (isLoading: boolean) {
    if (isLoading != this.isLoading) {
      this.isLoading = isLoading
      this.info.callbacks.onLoading(isLoading)
      this.websocket.schedule.next(1)
    }
  }

  private prepareData (data: [number, any][]) {
    data.sort((a, b) => a[0] - b[0])
    return data
  }

  emitData (data: {[key: string]: [number, any][]}): boolean {
    let changes = false
    for (var k in data) {
      let values = this.prepareData(data[k])
      //this.lastTs = Math.max(...values.map(x => x[0]))
      //console.log(k, this.isTimeseries(), Reflect.has(this.data, k), isEqual(this.data[k], values), {data: this.data[k], values: values}, this.subscriber.subscriptionCommands.map(x => x.entityId))
      if (!Reflect.has(this.data, k)) {
        this.data[k] = data[k]
        this.realtime[k] = []
        changes = true
      } else if (this.isTimeseries()) {
        if (!Reflect.has(this.realtime, k)) this.realtime[k] = []
        let _last = this.realtime[k][this.realtime[k].length - 1]
        if (!_last) _last = this.data[k][this.data[k].length - 1]
        if (_last && _last[0] >= values[0][0]) {
          values = values.filter(x => x[0] > _last[0])
        }
        this.realtime[k].push(...values)
      } else if (!isEqual(this.data[k], values)) {
        this.data[k] = values
        changes = true
      }
    }

    if (!changes && this.isTimeseries()) {
      for (var k in this.realtime) {
        let items = this.realtime[k]
        if (items.length == 0) continue
        let duration = 0
        let startTs = this.data[k][this.data[k].length - 1][0] // items[0][0]
        let currentItems = []
        let found = false
        items.map(item => {
          //if (found) return
          duration = item[0] - startTs
          if (duration >= this.options.interval) {
            if (currentItems.length > 0) {
              let values = currentItems.map(item => typeof item[1] == 'string' ? parseFloat(item[1]) : item[1])
              let value = average(values)
              let ts = item[0] // average(currentItems.map(item => item[0]))
              if (!this.data[k]) this.data[k] = []
              //this.data[k].push([ts, value]) // = data[k].concat([[ts, value]])
              this.data[k].push(item)
              //this.data[k].push(items[items.length - 1])
              found = true 
              changes = true
            }
            startTs = item[0]
            currentItems = []
          }
          currentItems.push(item)
        })
        if (found) this.realtime[k] = []
      }
    }
    if (!changes && !this.firstData) {
      //console.error('NO CHANGES!', data)
      return false
    }

    this.firstData = false
    return this.info.callbacks.onData({data: this.data})
  }

  subscribe (info: ITelemetrySubscriptionInfo) {
    if (this.subscriber) {
      this.unsubscribe()
    }
    //this.lastTs = null
    this.firstData = true
    this.info = info
    
    this.emitLoading(true)

    let defaultOptions: ITelemetrySubscriptionOptions = {
      agg: AggregationType.AVG, limit: 500, //limit: 289, 
      interval: 300000, keys: []
    }
    
    let opt = info.options
    let entityId = info.entity
    let options = Object.assign({}, defaultOptions, this.settings, opt)
    this.options = options

    let commands = []
    if (opt.attributes && opt.attributes.length > 0) {
      let attrCmd = new AttributesSubscriptionCmd()
      attrCmd.keys = opt.attributes.join(',')
      attrCmd.entityId = entityId.id;
      attrCmd.entityType = entityId.entityType as EntityType;
      commands.push(attrCmd)
    }

    if (options.mode == 'HISTORY') {

      let cmd = new GetHistoryCmd()
      cmd.startTs = options.startTs
      cmd.endTs = options.endTs
      cmd.limit = 100
      cmd.agg = options.agg
      cmd.interval = options.interval
      cmd.keys = options.keys.join(',')
      cmd.entityType = entityId.entityType as EntityType;
      cmd.entityId = entityId.id;
      //console.error('COMMAND', cmd.entityId, cmd)
      commands.push(cmd)
    } else if (opt.keys) {
      
      let cmd = new TimeseriesSubscriptionCmd()
      if (options.scope == 'LATEST_TELEMETRY') {
        cmd.scope = 'LATEST_TELEMETRY' as AttributeScope
      } else {
        cmd.agg = options.agg
        //cmd.limit = options.limit
        cmd.interval = options.interval
        cmd.timeWindow = options.timeWindow
        cmd.startTs = now() - options.timeWindow 
      }
      cmd.keys = options.keys.join(',')
      cmd.entityType = entityId.entityType as EntityType;
      cmd.entityId = entityId.id;
      //console.error('COMMAND', cmd.entityId, cmd)
      commands.push(cmd)
    }
    this.subscriber = {
      onData: (v) => {
        return this.onData(v)
      },
      onEntityData: (d) => {console.warn('IMPLEMENT onEntityData') },
      onReconnected: () => {
        this.data = {}
        this.realtime = {}
      },
      onAlarmData: (d) => {console.error('unexpected alarm data', d); return false},
      subscriptionCommands: commands
    }
    this.websocket.subscribe(this.subscriber)
  }

  unsubscribe () {
    //console.log('unsubscribe', this.subscriber)
    this.websocket.unsubscribe(this.subscriber)
    this._s?.unsubscribe()
    this.data = {}
    this.realtime = {}
    this.subscriber = null
    this.emitData(this.data)
  }

  isSubscribed () { return this.subscriber != null}

  onData (data: ITelemetryData): boolean {
    this.emitLoading(false)
    return this.emitData(data.data)
  }
}

function printKeyValue (ts: [number, string]) {
  //let d = new Date().getTime() - 
  return moment(ts[0]).fromNow() + ' ' + ts[1]
}

export interface ITelemetrySubscriptionInfo {
  entity: EntityId<any>
  options: ITelemetrySubscriptionOptions
  callbacks: ISubscriptionCallbacks
}
export interface ITelemetryData {
  subscriptionId?: number
  data: {[key: string]: [number, any][]}
}
export interface ITelemetryDataArray {
  data: ITelemetryKeyData[]
}
export interface ITelemetryKeyData {
  dataKey: {name: string}, data: [number, any][]
}

interface ISubscriptionCallbacks {
  onData: (value: ITelemetryData) => boolean
  onLoading: (isLoading: boolean) => void
}

interface ITelemetrySubscriptionOptions {
  scope?: 'LATEST_TELEMETRY'
  startTs?: number
  keys?: string[]
  attributes?: string[]
  agg?: AggregationType
  timeWindow?: number
  limit?: number
  interval?: number
}
