import { Component, ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

import { MenuController, ModalController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppState, IFeatures } from './state';
import { AuthService } from './services/auth.service';
import { Store } from '@ngrx/store';
import { IToastMessage, ToastService } from './services/toast.service';
import { LocalStorageService } from './services/local-storage.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { AgModal, isMobile, onAuthenticationChange, onResume, shortId } from './util';
import { HttpErrorResponse } from '@angular/common/http';

import { BroadcastChannel } from 'broadcast-channel';
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

import { AttributeService } from './services/entity.service';
import { AttributeData, AttributeScope } from './models/telemetry.model';

import * as Sentry from '@sentry/angular'
import * as SmartBanner from 'smart-app-banner'
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { TEXT } from './texts';
import moment from "moment-es6";
import { DEFAULT_LOCALE, LOCALES, TRANSLATIONS } from './constant';

import { BehaviorSubject, ReplaySubject } from 'rxjs'


async function generateTranslationTemplate (locale) {
  let translationsResp = await fetch('/assets/i18n/' + locale + '.json')
  let translations = JSON.parse(await translationsResp.text())
  let result = {}
  let missing = {}
  for (var k in TRANSLATIONS) {
    if (!translations[k]) {
      missing[k] = k
      if (translations[k] != k) {
        result[k] = TRANSLATIONS[k]
      } else {
        result[k] = null
      }
    } else {
      result[k] = translations[k]
    }
  }
  let missingKeys = Object.keys(missing)
  if (missingKeys.length) {
    alert('missing translations:\n' + missingKeys.join('\n'))
  }
  console.log(JSON.stringify(result, null, 2))
  console.log(Object.keys(TRANSLATIONS).map(x => x).join('\n'))
  return result
}
//if (!environment.production) { generateTranslationTemplate('en') }

interface IAppState {
  locale?: string, ready?: boolean
}

@Injectable()
export class SessionService {
  socket: WebSocket | null = null
  messages = new ReplaySubject<any>(1)
  locale = 'da'
  constructor () {
    this.connect()
  }
  connect () {
    let host = new URL(environment.payment).hostname
    let url = 'wss://' + host + '/session'
    const socket = new WebSocket(url);
    socket.addEventListener('message', ev => {
      let content = JSON.parse(ev.data)
      this.messages.next(content)
    })
    socket.addEventListener('open', e => {
      this.socket = socket
      socket.send(JSON.stringify({locale: this.locale}))
    })
    socket.addEventListener('close', e => {
      this.socket = null
      setTimeout(() => this.connect(), 2 * 1000)
    })
    socket.addEventListener('error', e => {
      console.error('session websocket error')
      socket.close()
    })
  }
}

@Injectable()
export class AppService {
  locale = DEFAULT_LOCALE
  translations: {[key: string]: object} = {}
  onUpdate = new BehaviorSubject<IAppState>({})
  
  constructor (
    private translateService: TranslateService,
    private attributes: AttributeService,
    private ngZone: NgZone
  ) {
    this.translateService.setDefaultLang(DEFAULT_LOCALE)
    
    // NOTE: the default implementation when loading translations seems to overwrite any existing translations
    //       we need to add our dynamic server translations manually after language change
    this.translateService.onDefaultLangChange.subscribe(x => {
      let newTranslations = {...x.translations, ...this.translations[x.lang] || {}}
      this.translateService.setTranslation(x.lang, newTranslations)      
    })
    this.translateService.onLangChange.subscribe(x => {
      let newTranslations = {...x.translations, ...this.translations[x.lang] || {}}
      this.translateService.setTranslation(x.lang, newTranslations)
    })
  }

  async initialize () {
    let locale = localStorage.getItem('AGROLOG_LOCALE')
    if (locale) {
      this.setLocale(locale)
    } else {
      this.setLocale(this.getBrowserLocale())
    }
    this.connect()
  }

  async saveUser (user) {
    let locale = localStorage.getItem('AGROLOG_LOCALE')
    await this.attributes.saveEntityAttributes(
      user, AttributeScope.SERVER_SCOPE, [
        {key: 'locale', value: locale}
      ]
    ).toPromise()
  }


  socket: WebSocket | null = null
  connect () {
    let host = new URL(environment.payment).hostname
    let url = 'wss://' + host + '/session'
    const socket = new WebSocket(url);
    socket.addEventListener('message', ev => {
      let content = JSON.parse(ev.data)
      if (content.type == 'TRANSLATIONS') {
        this.translations[content.locale] = content.translations
        this.translateService.setTranslation(content.locale, content.translations, true)
      }
    })
    socket.addEventListener('open', e => {
      this.socket = socket
      socket.send(JSON.stringify({locale: this.locale}))
    })
    socket.addEventListener('close', e => {
      this.socket = null
      setTimeout(() => this.connect(), 2 * 1000)
    })
    socket.addEventListener('error', e => {
      console.error('session websocket error')
      socket.close()
    })
  }
  
  getBrowserLocale () {
    if (navigator.languages != undefined) 
      return navigator.languages[0]; 
    return navigator.language;
  }

  setLocale (locale: string) {
    // TODO: validate locale
    locale = locale.split('-')[0].toLowerCase()
    if (LOCALES.indexOf(locale) == -1) locale = DEFAULT_LOCALE
    this.translateService.use(locale)
    moment.locale(locale)
    this.locale = locale
    localStorage.setItem('AGROLOG_LOCALE', locale)
    if (this.socket) {
      this.socket.send(JSON.stringify({locale: this.locale}))
    }
  }
}

@Component({
  selector: 'admin-page',
  template: `
  <ion-list>
    <ion-item>
      <ion-label>Customer E-mail:</ion-label>
      <ion-input [(ngModel)]="userEmail"></ion-input>
    </ion-item>
    <div>{{error}}</div>
    <ion-button (click)="login()">Login</ion-button>
  </ion-list>
  `,
  styles: []
})
export class AdminPage {
  userEmail: string = ''
  error = ''

  constructor (
    private auth: AuthService
  ) {}

  login () {
    this.error = ''
    this.auth.loginAsUserEmail(this.userEmail).subscribe(x => {

    }, err => {
      this.error = err
    })
  }
}


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public cloudToken: string | null = null
  constructor(
    private title: Title,
    private store: Store<AppState>,
    private auth: AuthService,
    private router: Router,
    private menu: MenuController,
    private localStorage: LocalStorageService,
    private toastController: ToastController,
    private toast: ToastService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private permissions: AndroidPermissions,
    private modalController: ModalController,
    private ngZone: NgZone,
    private deepLinks: Deeplinks,
    private attributes: AttributeService,
    private appService: AppService,
    private route: ActivatedRoute
  ) {
  }

  public isNavigating = false

  setFeatures (features: IFeatures) {
    this.store.dispatch({type: 'UPDATE_FEATURES', payload: features})
  }

  ngOnInit() {
    // setFeatures({serverDown: true})
    window['setFeatures'] = (features: IFeatures) => this.setFeatures(features)
    window['clearToken'] = () => AuthService.clearTokenData()
    this.title.setTitle('AgroLog App')
    this.initializeApp();
  }
  ngAfterViewInit() {

    // NOTE: hack to get guards to refresh when authentication changes
    onAuthenticationChange(this.store).subscribe(x => {
      if (!this.router.navigated) return
      const currentUrl = this.router.url
      this.ngZone.run(() => {
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || currentUrl; // default redirect to home if no returnUrl is available
        this.router.navigateByUrl(returnUrl);

        //this.router.navigate([currentUrl])
      })
    })
    this.store.subscribe(x => {
      if (x.auth?.userDetails?.authority == 'TENANT_ADMIN') {
        this.router.navigate(['_admin'])
      } else {
      }
    })

    this.showSmartBanner()
    this.setDefaultCustomerAttributes()
    this.setupSentry()

    // NOTE: updating language on login can course problems for login as customer using admin
    //this.store.select(state => state.auth?.userDetails?.id).subscribe(user => { if (user) this.appService.saveUser(user) })
  }

  showSmartBanner () {
    if (Capacitor.isNativePlatform()) return
    try {
      new SmartBanner({
        daysHidden: -1,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: -1, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
        title: 'AgroLog App',
        author: 'Supertech',
        button: 'INSTALL',
        store: {
            ios: 'On the App Store',
            android: 'In Google Play'
            //windows: 'In Windows store'
        },
        price: {
            ios: 'FREE',
            android: 'FREE'
            //windows: 'FREE'
        }
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
        //, force: 'ios' // Uncomment for platform emulation
      });
    } catch (err) {
      Sentry.captureMessage('could not show smart app banner', {
        extra: {error: err}, level: 'info'
      })
    }
  }

  

  setupSentry () {
    this.store.select(state => state.auth?.userDetails).subscribe(user => {
      if (!user) {
        Sentry.setUser(null)
      } else {
        let sentryUser = {
          email: user.email, id: user.id.id, username: user.firstName + ' ' + user.lastName
        }
        Sentry.setUser(sentryUser)
      }
    })
  }

  setDefaultCustomerAttributes () {
    
    this.store.select(state => state.auth?.userDetails?.id).subscribe(user => {
      if (!user) return
      const defaultValues = {'enable_email': true, 'locale': this.appService.locale}
      this.attributes.getEntityAttributes(
        user, AttributeScope.SERVER_SCOPE, Object.keys(defaultValues)
      ).subscribe(async (attrs) => {
        let updates: AttributeData[] = []
        for (let k in defaultValues) {
          let attrValue = attrs.find(x => x.key == k) || null
          if (attrValue == null) {
            updates.push({
              key: k, value: defaultValues[k]
            })
          }
        }
        if (updates.length > 0) {
          await this.attributes.saveEntityAttributes(
            user, AttributeScope.SERVER_SCOPE, updates
          ).toPromise()
        }
      })
    })
  }

  openMainMenu() {
    this.menu.enable(true, 'mainMenu');
    this.menu.open('mainMenu');
  }

  async showNotification(msg: IToastMessage) {
    let defaultDuration = 1000 * 5
    const toast = await this.toastController.create({
      duration: msg.duration || defaultDuration,
      cssClass: msg.type == 'info' ? "success" : "danger",
      message: msg.message,
      position: 'bottom'
    });
    toast.present();
  }

  async requestPermissions() {
    if (!isMobile(this.platform)) return

    const PERMISSIONS = [
      this.permissions.PERMISSION.CAMERA,
      this.permissions.PERMISSION.FINE_LOCATION,
      this.permissions.PERMISSION.COARSE_LOCATION,
    ]

    let reqs: string[] = []
    for (const perm of PERMISSIONS) {
      try {
        let result = await this.permissions.checkPermission(perm)
        if (!result.hasPermission) {
          reqs.push(perm)
        }
      } catch (err) {
        reqs.push(perm)
      }
    }
    if (reqs.length > 0) {
      await this.permissions.requestPermissions(reqs);
      await this.requestPermissions() // recheck permissions
    }
  }

  isAuthenticated = false
  async onAuthenticationChange(authenticated: boolean) {
    if (authenticated) {
      this.tryPostChannel('login')
    } else {
      this.tryPostChannel('logout');
      this.modalController.getTop().then(x => x?.dismiss())
      this.toastController.getTop().then(x => x?.dismiss())
    }

  }

  submittedMessages = {}
  authChannel = new BroadcastChannel('authChannel');
  _isBroadcastEnabled = true
  private tryPostChannel(msg: string) {
    if (!this._isBroadcastEnabled) return
    let message = { id: shortId(), message: msg }
    this.submittedMessages[message.id] = message
    try {
      this.authChannel.postMessage(message)
    } catch (err) {
      Sentry.captureMessage('failed to broadcast login', {extra: {error: err.message, message: msg}, level: 'info'})
    }
  }

  
  setBroadcastEnabled(isEnabled: boolean) {
    this._isBroadcastEnabled = isEnabled
  }

  initializeAuthChannel() {

    this.authChannel.addEventListener('message', (msg) => {
      if (this.submittedMessages[msg.id] == undefined)
        this.auth.reloadUser()
    });
  }

  ngAfterViewChecked() {
  }

  setupDeepLinks () {
    this.deepLinks.route({ '/': 'root' }).subscribe(match => {
      const path = match.$route // `/${match.$route}/${match.$args['slug']}`;
      // Run the navigation in the Angular zone
      this.ngZone.run(() => {
        this.router.navigateByUrl(path);
      });
    }, nomatch => {
      console.error("Deeplink: that didn't match", nomatch);
    });
  }

  async initializeApp() {
    await this.appService.initialize()
    if (Capacitor.isNativePlatform()) {
      this.setupDeepLinks()
      this.addDeeplinkListener();
    }
    //this.requestPermissions()
    this.initializeAuthChannel()
    this.store.subscribe((state) => {
      this.localStorage.setItem('auth', state.auth)
      this.localStorage.setItem('settings', state.settings)
      this.localStorage.setItem('host', state.host)
      this.localStorage.setItem('features', state.features)
    })

    this.auth.reloadUser()
    onAuthenticationChange(this.store).subscribe(x => this.onAuthenticationChange(x))

    this.toast.subscribe((msg) => {
      this.showNotification(msg)
    })

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.isNavigating = true
      }
      if (val instanceof NavigationEnd) {
        this.isNavigating = false
      }
    });

    this.platform.ready().then(() => {
      if (!this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });
  }

  addDeeplinkListener() {
    // See https://devdactic.com/setup-deep-links-capacitor
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.ngZone.run(() => {
        let url = new URL(event.url)
        let appPath = url.pathname + url.search
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      })
    });
  }
}




@Injectable()
export class CustomErrorsHandler implements ErrorHandler {
  constructor(
    private injector: Injector
  ) { }

  handleError(error: any) {
    if (error == null || error == undefined) {
      // TODO: not sure what to do here ?
      return
    }
    if (error.rejection) {
      error = error.rejection
    }
    if (error instanceof HttpErrorResponse) {
      console.error(error)
      return
    }

    const unsupportedError = /messaging\/unsupported-browser/;
    if (unsupportedError.test(error.message)) {
      Sentry.captureMessage('unsupported-browser', {
        extra: {error: error}, level: 'info'
      })
      return
    }

    Sentry.captureException(error)
    console.error(error);
    
    let dev = new URL(window.location.href).searchParams.get('dev')
    if (dev) {
      alert("Error occured: " + error.message);//or any message
      let elm = document.createElement('DIV')
      elm.innerText = error.name + ': ' + error.message
      let container = window.document.querySelector('#ERROR') as HTMLElement
      container.style.display = 'inherit'
      container.appendChild(elm) 
    }
  }
}



