import { ChangeDetectorRef, Component, Directive, ElementRef, Injectable, Input, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';

import moment from 'moment-es6';
import * as jQuery from 'jquery'
import 'flot'
import 'flot.curvedlines'

import { EntityId } from 'src/app/models/entity.model';
import { ITimeRange, TelemetryService, TimeseriesSubsciption } from 'src/app/services/telemetry';
import { Router } from '@angular/router';
import { Index, LoadingState, onElementResize } from 'src/app/util';
import { fromEvent, interval, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { TEXT } from 'src/app/texts';


export interface ITimeserie {
  name: string, label: string, color: string, unit: string
  yaxis?: number, disabled?: boolean, entityId?: EntityId<any>
  id?: string
}
export interface IPlotFigure {
  y: number, color: string, type: string, label: string
}
export interface IChartInfo {
  name: string, timeseries: ITimeserie[], axes: any[], figures: IPlotFigure[]
}
interface IPlotTooltipItem {
  unit: string, color: string, value: string, label: string, ts: number
}
interface IPosition {x: number, y: number}



@Component({
  selector: 'ag-plot-tooltip',
  template: `
<div>
  <div #container *ngIf="visible" class="tooltip"
           [style.top]="position.y + 'px'"
           [style.left]="position.x + 'px'">
    <div class="tooltip-content">
      <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="templateProps"></ng-template>
    </div>
    <div #tip class="tooltip-tip-container" [style.transform]="tipTransform()">
      <div class="tooltip-tip"></div>
    </div>
  </div>
</div>`,
  styles: [`

.tooltip-line-value {
  margin-left: 5px;
  filter: brightness(0.8);
  font-size: 13px;
}
.tooltip-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 10px;
}
.tooltip-line {
  padding: 1px;
  white-space: pre;
}
.tooltip-header {
  white-space: pre;
  margin: 5px 10px;
  font-size: 14px;
  text-align: center;
}
.tooltip-line-title {
  color: #636363;
  font-size: 14px;
  margin-left: 5px;
  width: 120px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
}
.tooltip-time-since {
  font-size: 12px; text-align: center;
}
.tooltip {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: absolute;
  width: 240px;
  transform: translate(-50%, -100%) translate(0px, -15px);
  z-index: 999;
  background: white;
  border-radius: 6px;
  color: #4d4d4d; 
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
}
.tooltip-content {
  margin: 13px 19px;
  line-height: 1.4;
}
.tooltip-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.tooltip-tip {
  background: white;
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  transform: rotate(45deg);
}
  `]
})
export class AgPlotTooltip {
  @Input() items: IPlotTooltipItem[]
  @Input() ts: number
  @Input() position: IPosition
  @Input() visible: boolean = false
  
  template: TemplateRef<any>
  templateProps = {}
  element

  offsetX = 0
  width = 240

  @ViewChild('container') container: ElementRef<HTMLElement>

  constructor (private service: AgTooltipService, private cdr: ChangeDetectorRef) {
    service.component = this
  }
  refresh () {
    this.cdr.detectChanges()
  }
  tipTransform () {
    let result = "translate(" + -1*this.offsetX + 'px)'
    return result
  }
  setTemplate (template: TemplateRef<any>, props) {
    this.template = template
    this.templateProps = props
  }
  setElement (elm: HTMLElement) {
    this.element = elm
    this.refreshPosition()
  }
  refreshPosition () {
    if (!this.element) return
    let elm = this.element
    let bound = elm.getBoundingClientRect()
    this.setPosition(
      bound.left + (bound.width * 0.5), bound.top
    )
    this.element = elm
  }
  setPosition (x: number, y: number) {
    let winWidth = window.screen.width
    let width = this.width + 10
    let right = x + (width * 0.5)
    let left = x - (width * 0.5)
    let newX = x
    if (right > winWidth) {
      newX -= (right - winWidth)
    } else if (left < 0) newX = (width * 0.5)
    this.position = {x: newX, y: y}
    this.offsetX = newX - x
  }

  ngOnInit () {
    // TODO: detroy
    window.addEventListener('mousedown', e => {
      let target = e.target as HTMLElement
      if (!this.container || !this.container.nativeElement) {
        return this.service.hide()
      }
      if (this.position && !this.container.nativeElement.contains(target))
        this.service.hide()
    }, {capture: true})
    window.addEventListener('mousewheel', e => {
      if (this.position && this.element) {
        setTimeout(() => this.refreshPosition(), 100)
      }
    }, {capture: true, passive: true})
  }
}


@Injectable()
export class AgTooltipService {
  component: AgPlotTooltip

  constructor (private router: Router) {
    this.init()
  }
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  private init () {
    this.listen(this.router.events.subscribe((val) => {
      this.hide()
    }));
  }
  show (template: TemplateRef<any>, opt: {position: IPosition, props: any}) {
    this.component.setTemplate(template, opt.props)
    this.component.setPosition(opt.position.x, opt.position.y)
    this.component.visible = true
    this.component.refresh()
  }
  open (elm: HTMLElement, template: TemplateRef<any>, props) {
    this.component.setElement(elm)
    this.component.setTemplate(template, props)
    this.component.visible = true
    this.component.refresh()
  }
  hide () {
    this.component.visible = false
    this.component.refresh()
  }
}


@Component({
    selector: 'ag-plot',
    template: `
  <ng-template #plotTooltip let-items="items" let-ts="ts" > 
    <div class="tooltip-header">
      <div>{{timeDisplay(ts)}}</div>
      <div class="tooltip-time-since"><ag-time-since [ts]="ts"></ag-time-since></div>
    </div>
    <div class="tooltip-line" *ngFor="let item of items">
      <span class="tooltip-icon" [style.background]="item.color"></span>
      <span class="tooltip-line-title">{{item.label|translate}}</span>: 
      <span class="tooltip-line-value" [style.color]="item.color">{{item.value}} {{item.unit}}</span>
    </div>
  </ng-template>
  <div style="display: flex; flex-grow: 1; position: relative;height:100%" style="min-height: {{height}}px">
    <ag-loading [loading]="loading" [inline]="true"></ag-loading>
    <div class="no-data-message" *ngIf="isEmpty && isLoaded">{{(emptyMessage || defaultEmptyMessage) | translate}}</div>
    <div *ngIf="!isEmpty && isLoaded" agFlot style="min-height: {{height}}px" style="display: flex; flex-grow: 1;" 
         [figures]="figures" [dataframe]="dataframe" [timerange]="timerange" [axis]="axis"  
         [grid]="grid" [tooltip]="plotTooltip" style="width: 100%;height:100%" ></div>
  </div>
`,
    styles: [`
  .no-data-message {
    display: inherit;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: bold;
    font-size: 12px;
    color: #a9a9a9;
    white-space: pre;
  }
`
]})
export class AgPlotComponent {
  @Input() entity: EntityId<any>
  @Input() timeseries: ITimeserie[]
  @Input() figures: IPlotFigure[] = []
  @Input('grid') grid: IFlotGridOptions
  @Input('legend') legend: IFlotLegendOptions
  @Input('axis') axis: IFlotAxisOptions[]
  @Input('height') height: number
  @Input() emptyMessage: string
  
  @ViewChild('plotTooltip') plotTooltip: TemplateRef<any>

  text = TEXT
  defaultEmptyMessage = TEXT.chart.no_data
  dataframe: Index<IFlotData> = {}
  subscriptions: TimeseriesSubsciption[] = []
  timerange: ITimeRange
  loading = new LoadingState()
  isEmpty = false
  isLoaded = false

  constructor (private telemetry: TelemetryService) {

  }

  timeDisplay (ts: number) {
    if (!ts) return "-"
    return moment(ts).format("MMMM Do YYYY, HH:mm")
  }

  unsubscribe () {
    this.subscriptions.map(s => s.unsubscribe())
  }

  _loadSubscription: Subscription
  _refreshInterval: number
  ngOnInit () {
    this.loading.success()
    this._loadSubscription = this.loading.events.subscribe(evt => {
      this.isLoaded = evt.is_success()
    })
    this._refreshInterval = window.setInterval(() => {
      this.refresh()
    }, 1000 * 60 * 10)
  }
  refresh () {
    this.updateTimerange()
    this.isEmpty = !this.hasData()
  }
  hasData () {
    for (var k in this.dataframe) {
      let frame = this.dataframe[k]
      let withinTimerange = frame.data.filter(x => x[0] >= this.timerange.min && x[0] <= this.timerange.max)
      if (withinTimerange.length > 1) return true
    }
    return false
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.timeseries || this.timeseries.length == 0) {
      this.isEmpty = true
      this.loading.success()
      return
    }
    this.timeseries.map(ts => {
      if (!ts.entityId) ts.entityId = this.entity
      ts.id = ts.label + ' ' + ts.name
    })
    this.timeseries.map(ts => {
      let data = this.dataframe[ts.id] ? this.dataframe[ts.id].data : []
      this.dataframe[ts.id] = {yaxis: ts.yaxis || 1, unit: ts.unit, disabled: ts.disabled, data: data, label: ts.label, color: ts.color}
    })
    
    if (changes.timeseries && !isEqual(changes.timeseries.currentValue, changes.timeseries.previousValue)) {
      this.unsubscribe()
      this.dataframe = {}
      let entityTimeseries: Index<{entityId: EntityId<any>, keys: string[], id: string}> = {}
      this.timeseries.map(ts => {
        let key = ts.entityId?.id
        if (!entityTimeseries[key]) {
          entityTimeseries[key] = {
            entityId: ts.entityId,
            keys: [],
            id: ts.id
          }
        }
        entityTimeseries[key].keys.push(ts.name)
      })
      this.subscriptions = Object.values(entityTimeseries).map(timeserie => {
        return this.telemetry.subscribe(timeserie.entityId, {keys: timeserie.keys}, {
          onData: (data) => {
            for (var key in data.data) {
              let ts = this.timeseries.find(
                ts => ts.entityId?.id == timeserie.entityId.id && ts.name == key
              )
              if (!ts) {
                delete this.dataframe[timeserie.id]
                continue
              }
              if (!this.dataframe[ts.id]) {
                this.dataframe[ts.id] = {
                  steps: true, unit: ts.unit,
                  yaxis: ts.yaxis || 1, disabled: ts.disabled, data: [], label: ts.label, color: ts.color}
              }
              
              this.dataframe[ts.id].data = data.data[key]
              
            }
            this.dataframe = Object.assign({}, this.dataframe)
            this.refresh()
            return true   
          },
          onLoading: (isLoading) => {
            if (isLoading) {
              this.isEmpty = false
              this.loading.loading()
            } else {
              this.loading.success()
            }
          }
        })
      })
      this.updateTimerange()
    }
  }
  updateTimerange () {
    this.subscriptions.map(s => {
      this.timerange = s.getTimerange()
    })
  }
  ngOnDestroy () {
    this.unsubscribe()
    if (this._loadSubscription) this._loadSubscription.unsubscribe()
    if (this._refreshInterval) window.clearInterval(this._refreshInterval)
  }
}


interface IFlotData {
  label: string, color: string, data: number[][], unit: string
  yaxis?: number, disabled?: boolean, steps?: boolean
}
interface IFlotGridOptions {}

interface IFlotLegendOptions {
  show?: boolean
}
export type POSITION = 'bottom' | 'top' | 'left' | 'right'
export interface IFlotAxisOptions {
  position: POSITION, show?: boolean, min?: number, max?: number
  tickFormatter?: any, showLabels?: boolean, alignTicksWithAxis?: number
}

const DEFAULT_OPTIONS = {
  grid: {
    outlineWidth: 0,
    margin: 0,
    minBorderMargin: 0,
    borderColor: 'black',
    hoverable: true, clickable: true
  },
  axis: {
    show: false, 
    font: {
      size: 11,
      lineHeight: 13,
      style: "italic",
      weight: "bold",
      family: "sans-serif",
      variant: "small-caps",
      color: "#545454"
    }
    //position: "bottom" or "top" or "left" or "right"
    //mode: 'time', null or "time" ("time" requires jquery.flot.time.js plugin)
    //timezone: "browser",  null, "browser" or timezone (only makes sense for mode: "time")
    //color: null or color spec
    //tickColor: null or color spec
    //font: null or font spec object
    //min: null or number
    //max: null or number
    //autoscaleMargin: null or number
    //transform: null or fn: number -> number
    //inverseTransform: null or fn: number -> number
    //ticks:  null or number or ticks array or (fn: axis -> ticks array)
    //tickSize: 0,// number or array
    //minTickSize: number or array
    //tickFormatter: (fn: number, object -> string) or string
    //tickDecimals: null or number
    //labelWidth: null or number
    //labelHeight: null or number
    //reserveSpace: null or true
    //tickLength: null or number
    //alignTicksWithAxis: null or number
  },
  legend: {
    show: false,// boolean
    labelFormatter: null, // null or (fn: string, series object -> string)
    labelBoxBorderColor: 'none',// color
    noColumns: 2, // number
    position: 'sw', // "ne" or "nw" or "se" or "sw"
    margin: 0, // number of pixels or [x margin, y margin]
    backgroundColor: 'white', // null or color
    backgroundOpacity: 0, // number between 0 and 1
    container: null, // null or jQuery object/DOM element/jQuery expression
    sorted: null // null/false, true, "ascending", "descending", "reverse", or a comparator
  },
  series: {
    curvedLines: {
      apply: false,
      active: true,
      tension: 0.5
      //monotonicFit: true
    },
    lines: { show: true },
			points: { show: false, steps: true }
  }
}


@Directive({
  selector: '[agFlot]'
})
export class FlotDirective {
  @Input('dataframe') dataframe: Index<IFlotData>
  @Input('grid') grid: IFlotGridOptions
  @Input('legend') legend: IFlotLegendOptions
  @Input('axis') axis: IFlotAxisOptions[]
  @Input('timerange') timerange: ITimeRange
  @Input('figures') figures: IPlotFigure[] = []
  @Input('tooltip') tooltip: TemplateRef<any>
  plot = null
  dataset: IFlotData[] = []
  $redraw = new Subject()

  constructor (
    private el: ElementRef, 
    private agtooltip: AgTooltipService
  ) {
  }
  
  makeXAxis (opt: IFlotAxisOptions) {
    let axis = Object.assign({}, DEFAULT_OPTIONS.axis, {
      timeBase: "milliseconds",
      timeformat: "%d %b %H:%M",
      timezone: 'browser', mode: 'time'
    }, opt)
    if (this.timerange) {
      return Object.assign(axis, {
        min: this.timerange.min, max: this.timerange.max
      })
    }
    return axis
  }
  makeYAxis (opt: IFlotAxisOptions) {
    return Object.assign({}, DEFAULT_OPTIONS.axis, {
      autoScaleMargin: 0.0,
      tickFormatter: (val, obj) => val.toFixed() + '°'
    }, opt)
  }

  makeOptions (dataset: IFlotData[]) {
    let yvalues: number[] = [] 
    dataset.map(d => d.data.map(v => yvalues.push(parseFloat(v[1] as any))))
    let maxY = yvalues.length ? Math.max(...yvalues) + 10 : 10
    let minY = yvalues.length ? Math.min(...yvalues) - 10 : 0
    let xaxes: IFlotAxisOptions[] = []
    let yaxes: IFlotAxisOptions[] = []
    this.axis.map(ax => {
      if (ax.position == 'bottom' || ax.position == 'top') {
        xaxes.push(this.makeXAxis(ax))
      } else { 
        ax = Object.assign({}, ax, {min: minY, max: maxY})
        yaxes.push(this.makeYAxis(ax))
      }
    })
    return Object.assign({}, {
      grid: Object.assign({}, DEFAULT_OPTIONS.grid, this.grid),
      legend: Object.assign({}, DEFAULT_OPTIONS.legend, this.legend),
      xaxis: xaxes[0], 
      yaxes: yaxes, 
      series: DEFAULT_OPTIONS.series
    }) as any
  }

  showTooltip(x, y, content) {
    let tooltip = jQuery('<div class="graph-tooltip">' + content + '</div>')
    tooltip.css({
      position: 'absolute',
      display: 'none',
      top: y + 140,
      left: x + 5
    }).appendTo("body").fadeIn(200);
    return tooltip
  }
  
  pointAtScreenPos (x: number, y: number) {
    let xAxes = this.plot.getXAxes()[0]
    let yAxes = this.plot.getYAxes()[0]
    let xOffset = this.plot.offset().left
    let yOffset = this.plot.offset().top
    let gridX = x - xOffset 
    let px = parseInt(xAxes.c2p(gridX))
    let result = {items: [], ts: px}
    this.plot.getData().map((serie, index) => {
      if (serie.isFigure) return
      let pid = serie.data.findIndex(v => {
        return v[0] >= px
      })
      if (pid != -1) {
        let value = serie.data[pid]
        let screen = {
          x: xAxes.p2c(parseFloat(value[0])) + xOffset,
          y: yAxes.p2c(value[1]) + yOffset
        }
        result.items.push({serie: serie, serieIndex: index, pointIndex: pid,

          value: value[1], time: value[0], datapoint: value,
          screen_x: screen.x,
          screen_y: screen.y
        })
      }
    })
    return result
  }

  updateTooltip (clientX: number, clientY: number) { 
    let result = this.pointAtScreenPos(clientX, clientY)
    let time = moment(result.ts).format("MMMM Do YYYY, HH:mm")
    let content = `<div class="tooltip-header">${time}</div>`
    this.plot.unhighlight()
    let x; let y;
    

    let resultItems: IPlotTooltipItem[] = result.items.map(item => {
      if (!x || item.screen_x < x) x = item.screen_x
      if (!y || item.screen_y < y) y = item.screen_y
      this.plot.highlight(item.serieIndex, item.pointIndex)
      let tpl = `
<div class="tooltip-line">
<span class="tooltip-icon" style="background: ${item.serie.color}"></span>
<span class="tooltip-line-title">${item.serie.label}</span>: 
<span class="tooltip-line-value">${parseFloat(item.value).toFixed(2)} ${item.serie.unit}</span>
</div>`
      content += tpl
      let tooltipItem: IPlotTooltipItem = {
        color: item.serie.color, unit: item.serie.unit, value: parseFloat(item.value).toFixed(2),
        label: item.serie.label, ts: item.time
      }
      return tooltipItem
    })
    if (x && y && resultItems.length) {
      let ts = 0
      resultItems.map(item => ts += item.ts)
      ts = ts / resultItems.length
      this.agtooltip.show(this.tooltip, {position: {x: x, y: y}, props: {items: resultItems, ts: ts}})
    }
  }
  redraw () {
    let dataframe = this.dataframe
    let dataset = []
    
    let graph_dataset = []

    if (this.timerange) {
      this.figures.map(fig => {
        dataset.push({
          data: [[this.timerange.min, fig.y], [this.timerange.max, fig.y]],
          lines: { show: true }, points: {show: false}, 
          color: fig.color, label: fig.label, isFigure: true
        })
      })
    }
    
    for (var k in dataframe) {
      if (dataframe[k].disabled) continue
      let item = Object.assign({}, dataframe[k], {
        lines: {
          fillColor: dataframe[k].color
        },
        highlightColor: dataframe[k].color,
        points: {fillColor: dataframe[k].color}})
      dataset.push(item)
      graph_dataset.push(item)
    }
    this.dataset = graph_dataset
    let options = this.makeOptions(graph_dataset)
    let first = this.plot == undefined
    if (this.plot) {
      this.plot.destroy()
    }
    this.plot = jQuery.plot(this.el.nativeElement, dataset, options)
    this.plot.resize()
    this.plot.setupGrid()
    this.plot.draw()
  }

  ngAfterViewInit () {
    let el = this.el.nativeElement
    this.listen(
      fromEvent(el, 'touchmove', {passive: true}).subscribe(evt => {
        let event = evt as TouchEvent
        let touch = event.touches[0]
        this.updateTooltip(touch.clientX, touch.clientY)
      })
    )
    this.listen(
      fromEvent(el, 'touchstart', {passive: true}).subscribe(evt => {
        let event = evt as TouchEvent
        this.plot?.unhighlight()
      })
    )
    this.listen(
      fromEvent(el, 'touchend', {passive: true}).subscribe(evt => {
        this.plot?.unhighlight()
        this.agtooltip?.hide()
      })
    )
    this.listen(
      fromEvent(el, 'mousemove').subscribe(evt => {
        let event = evt as MouseEvent
        if (event.buttons == 1)
          this.updateTooltip(event.clientX, event.clientY)
      })
    )
    this.listen(
      fromEvent(el, 'mouseup').subscribe(evt => {
        this.plot?.unhighlight()
        this.agtooltip?.hide()
      })
    )
  }

  ngOnInit () {
    
    // TODO: find a better method for redrawing plot
    //  maybe use https://marcj.github.io/css-element-queries/ watch for resize
    this.redraw()
    this.listen(onElementResize(this.el.nativeElement).pipe(
      debounceTime(100)
    ).subscribe(x => {
      this.$redraw.next(1)
    }))
    this.listen(this.$redraw.pipe(debounceTime(100)).subscribe(x => this.redraw()))
  }
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  // TODO: redraw scheduler
  ngOnChanges(changes: SimpleChanges): void {
    this.$redraw.next(1)
  }
}

/*
 %h: hours
  %H: hours (left-padded with a zero)
  %M: minutes (left-padded with a zero)
  %S: seconds (left-padded with a zero)
  %d: day of month (1-31), use %0d for zero-padding
  %m: month (1-12), use %0m for zero-padding
  %y: year (2 digits)
  %Y: year (4 digits)
  %b: month name (customizable)
  %p: am/pm, additionally switches %h/%H to 12 hour instead of 24
  %P: AM/PM (uppercase version of %p)
*/