
import { Component, Directive, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { agTypes } from '../../ag-types'
import { AgModal, isMoisture, isSpear, LoadingState } from '../../util';
import { Asset, Device, IAlarm } from '../../models/entity.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FarmerService } from 'src/app/services/device.service';
import { EntityService } from 'src/app/services/entity.service';
import { MenuService, PermissionService } from 'src/app/menus';
import { AlarmService } from 'src/app/services/alarm.service';
import { AddDeviceDialogComponent } from './farmer-create-device';
import { IDevicePaymentState, PaymentService } from 'src/app/services/payment.service';
import { Subscription } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { TEXT } from 'src/app/texts';
import { TranslateService } from '@ngx-translate/core';



@Component({
  template: `
<div *ngIf="!device">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title></ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content style="display: contents;">
    <ag-loading [loading]="loading" message="{{text.device.loading_device | translate}}" (retry)="reload()"></ag-loading>
  </ion-content>
</div>
<div *ngIf="isLoaded" style="height: 100%">
  <spear-page *ngIf="isCellularSpear" [device]="device"></spear-page>
  <superpro-overview *ngIf="!isCellularSpear" [device]="device"></superpro-overview>
</div>
  `,
  styleUrls: ['farmer-devices.scss']
})
export class FarmerDeviceDetailComponent {
  loading = new LoadingState()
  entityId: string
  device: Device
  isCellularSpear
  isLoaded = false
  text = TEXT

  constructor (
    private title: Title, 
    private entityService: EntityService,
    private translate: TranslateService,
    private route: ActivatedRoute) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  ngOnInit () {
    this.listen(this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    }));
    this.loading.events.subscribe(x => {
      this.isLoaded = this.loading.is_success()
    })
    this.reload()
  }
  async reload () {
    try {
      this.device = await this.entityService.getDeviceById(this.entityId)
      this.title.setTitle(this.device.label)
      this.isCellularSpear = this.isSpear(this.device.type)
      this.loading.success()
    } catch (err) {
      this.loading.error(this.translate.instant(TEXT.device.device_load_error))
      throw err
    }
  }

  isSpear (deviceType: string) {
    return deviceType == agTypes.farmerDeviceTypes.cellularSensorSpear.value
  }
}

@Directive({
  selector: "[click-stop-propagation]"
})
export class ClickStopPropagation
{
  @HostListener("click", ["$event"])
  public onClick(event: any): void
  {
      event.stopPropagation();
  }
}

@Component({
  selector: 'payment-card-overlay',
  template: `
<div #overlay class="payment-overlay">

  <!--<div *ngIf="paymentState?.trial" class="payment-badge">TRIAL</div>-->
  
  <div class="blocked" [class.blank]="paymentState == null" *ngIf="paymentState == null || !paymentState?.active || paymentState?.isActivating" >
    <div style="position: absolute; top: 50%; left: 45%; width: 100%; padding: 20px 20px;">
      <div style="pointer-events: fill !important; transform: translate(-50%, -50%); font-weight: bold; width: 100%; width: 80%; text-align: center;">
        <div>{{paymentState?.message}}</div>
        <ion-spinner *ngIf="paymentState == null || paymentState?.isActivating" class="loader-spinner" name="dots"></ion-spinner>
        <div *ngIf="paymentState?.canCheckout" style="margin-top: 10px; display: flex; justify-content: center;">
          <button class='checkout-button' (click)="$event.stopPropagation(); checkout()" >
            {{text.general.checkout | translate}}
          </button>
        </div>
      </div> 
    </div>
  </div>

  <div class="overlay" (click)="$event.stopPropagation(); checkout()" *ngIf="paymentState?.active && !paymentState?.isActivating && paymentState?.message" >
    <span class='inline-message'>{{paymentState?.message}}</span>
    <span *ngIf="paymentState?.canCheckout && paymentState?.active"> - {{text.general.checkout | translate}}</span>
  </div>
</div>
  `,
  styles: [`

.checkout-button {
  background: rgba(237, 109, 5, 0.8);
  padding: 7px 10px;
  color: #FEFEFE;
  border-radius: 10px;
  font-size: 16px;
}
.payment-badge {
  background: rgba(237, 109, 5, 0.8);
  color: #FEFEFE;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 1px;
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 14px;
}
.inline-message {

}
.inline-checkout-button {
  font-size: 16px;
  padding: 5px 10px;
  background: none;
  background: rgba(237, 109, 5, 0.8);
  border-radius: 10px;
  color: #FEFEFE;
  margin-left: 10px;
}
.payment-overlay {
  z-index: 500; display: flex;
  width: 100%; height: 100%;
  position: relative;
}
.blank {
  background: white;
}
.overlay {
  position: absolute;
  top: 200px;
  right: 10px;
  z-index: 600;
  pointer-events: fill;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.15px;
  background: rgba(237, 109, 5, 0.8);
  border-radius: 10px;
  color: #FEFEFE;
  padding: 0 10px;
}
.blocked {
  
  z-index: 500;
  font-family: Roboto; 
  display: block;  width: 100%; height: 100%;
  flex-direction: column; 
  flex-grow: 1 !important; 
  position: relative;
  border-radius: 8px;
}
  `]
})
export class PaymentCardOverlay {
  paymentState: IDevicePaymentState = null
  message: string
  text = TEXT

  @Input() entity: Asset | Device
  @ViewChild('overlay') overlay: ElementRef<HTMLDivElement>

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    this._subscriptions.map(s => s.unsubscribe()); 
    this._subscriptions = [] 
  }
  constructor (
    private paymentService: PaymentService,
    private farmer: FarmerService
  ) {}

  ngOnInit () {
    this.listen(
      this.paymentService.subscribe(() => this.onPaymentStateUpdate() )
    )
    this.listen(
      this.farmer.onRefresh.subscribe(x => this.onPaymentStateUpdate())
    )
  }

  checkout () {
    if (this.paymentState?.canCheckout)
      this.paymentService.openPaymentDialog()
  }
  usePayment () { return this.paymentService.isEnabled() }

  onPaymentStateUpdate () {
    let state = this.paymentService.getEntityState(this.entity)
    this.paymentState = state
  }
}

@Component({
  selector: 'entity-grid',
  template: `
<div class="device-grid">
  <div style="position: relative" class="device-item device-dashboard-widget ag-card flex-auto"  
      *ngFor="let item of items; trackBy:itemKey" [class]="{alarmActive: item.hasAlarm}">
    <div class='card-click-aera' (click)="selectCard(item.entity)"></div>
    <payment-card-overlay style="pointer-events: none; position: absolute; width: 100%; height: 100%; z-index: 500" [entity]="item.entity"></payment-card-overlay>
    <farmer-building-item [hasAlarm]="item.hasAlarm" class="dashboard-card-content" *ngIf="item.type == 'building'" [id]="'card-'+item.entity.name" [asset]="item.entity"></farmer-building-item >
    <farmer-building-item class="dashboard-card-content" *ngIf="item.type == 'silo'" [id]="'card-'+item.entity.name" [asset]="item.entity"></farmer-building-item >
    <farmer-cellular-spear-item [hasAlarm]="item.hasAlarm" class="dashboard-card-content" *ngIf="item.type == 'cellular_sensor_spear'" [device]="item.entity" [id]="'card-'+item.entity.name"></farmer-cellular-spear-item>
    <farmer-measurement-item class="dashboard-card-content" *ngIf="item.type == 'measurement'" [device]="item.entity" [id]="'card-'+item.entity.name"></farmer-measurement-item>
  </div>
</div>
  `,
  styleUrls: ['farmer-devices.scss', 'farmer-device-item.scss']
})
export class EntityGrid {
  @Input() entities: (Device | Asset)[]
  items: IGridItem[] = []

  constructor (
    private router: Router, private alarmService: AlarmService
  ) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  alarms: IAlarm[] = []

  itemKey (index: number, item: IGridItem) {
    return item.entity.id.id
  }

  ngOnInit () {
    this.listen(
      this.alarmService.subscribe(alarms => {
        this.alarms = alarms
        this.refresh()
      })
    )
    this.refresh()
  }
  ngOnChanges () {
    this.refresh()
  }
  refresh () {
    this.items = this.entities.map(x => {
      let type = x.type
      let hasAlarm = this.alarms.find(a => a.originator.id == x.id.id) != undefined
      if (this.isMessurementDevice(x as Device)) {
        type = 'measurement'
      }
      return {entity: x, type: type, hasAlarm: hasAlarm}
    })
  }
  
  isMessurementDevice (device: Device) {
    return isMoisture(device.type)
  }
  selectBuilding (asset: Asset) {
    this.router.navigateByUrl('/building/' + asset.id.id)
  }
  selectSilo (asset: Asset) {
    this.router.navigateByUrl('/silo/' + asset.id.id)
  }

  selectDevice (device: Device) {
    this.router.navigateByUrl('/device/' + device.id.id)
  }
  selectCard (entity: Asset | Device) {
    //if (!this.payment.canAccessEntity(entity)) { return }
    if (entity.id.entityType == 'DEVICE') {
      this.selectDevice(entity as Device)
    } else if (entity.type == agTypes.farmerDeviceTypes.building.value) {
      this.selectBuilding(entity as Asset)
    } else if (entity.type == agTypes.farmerDeviceTypes.silo.value) {
      this.selectSilo(entity as Asset)
    }
  }
}
interface IGridItem {
  entity: Device | Asset, type: string, hasAlarm: boolean
}

@Component({
  selector: 'farmer-devices',
  template: `
<ion-header translucent="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="menu.openMainMenu()">
        <ion-icon slot="icon-only" name="reorder-three-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button (click)="menu.toggleDevicesMap()">
        <ion-icon slot="icon-only" *ngIf="menu.showMap" name="grid-outline"></ion-icon>
        <ion-icon slot="icon-only" *ngIf="!menu.showMap" name="map-sharp"></ion-icon>
      </ion-button>
      <ion-button (click)="menu.openAlarmPage()">
        <!--style="color: #ED6D05;"-->
        <ion-icon slot="icon-only" *ngIf="alarms" style="color: #ED6D05;" src="/assets/svg/notifications_icon.svg" ></ion-icon>
        <ion-icon slot="icon-only" *ngIf="!alarms" src="/assets/svg/notifications_icon.svg" ></ion-icon>
      </ion-button>
      <ion-button (click)="menu.openTimeSettingsMenu($event)">
        <ion-icon slot="icon-only" name="time-outline" ></ion-icon>
      </ion-button>
      <ion-button (click)="menu.openProfileMenu()">
        <ion-icon slot="icon-only" name="person-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-menu [disabled]="loadingMenu" type="overlay" side="start" menuId="timeSettings" contentId="menu-content">
  <time-query-settings style="height: 100%; width:"></time-query-settings>
</ion-menu>
<ion-menu [disabled]="loadingMenu" type="overlay" side="start" menuId="profileMenu" contentId="menu-content">
  <profile-menu style="height: 100%;"></profile-menu>
</ion-menu>
<ion-menu [disabled]="loadingMenu" type="overlay" side="start" menuId="mainMenu" contentId="menu-content">
  <main-menu ></main-menu>
</ion-menu>
<div id="menu-content"></div>

<ion-content  id="device-content" >
  <ag-loading [loading]="pageLoading" message="{{text.general.loading | translate}}" (retry)="reload()"></ag-loading>
  <div *ngIf="!hasPermission" class="ag-card" style="padding: 15px; margin: 20px;">
    <permission-error type="NOTIFICATION" message="{{text.permission.notifications_denied | translate}}" (onRetry)="fcm.checkPermissions()"></permission-error>
  </div>
  <entity-grid *ngIf="isLoaded" [entities]="entities"></entity-grid>
  <ion-fab *ngIf="!menu.showMap && isLoaded && hasContent" vertical="bottom" horizontal="end" slot="fixed" (click)="openSelectDeviceDialog()">
    <ion-fab-button color="primary">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab *ngIf="!menu.showMap && isLoaded && !hasContent" vertical="center" horizontal="center" slot="fixed" (click)="openSelectDeviceDialog()">
    <ion-fab-button color="primary">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-button color="primary" style="transform: translate(-25%, 0); text-transform: uppercase;">
      {{text.general.add_device | translate}}
    </ion-button>
  </ion-fab>
</ion-content>


  `,
  styleUrls: ['farmer-devices.scss']
})
export class FarmerDevicesComponent {
  constructor (
    public menu: MenuService,
    private title: Title,
    public modalController: ModalController,
    private farmer: FarmerService,
    private alarmService: AlarmService,
    private agModal: AgModal,
    private location: LocationService,
    public fcm: MessagingService,
    public permissions: PermissionService
  ) {}

  text = TEXT
  agTypes = agTypes;
  devices: Device[] = [];
  assets: Asset[] = []
  entities: (Asset | Device)[] = []
  currentDevice: Device = null;
  alarms: boolean = false
  //pageLoading = new LoadingState()
  buildingDevices: Device[] = []
  i = 0
  hasContent = true
  isLoaded = false

  get pageLoading () { return this.farmer.loading }

  ionViewWillEnter(){
    this.title.setTitle('Devices')
  }
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  hasPermission = true
  ngOnInit () {
    this.listen(this.alarmService.onUpdate.subscribe(alarms => {
      this.alarms = this.alarmService.hasAlarms()
    }))
    this.alarms = this.alarmService.hasAlarms()
    this.listen(this.farmer.subscribe(() => {
      this.loadDevices(true)
    }))
    this.listen(this.pageLoading.events.subscribe(x => {
      this.isLoaded = this.pageLoading.is_success()
    }))
    this.listen(
      this.permissions.state.subscribe(x => {
        this.hasPermission = x['NOTIFICATION']
      })
    )
    this.fcm.checkPermissions()
    //this.permissions.checkPermission('NOTIFICATION')
    // NOTE: do not reload here or it will make the app crash for ignito users for some reason
    //this.reload()
    
  }

  // trying to deal with this problem: 
  //   https://forum.ionicframework.com/t/ion-menu-issue/215534/2
  //   https://github.com/ionic-team/ionic-framework/issues/24907
  loadingMenu = true
  ngAfterViewInit () {
    setTimeout(() => { this.loadingMenu = false; }, 1000)
  }

  reload () { 
    this.farmer.reload()
  }
  
  loadDevices (keepSelection=false) {
    this.devices = [];
    this.assets = []
    if (!keepSelection) {
        this.currentDevice = null;
    }
    
    this.assets = this.farmer.assetEntities
    this.devices = this.filterFarmerDevices(this.farmer.listFreeDevices())
    this.entities = [].concat(this.assets).concat(this.devices)
    this.hasContent = this.entities.length > 0
    this.pageLoading.success()
  }

  listSilos () {
    return this.assets.filter(asset => asset.type == 'silo')
  }
  listBuildings () {
    return this.assets.filter(asset => asset.type == 'building')
  }
  listMeasurementDevices () {
    return this.devices.filter(device => isMoisture(device.type))
  }
  listCellularSpearDevices () {
    return this.devices.filter(device => isSpear(device.type))
  }

  filterFarmerDevices (devices: Device[]) {
      return devices.filter((device) => {
        return true 
      }).sort((a, b) => a.label.localeCompare(b.label));
  }

  

  isCustomer() {
    return true // this.userService.getAuthority() === 'CUSTOMER_USER'
  }

  async openSelectDeviceDialog () {
    const dialogRef = await this.modalController.create({
      component: AddDeviceDialogComponent});
    const { data } = await this.agModal.openModal(dialogRef)
  }
}

