import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { isEqual } from "lodash-es"; 
import { Subject, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ALARM_TYPES, IAlarmType } from "../constant";
import { Asset, Device, Entity, EntityId, IAlarm, Index } from "../models/entity.model";
import { AppState } from "../state";
import { onAuthenticationChange } from "../util";
import { EntityService } from "./entity.service";
import { TelemetryService, TelemetrySubscriber } from "./telemetry";
import { FarmerService } from "./device.service";


/*export interface IAlarmItem {
  icon: string, color: string, unit: string
  name: string, alarm: IAlarm, value: number, type: string
  ts: number
}*/
export interface IAlarmItem {
  type: IAlarmType, alarm: IAlarm, ts: number, value?: number
  details: {sensorId?: string, sensorKey?: string}
}

export const ALARM_KEYS = ALARM_TYPES.map(x => x.key)



@Injectable()
export class AlarmService {
  onUpdate = new EventEmitter<IAlarm[]>()
  alarms: Index<IAlarm> = {}
  entities: Index<Entity<any>> = {}
  subscribers: Index<TelemetrySubscriber> = {}
  excludedTypes = ['super_combi', 'c_pro', 'super_pro', 'farm_pro', 'super_point', 'farm_point']
  
  constructor ( 
    private farmer: FarmerService,
    private telemetry: TelemetryService,
    private entityService: EntityService,
    private http: HttpClient,
    private ngZone: NgZone,
    private store: Store<AppState>
  ) {
    this.schedule.pipe(debounceTime(1000)).subscribe(x => {
      this.ngZone.run(() => this.onUpdate.emit(Object.values(this.alarms)))
    })
    onAuthenticationChange(this.store).subscribe(x => {
      this.clear()
      if (x)
        this.fetchCustomerAlarms()
    })
    this.farmer.subscribe(() => {
      this.fetchCustomerAlarms()
    })

    // TODO: remove this if you find a way to get stable alarm subscriptions
    fromEvent(document, 'visibilitychange').subscribe(x => {
      console.log('visibility change', x)
      if (document.visibilityState == 'visible') {
        this.fetchCustomerAlarms()
      }
    })
  }

  schedule = new Subject()

  subscribe (cb: (alarms: IAlarm[])=>void) {
    cb(Object.values(this.alarms))
    return this.onUpdate.subscribe(cb)
  }

  hasAlarms () {
    return Object.keys(this.alarms).length > 0
  }
  
  getEntity (id: string) {
    return this.entities[id]
  }

  clearEntity (id: string) {
    let alarms: Index<IAlarm> = {}
    for (var k in this.alarms) {
      let alarm = this.alarms[k]
      if (!alarm) continue
      if (alarm.originator.id != id) {
        alarms[k] = alarm
      }
    }
    this.alarms = alarms
    this.emit() //this.onUpdate.emit(Object.values(this.alarms))
  }

  //alarm_interval
  clear () {
    Object.values(this.subscribers).map(s => {
      this.telemetry.socket.unsubscribe(s)
    })
    this.subscribers = {}
    this.alarms = {}
    this.entities = {}
    //if (this.alarm_interval) window.clearInterval(this.alarm_interval)
    this.emit()
  }

  isInvalidAlarm (alarm: IAlarm) {
    if (!this.entities[alarm.originator.id]) return true
    return alarm.status == 'CLEARED_ACK' || alarm.status == 'CLEARED_UNACK' || !ALARM_KEYS.includes(alarm.type)
  }

  listAlarms () {
    return Object.values(this.alarms)
  }

  emit () {
    this.schedule.next(1)
  }


  addAlarms (alarms: IAlarm[]) {
    if (!alarms || alarms.length == 0) return
    alarms.map(alarm => {
      let key = alarm.id.id
      if (this.isInvalidAlarm(alarm)) {
        delete this.alarms[key]
      } else if (!isEqual(this.alarms[key], alarm)) {
        this.alarms[key] = alarm
      }
    })
    this.emit()
  }

  async fetchDevices () {
    /*let assets = await this.entityService.getCustomerAssets()
    let devices = (await this.entityService.getDevicesByType()).data
    let entities: (Device|Asset)[] = []
    entities = entities.concat(assets).concat(devices)*/

    let entities = this.farmer.listEntities()
    entities.filter(d => !this.excludedTypes.includes(d.type)).map(d => {
      this.addEntity(d)
    })
  }

  last_fetch_ts: number = 0
  async fetchCustomerAlarms () {
    this.clear()
    await this.fetchDevices()
    
    let next_ts = new Date().getTime() - 1000
    let alarms = await this.entityService.fetchCustomerAlarms(0)
    this.addAlarms(alarms)  
    
    // TODO: get all alarms to propagate to CUSTOMER, right now need to fetch ASSET alarms manually
    for (var key in this.entities) {
      let entity = this.entities[key]
      if (entity?.id?.entityType == 'ASSET') {
        let assetAlarms = await this.entityService.fetchEntityAlarms(entity.id, 0)
        this.addAlarms(assetAlarms)
      }
    }

    this.last_fetch_ts = next_ts
  }
  async fetchEntityAlarms (entityId: EntityId<any>) {
    let alarms = await this.entityService.fetchEntityAlarms(entityId, 0)
    this.clearEntity(entityId.id)
    this.addAlarms(alarms)
    return alarms
  }
  
  addEntity (device: Entity<any>) {
    let key = device.id.id
    this.entities[key] = device
    // NOTE: subscribing to alarms is unstable, will make other assets not receive latest telemetry
    //this.subscribeAlarms(device)
  }
  
  private subscribeAlarms (device: Entity<any>) {
    let key = device.id.id
    if (this.subscribers[key]) return
    
    this.subscribers[key] = this.telemetry.subscribeAlarms(device.id, ALARM_KEYS, (resp) => {
      // NOTE: alarms will sometimes be null regardsless of no-alarms or has-alarms
      //    have to refetch alarms for entity if null
      
      const data = resp.data
      if (data) {
        this.clearEntity(device.id.id)
        this.addAlarms(data.data)
      } 
      const update = resp.update
      if (update) {
        this.addAlarms(update)
      }
      return true
    })
  }
  async clearAlarms (alarms: IAlarm[]) {
    await Promise.all(alarms.map(alarm => {
      return this.http.post('/api/alarm/' + alarm.id.id + '/clear', {}).toPromise()
    }))
    this.last_fetch_ts = 0
    alarms.map(alarm => {
      alarm.status = "CLEARED_ACK"
    })
    this.addAlarms(alarms)
    await this.fetchCustomerAlarms()
  }
  
}
