import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { agTypes } from "src/app/ag-types";
import { Asset, Device, Entity } from "src/app/models/entity.model";
import { AuthService } from "src/app/services/auth.service";
import { FarmerService } from "src/app/services/device.service";
import { EntityService } from "src/app/services/entity.service";
import { PaymentService } from "src/app/services/payment.service";
import { ISensorType, SENSOR_TYPE } from "src/app/services/types.service";
import { AGUserService } from "src/app/services/user.services";
import { TEXT } from "src/app/texts";
import { deviceIcon, LoadingState } from "src/app/util";
import { v4 as uuidv4 } from 'uuid';
import { POSITION } from "../ag-plot/ag-plot";
import { UNIT_TYPE } from "../base-components";
import { FarmerSilo } from "../farmer-silo/farmer-silo.model";
import { FarmerBuilding, IBuildingMarker } from "./farmer-building.model";


@Component({
  selector: 'building-list',
  template: `
<ion-header translucent="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title [translate]="text.general.building"></ion-title>
    <ion-buttons slot="primary">
      
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content id="building-page-content" >
  <ag-loading [loading]="loading" message="{{text.general.loading | translate}}" (retry)="reloadBuildings()"></ag-loading>
  <div class="building-grid">
    <farmer-building-item 
      *ngFor="let building of buildings" class="building-item"  [asset]="building" 
      (onClick)="selectBuilding(building)"></farmer-building-item>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="openCreateBuildingDialog()">
    <ion-fab-button color="primary">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
  `,
  styleUrls: ['farmer-building.scss']
})
export class BuildingListPage {

  constructor (
    private farmer: FarmerService,
    private userService: AGUserService,
    private alertController: AlertController,
    private http: HttpClient,
    private router: Router,
    private entityService: EntityService,
    private authService: AuthService
  ) {}

  text = TEXT
  loading = new LoadingState()
  buildings: Asset[] = []

  async reloadBuildings () {
    this.loading.loading(true)
    try {
      this.buildings = await this.entityService.getCustomerAssets()
      this.loading.success()
    } catch (err) {
      this.loading.error('failed to load buildings')
      throw err
    }
  }

  ngOnInit () {
    this.reloadBuildings()
  }

  selectBuilding (asset: Asset) {
    this.router.navigateByUrl('building/' + asset.id.id)
  }

  async createBuilding (name: string) {
    await this.authService.getAuth().pipe(
      switchMap(auth => {
        let url = '/api/asset'
        let tenantId = auth.authUser
        return this.http.post(url, {
          additionalInfo: '',
          customerId: {
            id: auth.authUser.customerId
          },
          createdTime: 0,
          id: {
            id: uuidv4()
          },
          label: name,
          name: name,
          tenantId: {
            id: auth.authUser.tenantId
          },
          type: "building"
        })
      })
    ).toPromise()
  }

  async openCreateBuildingDialog () {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: TEXT.building.create_building,
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: TEXT.building.building_name
        }
      ],
      buttons: [
        {
          text: TEXT.general.cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: TEXT.general.ok,
          handler: (data) => {
            if (data.name)
              this.createBuilding(data.name)
          }
        }
      ]
    });

    await alert.present();
  }
}


@Component({
  selector: 'farmer-building-item',
  template: `
<div class="card-container">
  <div fxFlex fxLayout="column" class="card-content flex-auto" > 
    <div class="device-section flex-auto" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="device-label">
        {{asset.label}}
      </div>
      <div *ngIf="hasAlarm" [style.color]="colors.maxColor" class='alarm-label' style="text-transform: uppercase;" [translate]="text.general.alarm"></div>
      <ion-icon [style.color]="hasAlarm ? colors.maxColor : ''" class="ag-device-icon" [src]="icon"></ion-icon>
      <!--<img class="ag-device-icon" [src]="icon" />-->
    </div>

    <div *ngIf="isPaid == true" class="device-section" class="ag-values-row flex-auto" fxFlex fxLayout="row"> 
      <div class="ag-value-row" fxLayout="row" fxLayoutAlign="start center" > 
        <ag-sensor-value [prefix]="text.general.min" [cropType]="cropType" [type]="type" [value]="minValue"></ag-sensor-value>
      </div>
      <div class="ag-value-row" fxLayout="row" fxLayoutAlign="start center">
        <ag-sensor-value [prefix]="text.general.max" [cropType]="cropType" [type]="type" [value]="maxValue"></ag-sensor-value>
      </div>
    </div>
    <div *ngIf="isPaid == true" style="height: 100px; pointer-events: none;" class="ag-flot-row flex-auto" fxFlex fxLayout="column"> 
      <ag-plot fxFlex fxLayout="column" [grid]="flotSettings.grid" [axis]="flotSettings.axes" [entity]="asset.id" [timeseries]="timeseries"></ag-plot>
    </div>
  </div>
  <div *ngIf="isPaid == true" class="device-section" fxLayout="row" >
      <div fxLayout="row" *ngIf="sensorTypes.length > 1">
        <div *ngFor="let sensor of sensorTypes; trackBy: sensorTypeKey" 
            [class]="{active: isActiveSensor(sensor)}" 
            (click)="$event.stopPropagation(); setActiveSensor(sensor)" class="sensor-button" [translate]="sensor.name"></div>
      </div>
      <div class="time-value"><ag-time-since [ts]="lastTs"></ag-time-since></div>
    </div>
</div>

  `,
  styleUrls: ['farmer-building.scss', '../farmer-devices/farmer-device-item.scss']
})
export class FarmerBuildingItem {

  @Input() asset: Entity<any>
  @Input() hasAlarm: boolean
  @Output() onClick = new EventEmitter<Asset>();

  text = TEXT
  building: FarmerBuilding | FarmerSilo
  colors = agTypes.colors
  minValue
  maxValue
  name = 'name'
  lastTs
  cropType: string
  flotSettings = {
    shadowSize: 0,
    axes: [
      {
        position: 'left' as POSITION, showLabels: false
      },
      {
        showLabels: false,
        alignTicksWithAxis: 1,
        position: 'right' as POSITION,
      },
      {
        position: 'bottom' as POSITION,
        showLabels: false
      }
    ],
    grid: {
      outlineWidth: 0,
      margin: 5,
      minBorderMargin: 0,
      borderColor: 'transparent'
    },
    smoothLines: true
  };

  icon = "/assets/svg/building_icon2.svg"
  type: UNIT_TYPE = 'TEMPERATURE'
  timeseries = []

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  sensor: ISensorType

  sensorTypes: ISensorType[] = []
  //getSensorTypes () { return this.building.getSensorTypes() }

  sensorTypeKey (index: number, value: ISensorType) {
    return value.id
  }

  constructor (
    private farmer: FarmerService, 
    private payment: PaymentService
  ) {}
  checkout () {
    this.payment.openPaymentDialog()
  }

  isPaid = null
  ngOnInit () {
    if (this.asset.type == 'building') {
      this.building = this.farmer.getBuilding(this.asset)
    } else {
      this.building = this.farmer.getSilo(this.asset)
    }
    this.listen(this.building.updated.subscribe(() => {
      this.updateValues()
      this.sensorTypes = this.building.getSensorTypes()
      if (!this.sensor && this.sensorTypes.length > 0) {
        this.setActiveSensor(this.sensorTypes[0])
      }
      this.updatePayment()
    }))
    
    this.building.load()
    
    this.listen(this.payment.subscribe(() => {
      this.updatePayment()
    }))
  }

  updatePayment () {
    if (this.asset) {
      let state = this.payment.getAssetState(this.asset)
      this.isPaid = state?.active
    }
  }

  updateValues () {
    let values = this.building.settings
    this.cropType = values.crop_type
    if (this.type == 'TEMPERATURE') {
      this.minValue = values.current_min_temperature
      this.maxValue = values.current_max_temperature
      this.lastTs = this.building.model.getLatestTs([
        'current_min_temperature', 'current_avg_temperature', 'current_max_temperature'
      ])
    } else {
      this.minValue = values.current_min_emc
      this.maxValue = values.current_max_emc
      this.lastTs = this.building.model.getLatestTs([
        'current_min_emc', 'current_avg_emc', 'current_max_emc'
      ])
    }
  }

  isActiveSensor (sensor: ISensorType) {
    return this.sensor && sensor.id == this.sensor.id
  }
  setActiveSensor (sensor: ISensorType) {
    if (sensor.id == SENSOR_TYPE.TEMP) {
      this.setTemperature()
    } else {
      this.setMoisture()
    }
    this.sensor = sensor
    this.updateValues()
  }

  setMoisture () {
    this.type = 'MOISTURE'
    this.timeseries = [
      {
        name: 'current_min_emc',
        label: "Max Emc",
        color: agTypes.colors.minColor
      },
      {
        name: 'current_avg_emc',
        label: "Average Emc",
        color: agTypes.colors.minColor
      },
      {
        name: 'current_max_emc',
        label: "Max Emc",
        color: agTypes.colors.minColor
      }
    ]
  }

  setTemperature () {
    this.type = 'TEMPERATURE'
    this.timeseries = [
      {
        name: 'current_min_temperature',
        label: "Max temperature",
        color: agTypes.colors.maxColor
      },
      {
        name: 'current_avg_temperature',
        label: "Average Temperature",
        color: agTypes.colors.maxColor
      },
      {
        name: 'current_max_temperature',
        label: "Max Temperature",
        color: agTypes.colors.maxColor
      }
    ]
  }

  onClicked () {
    this.onClick.emit(this.asset)
  }
}


@Component({
  selector: 'building-device-item',
  template: `
<div class='container' *ngIf="device">
  <img [src]="icon" style="width: 20px; height: 30px;" />
  <div class='content flex-auto'>
    <div class='label'>{{device.label}}</div>
    <div class='name'>{{device.name}}</div>
  </div>
  <div (click)="removeDevice()"  class='remove-icon-button'>
    <ion-icon class="remove-icon" name="close-circle-outline"></ion-icon>
  </div>
</div>
  `,
  styles: [`

.remove-icon-button {
  width: 30px; height: 30px; position: relative;
}
.remove-icon {
  position: absolute;
  font-size: 20px;
  left: 5px; top: 5px;
}
.container {
  display: flex;
  margin: 13px 8px;
  padding: 8px;
  border: 1px solid gray;
  border-radius: 8px;
  background: white;
  opacity: 0.85 !important;
}
.content {
  margin: 0 10px;
  display: flex; flex-grow: 1; flex-direction: column;
}
.label {
  
}
.name {
  color: dimgray; font-size: 12px;
}
  `]
})
export class BuildingDeviceItem {
  @Input() building: FarmerBuilding
  @Input() deviceId: string

  device: Device
  icon: string
  text = TEXT

  constructor (
    private alertController: AlertController,
    private translate: TranslateService
  ) {}

  ngOnInit () {
    this.device = this.building.devices.find(x => x.id.id == this.deviceId)
    this.icon = deviceIcon(this.device.type)
  }
  async removeDevice () {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.building.remove_device),
      //subHeader: 'Subtitle',
      message: this.translate.instant(this.text.building.remove_device_confirm, {name: this.device.label}), // 'Are you sure you want to remove ' + this.device.label + ' from building ?',
      inputs: [],
      buttons: [
        {
          text: this.translate.instant(this.text.general.cancel),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.translate.instant(this.text.general.yes),
          handler: async (data: {email?: string}) => {
            this.building.unassignDevice(this.device)
          }
        }
      ]
    })
    await alert.present();
  }
}


@Component({
  selector: 'edit-building-map',
  template: `
  <ion-header>
  
  <ion-toolbar>
    <ion-title [translate]="text.building.edit_device_placements"></ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" >
          <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div style="padding: 10px;" class="ag-card flex-auto">
    <building-sensor-map (onMarkersUpdate)="markersUpdated($event)" [editMode]="true" [editable]="true" [building]="building"></building-sensor-map>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="accept()">
    <ion-fab-button color="primary">
        <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
  `,
  styleUrls: ['farmer-building.scss']
})
export class EditBuildingMapDialog {
  @Input() building: FarmerBuilding

  constructor (
    public modalController: ModalController
  ) {}

  text = TEXT
  updatedMarkers: IBuildingMarker[]

  markersUpdated (markers) {
    this.updatedMarkers = markers
  }

  async accept () {
    if (this.updatedMarkers) {
      await this.building.saveMarkers(this.updatedMarkers)
    }
    this.modalController.dismiss()
  }
  dismiss () { this.modalController.dismiss(null) }
}