import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponseBase, HttpResponse
} from '@angular/common/http';

import { Observable, of, throwError, EMPTY } from 'rxjs';
import { AppState } from '../state';
import { AuthService } from './auth.service';
import { catchError, delay, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ToastService } from './toast.service';
import { InterceptorConfig, InterceptorHttpParams } from '../models/auth.model';
import { TEXT } from '../texts';
import { captureMessage } from '@sentry/angular';

let tmpHeaders = {};

export const Constants = {
  serverErrorCode: {
    general: 2,
    authentication: 10,
    jwtTokenExpired: 11,
    tenantTrialExpired: 12,
    credentialsExpired: 15,
    permissionDenied: 20,
    invalidArguments: 30,
    badRequestParams: 31,
    itemNotFound: 32,
    tooManyRequests: 33,
    tooManyUpdates: 34
  },
  entryPoints: {
    login: '/api/auth/login',
    tokenRefresh: '/api/auth/token',
    nonTokenBased: '/api/noauth'
  }
};


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private api = ''
  constructor (private store: Store<AppState>) {
    this.store.select((state) => state.host).subscribe(host => {
      this.api = host.api
    })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      if (!req.url.startsWith('http') && !req.url.startsWith('/assets')) {
        req = req.clone({
          url: this.api + req.url
        })
      }
      return next.handle(req);
  }
}



@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {

  private AUTH_SCHEME = 'Bearer ';
  private AUTH_HEADER_NAME = 'X-Authorization';

  private internalUrlPrefixes = [
    '/api/auth/token',
    '/api/plugins/rpc'
  ];

  private activeRequests = 0;
  private simulateServerDown = false

  constructor(@Inject(Store) private store: Store<AppState>,
              @Inject(ToastService) private toast: ToastService,
              //@Inject(DialogService) private dialogService: DialogService,
              //@Inject(TranslateService) private translate: TranslateService,
              @Inject(AuthService) private authService: AuthService
              ) {
      store.subscribe(state => {
        this.simulateServerDown = !!(state.features.serverDown)
      })
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/api/')) {
      
      const config = this.getInterceptorConfig(req);
      const isLoading = !this.isInternalUrlPrefix(req.url);
      this.updateLoadingState(config, isLoading);
      if (this.isTokenBasedAuthEntryPoint(req.url)) {
        if (!AuthService.getJwtToken() && !this.authService.refreshTokenPending()) {
          console.error('unauthorized!')
          return this.handleResponseError(req, next, new HttpErrorResponse({error: {message: 'Unauthorized!'}, status: 401}));
        } else if (!AuthService.isJwtTokenValid()) {
          console.error('token invalid!')
          return this.handleResponseError(req, next, new HttpErrorResponse({error: {refreshTokenPending: true}}));
        } else {
          return this.jwtIntercept(req, next);
        }
      } else {
        return this.handleRequest(req, next);
      }
    } else {
      return next.handle(req).pipe(catchError(err => {
        const errorResponse = err as HttpErrorResponse;
        // user broke connection, ignore errors
        if (errorResponse.status == 0) {
          return EMPTY
        }
        return throwError(errorResponse);
      }))
    }
  }

  private jwtIntercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq = this.updateAuthorizationHeader(req);
    if (newReq) {
      return this.handleRequest(newReq, next);
    } else {
      return this.handleRequestError(req, new Error('Could not get JWT token from store.'));
    }
  }

  private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let testingServerDown = this.simulateServerDown
    if (testingServerDown) {
      return this.handleResponseError(req, next, new HttpErrorResponse({status: 503}));
    }
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponseBase) {
          this.handleResponse(req, event as HttpResponseBase);
        }
      }),
      catchError((err) => {
        const errorResponse = err as HttpErrorResponse;
        return this.handleResponseError(req, next, errorResponse);
      }));
  }

  private handleRequestError(req: HttpRequest<any>, err): Observable<HttpEvent<any>> {
    console.error('handleRequestError', req, err)
    const config = this.getInterceptorConfig(req);
    if (req.url.startsWith('/api/')) {
      this.updateLoadingState(config, false);
    }
    return throwError(err);
  }

  private handleResponse(req: HttpRequest<any>, response: HttpResponseBase) {
    const config = this.getInterceptorConfig(req);
    if (req.url.startsWith('/api/')) {
      this.updateLoadingState(config, false);
    }
  }

private handleResponseError(req: HttpRequest<any>, next: HttpHandler, errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> | null {
    const config = this.getInterceptorConfig(req);
    console.error(`${errorResponse.status} ${req.method} ${req.url} - ${errorResponse.statusText}`)
    if (req.url.startsWith('/api/')) {
      this.updateLoadingState(config, false);
    }
    let unhandled = false;
    const ignoreErrors = config.ignoreErrors;
    const resendRequest = config.resendRequest;
    const errorCode = errorResponse.error ? errorResponse.error.errorCode : null;
    
    if (errorResponse.error && errorResponse.error.refreshTokenPending || errorResponse.status === 401) {
      if (errorResponse.error && errorResponse.error.refreshTokenPending ||
          errorCode && errorCode === Constants.serverErrorCode.jwtTokenExpired) {
          
          /*console.log('retry refresh', 
            errorResponse.error?.refreshTokenPending,
            errorResponse.status,
            errorCode, Constants.serverErrorCode.jwtTokenExpired
          )*/
          // something goes wrong when deleting user from admin and open profile page
          // this seems to fix it, but not sure if correct
          if (errorResponse.status == 0) {
            return EMPTY
          } else {
            return this.refreshTokenAndRetry(req, next);
          }
      } else if (errorCode !== Constants.serverErrorCode.credentialsExpired) {
        unhandled = true;
      }
    } else if (errorResponse.status === 429) {
      if (resendRequest) {
        return this.retryRequest(req, next);
      }
    } else if (errorResponse.status === 403) {
      if (!ignoreErrors) {
        this.showError(TEXT.general.forbidden)
      }
    } else if (errorResponse.status === 503) {
      if (!ignoreErrors) {
        this.showError(TEXT.general.server_down)
      }
    } else if (errorResponse.status === 0 || errorResponse.status === -1) {
        this.showError('No internet connection');
    } else if (!req.url.startsWith('/api/plugins/rpc')) {
      if (errorResponse.status === 404) {
        if (!ignoreErrors) {
          this.showError(errorResponse.statusText);
        }
      } else {
        unhandled = true;
      }
    }

    if (unhandled && !ignoreErrors) {
      let error = null;
      if (req.responseType === 'text') {
        try {
          error = errorResponse.error ? JSON.parse(errorResponse.error) : null;
        } catch (e) {}
      } else {
        error = errorResponse.error;
      }
      if (error && !error.message) {
        this.showError(this.prepareMessageFromData(error));
      } else if (error && error.message) {
        this.showError(error.message, error.timeout ? error.timeout : 0);
      } else {
        this.showError('Unhandled error code ' + (error ? error.status : '\'Unknown\''));
      }
      

      if (errorResponse.status === 401) {
        this.authService.logout(true);
      } else {
        captureMessage(`[${errorResponse.status}] ${errorResponse.url}`, {
          extra: {error: error}, level: 'error'
        })
      }
    }
    return throwError(errorResponse);
  }

  private prepareMessageFromData(data) {
    if (typeof data === 'object' && data.constructor === ArrayBuffer) {
      const msg = String.fromCharCode.apply(null, new Uint8Array(data));
      try {
        const msgObj = JSON.parse(msg);
        if (msgObj.message) {
          return msgObj.message;
        } else {
          return msg;
        }
      } catch (e) {
        return msg;
      }
    } else {
      return data;
    }
  }

  private retryRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const thisTimeout =  1000 + Math.random() * 3000;
    return of(null).pipe(
      delay(thisTimeout),
      mergeMap(() => {
        return this.jwtIntercept(req, next);
      }
    ));
  }

  private refreshTokenAndRetry(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.warn('interceptors: refreshTokenAndRetry')
    return this.authService.refreshJwtToken().pipe(switchMap(() => {
      return this.jwtIntercept(req, next);
    }),
    catchError((err: Error) => {
      console.error('refreshTokenAndRetry failed')
      this.authService.logout(true);
      const message = err ? err.message : 'Unauthorized!';
      return this.handleResponseError(req, next, new HttpErrorResponse({error: {message, timeout: 200}, status: 401}));
    }));
  }

  private updateAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    const jwtToken = AuthService.getJwtToken();
    if (jwtToken) {
      req = req.clone({
        setHeaders: (tmpHeaders = {},
          tmpHeaders[this.AUTH_HEADER_NAME] = '' + this.AUTH_SCHEME + jwtToken,
          tmpHeaders)
      });
      return req;
    } else {
      return null;
    }
  }

  private isInternalUrlPrefix(url): boolean {
    for (const index in this.internalUrlPrefixes) {
      if (url.startsWith(this.internalUrlPrefixes[index])) {
        return true;
      }
    }
    return false;
  }

  private isTokenBasedAuthEntryPoint(url): boolean {
    return  url.startsWith('/api/') &&
      !url.startsWith(Constants.entryPoints.login) &&
      !url.startsWith(Constants.entryPoints.tokenRefresh) &&
      !url.startsWith(Constants.entryPoints.nonTokenBased);
  }
  
  private updateLoadingState(config: InterceptorConfig, isLoading: boolean) {
    if (!config.ignoreLoading) {
      if (isLoading) {
        this.activeRequests++;
      } else {
        this.activeRequests--;
      }
      if (this.activeRequests === 1 && isLoading) {
        //TODO: this.store.dispatch(new ActionLoadStart());
      } else if (this.activeRequests === 0) {
        // TODO: ..this.store.dispatch(new ActionLoadFinish());
      }
    }
  }

  private getInterceptorConfig(req: HttpRequest<any>): InterceptorConfig {
    if (req.params && req.params instanceof InterceptorHttpParams) {
      return (req.params as InterceptorHttpParams).interceptorConfig;
    } else {
      return new InterceptorConfig(false, false);
    }
  }

  private showError(error: string, timeout: number = 0) {
    // TODO: this causes claimDevice failure to show http-error in toast

    this.toast.notify({message: error, type: 'error'})
  }
}
