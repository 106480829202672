import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { agTypes } from "../ag-types";
import { Asset, Device, Entity, EntityId, EntityType } from "../models/entity.model";
import { AggregationType, AttributeScope, ITelemetryValue } from "../models/telemetry.model";
import { AppState } from "../state";
import { Index, isAsset, LoadingState, onAuthenticationChange, onConnectionState, shortId } from "../util";
import { AlarmService } from "./alarm.service";
import { AttributeService, EntityService } from "./entity.service";
import { TelemetryContext, TelemetryService, TelemetryWebsocketService, TimeseriesSubsciption } from "./telemetry";
import { AGUserService } from "./user.services";
import { FarmerBuilding } from "../components/farmer-building/farmer-building.model";
import { FarmerSilo, FarmerSiloCable, SiloTMSGateway } from "../components/farmer-silo/farmer-silo.model";
import { FarmerType, FarmerTypes, IEntityAttribute } from "./types.service";
import { FarmerCellularSpear } from "../components/cellular-spear/spear.model";
import { FarmerMeasurementDevice } from "../components/super-pro/super-pro.model";
import { BehaviorSubject, merge, Subscription } from "rxjs";
import { isEqual } from "lodash-es";
import { captureMessage } from "@sentry/angular";
import { AppService } from "../app.component";
import { ProfileService } from "../components/login-page/profile-page";


export enum ClaimResponse {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CLAIMED = 'CLAIMED'
}

export interface ISignupParams {
  email: string, firstName: string, lastName: string, password: string
  recaptchaResponse: string, tos: boolean
}

export interface ClaimResult {
  device: Device;
  response: ClaimResponse;
}

interface IRelationInfo {
  additionalInfo: null
  from: EntityId<any> //{entityType: "DEVICE", id: "07ea8c10-7c03-11eb-86ad-d7832df36490"}
  fromName: string // "farm_point-_UmIalpcI"
  to: EntityId<any> // {entityType: "CUSTOMER", id: "3fa7f720-389f-11eb-a5eb-af18848ee5a8"}
  toName: string | null // null
  type: string // "Contains"
  typeGroup: string // "COMMON"
}

export interface ISubscriptionState {
  deviceTypeAllowed: string
  exists: boolean
  maxDevicesAllowed: number
  paid: boolean
  planId: string
  price: string
  subscriptionId: string
  terms: string
}

interface ICustomer {
  subscriptionState: ISubscriptionState[]
}

interface ICreateDeviceResult {}



export enum SERVER_TYPE {
  PRODUCTION='PRODUCTION',
  DEV='DEV'
}

interface IRelation {
  //fromType: string, toType: string
  from: EntityId<any>
  to: EntityId<any>
}
interface IRelationResponse {
  additionalInfo?: any
  from: EntityId<any>
  fromName?: string
  toName?: string
  to: EntityId<any>
  type: string
  typeGroup: string
}
interface IRelationUpdate {
  from: EntityId<any>, to: EntityId<any>, remove?: boolean
}
export interface IConnectState {
  online: boolean, connecting: boolean, connected: boolean, recovered: boolean
}

@Injectable()
export class ConnectionService { 
  constructor (private telemetry: TelemetryWebsocketService) {
    this.watchConnection()
  }
  _subscriber: Subscription
  connection = new BehaviorSubject<IConnectState>({
    online: false, connecting: false, connected: false, recovered: false
  })
  subscribe (cb: (state: IConnectState) => void) { return this.connection.subscribe(cb) }
  watchConnection () {
    this._subscriber = merge(onConnectionState(), this.telemetry.state).subscribe(x => this.updateConnectionState())
  }
  ngOnDestroy () { this._subscriber?.unsubscribe() }
  updateConnectionState () {
    let currentState = this.connection.value
    let connectState: IConnectState = {
      online: false, connecting: false, connected: false, recovered: false
    }
    connectState.online = navigator.onLine 
    connectState.connecting = navigator.onLine && this.telemetry.isReconnect
    connectState.connected = navigator.onLine && this.telemetry.isOpened
    connectState.recovered = !currentState.connected && connectState.connected
    //console.log('connection state', connectState, isEqual(currentState, connectState))
    if (!isEqual(currentState, connectState)) {
      this.connection.next(connectState)
    }
  }
} 


export interface IActivateResult {
  token: string, refreshToken: string
}
interface IResetPasswordResult {
  errorCode?: number, message?: string
}

interface FarmerSettings {
  is_ambient_humidity: boolean
}

@Injectable()
export class FarmerService {
  public onRefresh = new EventEmitter()
  
  public loading = new LoadingState()

  private state: AppState

  private devices: Index<FarmerCellularSpear> = {}
  private buildings: Index<FarmerBuilding> = {}
  private silos: Index<FarmerSilo> = {}
  private cables: Index<FarmerSiloCable> = {}
  private gateways: Index<SiloTMSGateway> = {}
  private measurementDevices: Index<FarmerMeasurementDevice> = {}

  public deviceEntities: Device[] = []
  public assetEntities: Asset[] = []
  public entityRelations: IRelation[] = []

  public settings = new BehaviorSubject<FarmerSettings>({is_ambient_humidity: false})

  public dirtyTs: number = 0

  listEntities () {
    let entities: Entity<any>[] = []
    return entities.concat(this.deviceEntities).concat(this.assetEntities)
  }
  
  constructor (
    public http: HttpClient, 
    public telemetry: TelemetryService,
    public attributeService: AttributeService,
    private store: Store<AppState>,
    private userService: AGUserService,
    public entityService: EntityService,
    public types: FarmerTypes,
    private appService: AppService,
    private profile: ProfileService
  ) {
    this.store.subscribe(state => {
      this.state = state
    })
    // TODO: using markDirty might go wrong since client time is not the same as server-time
    this.entityService.onEntityUpdated.subscribe(e => {
      this.markDirty(new Date().getTime()) // TODO: too slow operation
      //this.reload() 
    })
    onAuthenticationChange(this.store).subscribe(x => {
      if (!x) this.clear()
      else this.markDirty(new Date().getTime()) // this.reload()
    })
    this.userConfiguration()
    
    this.profile.settings.subscribe(profileSettings => {
      this.settings.next({...this.settings.value, is_ambient_humidity: profileSettings.is_ambient_humidity})
    })
  }

  
  // TODO: figure out why we do not receive updates from User attribute changes
  user_subscription: TimeseriesSubsciption
  userConfiguration () {
    this.store.select(state => state.auth?.userDetails).subscribe(user => {
      if (this.user_subscription) this.user_subscription.unsubscribe()
      if (!user) return
      this.user_subscription = this.telemetry.subscribe(user.id, {
        attributes: ['locale']
      }, {
        onData: resp => {
          if (resp.data.locale != undefined) {
            this.appService.setLocale(resp.data.locale[0][1])
          }
          return true
        },
        onLoading: x => 0
      })
    })
  }

  markDirty (ts: number) {
    if (ts > this.dirtyTs) {
      this.dirtyTs = ts
      this.reload(true)
    }
  }

  // DEPRECATED
  markEntityIds (ids: string[]) {
    let currentEntityIds = this.deviceEntities.map(x => x.id.id).concat(this.assetEntities.map(x => x.id.id))
    let newEntities = ids.filter(x => !currentEntityIds.includes(x))
    let removedEntities = currentEntityIds.filter(x => !ids.includes(x))
    if (newEntities.length > 0 || removedEntities.length > 0) {
      this.reload(true)
    }
  }

  async activateUser (emailToken: string) {
    return await this.http.post<IActivateResult>('/api/noauth/activateByEmailCode?emailCode=' + emailToken, {}).toPromise()
  }
  async resetPassword (emailToken: string, newPassword: string) {
    return await this.http.post<IResetPasswordResult>('/api/noauth/resetPassword', {
      resetToken: emailToken, password: newPassword
    }).toPromise()
  }

  isDev () {
    return this.state.host.api.includes('farmerdev.agrolog.io')
  }

  models: Index<EntityModel<any,any>> = {}
  createModel<S extends EntityType, T> (type: FarmerType<S, T>, entity: Entity<any>): EntityModel<S, T> {
    let key = entity.id.entityType + ':' + entity.id.id
    if (this.models[key]) return this.models[key]
    let model = new EntityModel(this, type, entity)
    if (!type.config.profiles.includes(entity.type)) {
      console.error('init error', type, entity)
      throw new Error('bad initialization')
    }
    this.models[key] = model
    return model
  }
  getModelType (type: string) {
    return this.types.get(type)
  }
  getAttributeOptions (attr: IEntityAttribute<any>) {
    if (attr.scope == 'relation') {
      let allEntities = [...this.deviceEntities, ...this.assetEntities]
      return allEntities.filter(x => x.type == attr.type)
    }
    return []
  }

  async createRelation (from: EntityId<any>, to: EntityId<any>) {
    let url = '/api/relation'
    await this.http.post(url, {
      additionalInfo: "",
      from: from,
      to: to,
      type: 'Contains',
      typeGroup: "COMMON"
    }).toPromise()
  }
  async removeRelation (from: EntityId<any>, to: EntityId<any>) {
    let url = '/api/relation?relationTypeGroup=COMMON'
    url += '&fromId=' + from.id
    url += '&fromType=' + from.entityType 
    url += '&relationType=Contains'
    url += '&toId=' + to.id 
    url += '&toType=' + to.entityType
    await this.http.delete(url).toPromise()
  }

  async saveEntityRelation (toId: EntityId<any>, fromType: string, fromId: string | null) {
    let allEntities = [...this.deviceEntities, ...this.assetEntities]
    // current relations to this object from all $fromType
    let allRelationsTo = this.entityRelations.filter(x => x.to.id == toId.id).filter(x => {
      return allEntities.find(entity => entity.type == fromType)
    })
    if (allRelationsTo.length > 1) {
      console.warn('multiple relations to entity, this can indicate a problem')
    }
    for (var remRel of allRelationsTo) {
      await this.removeRelation(remRel.from, remRel.to)
    }
    if (fromId != null) {
      let type = this.types.get(fromType)
      await this.createRelation(type.createEntityId(fromId), toId)
    }
  }

  loaded = false
  subscribe (cb: () => void) {
    if (this.loaded) cb()
    return this.onRefresh.subscribe(cb)
  }
  getBuilding (asset: Asset) {
    let key = asset.id.id
    if (!this.buildings[key]) {
      this.buildings[key] = new FarmerBuilding(this, asset)
    }
    //this.buildings[key].set_entity(asset)
    return this.buildings[key]
  }
  getSilo (asset: Asset) {
    let key = asset.id.id
    if (!this.silos[key]) {
      this.silos[key] = new FarmerSilo(this, asset)
    }
    //this.silos[key].set_entity(asset)
    return this.silos[key]
  }
  getTMSGateway (device: Device) {
    let key = device.id.id
    if (!this.gateways[key]) {
      this.gateways[key] = new SiloTMSGateway(this, device)
    }
    //this.gateways[key].set_entity(device)
    return this.gateways[key]
  }
  getEntityModel (entity: Device|Asset) {
    if (entity.type == agTypes.farmerDeviceTypes.silo.value)
      return this.getSilo(entity as Asset)
    else if (entity.type == agTypes.farmerDeviceTypes.cellularSensorSpear.value)
      return this.getCellularSpear(entity as Device)
    else if (entity.type == agTypes.farmerDeviceTypes.building.value)
      return this.getBuilding(entity as Asset)
    //else if (entity.type == agTypes.farmerDeviceTypes.tmsGateway.value)
    //  return this.getTMSGateway(entity as Device)
    return null // TODO: ..
  }
  getMeasurementDevice (entity: Device) {
    let key = entity.id.id
    if (!this.measurementDevices[key]) {
      this.measurementDevices[key] = new FarmerMeasurementDevice(this, entity)
    }
    this.measurementDevices[key].set_entity(entity)
    return this.measurementDevices[key]
  }
  getCellularSpear (entity: Device) {
    let key = entity.id.id
    if (!this.devices[key]) {
      this.devices[key] = new FarmerCellularSpear(this, entity)
    }
    this.devices[key].set_entity(entity)
    return this.devices[key]
  }
  getSiloCable (entity: Device) {
    let key = entity.id.id
    if (!this.cables[key]) {
      this.cables[key] = new FarmerSiloCable(this, entity)
    }
    this.cables[key].set_entity(entity)
    return this.cables[key]
  }

  clear () {
    this.deviceEntities = []
    this.assetEntities = []
    this.entityRelations = []
    //this.onRefresh.emit()
  }

  // TODO: had to remove "clear" from reloading, it was coursing other refresh problems
  //  this whole process should be re-thought about
  _num_reloads = 0
  async reload (silent=false) {
    console.log('reload entities')
    //return
    if (!silent) {
      this.loading.loading(true)
    }
    try {
      //this.clear()
      this._num_reloads += 1
      // TODO: this should only reload entity-service
      this.entityRelations = []
      this.assetEntities = await this.entityService.getCustomerAssets()
      this.deviceEntities = (await this.entityService.getDevicesByType()).data
      // TODO: figure out if its possible to get all relations in one call
      let entities = [...this.assetEntities, ...this.deviceEntities]
      let relations: IRelation[] = []
      for (var asset of this.assetEntities) {
        let url = `/api/relations/info?fromId=${asset.id.id}&fromType=ASSET`
        let deviceRelations = await this.http.get<IRelationResponse[]>(url).toPromise()
        
        relations = relations.concat(deviceRelations)
      }
      this.entityRelations = relations

      // TODO: is this needed ? 
      this.deviceEntities.map(e => {
        if (e.type != agTypes.farmerDeviceTypes.cellularSensorSpear.value) return
        let device = this.getCellularSpear(e)
        let asset = this.buildingFromDevice(e)
        if (asset) {
          device.setBuildingModel(this.getBuilding(asset).model)
        } else {
          device.setBuildingModel(null)
        }
      })
      this.loaded = true
      this.onRefresh.emit()
      this.loading.success()

    } catch (err) {
      this.loading.error('failed to load')
      throw err
    }
  }

  isLoaded () { return this.loading.is_success() }

  getBuildingDevices (building: Asset) {
    let rels = this.entityRelations.filter(x => {
      return x.from.id == building.id.id
    })
    return this.deviceEntities.filter(d => {
      return rels.find(x => x.to.id == d.id.id)
    })
  }
  getSiloCables (silo: Asset) {
    let rels = this.entityRelations.filter(x => {
      return x.from.id == silo.id.id
    })
    return this.deviceEntities.filter(d => {
      return rels.find(x => x.to.id == d.id.id)
    })
  }

  buildingFromDevice (device: Device) {
    let rel = this.entityRelations.find(rel => {
      return rel.to.id == device.id.id
    })
    if (!rel) return null
    let types = ['building', 'silo']
    return this.assetEntities.find(x => types.includes(x.type) && x.id.id == rel.from.id)
  }

  listFreeDevices () {
    let result: Device[] = []
    for (let device of this.deviceEntities) {
      let building = this.buildingFromDevice(device)
      if (!building) {
        result.push(device)
      } else {
      }
    }
    return result
  }

  async changePassword (opt: {currentPassword: string, newPassword: string}) {
    let resp = await this.http.post<any>('/api/auth/changePassword', opt).toPromise()
  }

  async claimDevice(deviceName: string, deviceSecret?: string, config={}) {
    let url = "/api/customer/device/" + deviceName + "/claim";
    let obj = deviceSecret ? { secretKey: deviceSecret } : {};
    let result = await this.http.post<ClaimResult>(url, obj, {}).toPromise()
    if (isDeviceEntity(result.device)) {
      await this.removeDeviceRelations(result.device)
    }
    this.reload()
    return result
  }
  async unclaimDevice (device: Device) {
    let url = '/api/customer/device/' + device.id.id
    let result = await this.http.delete(url).toPromise()
    this.reload()
    return result
  }

  async deleteBuilding (building: Asset) {
    if (building.type != 'building') throw new Error('not a building: ' + building)
    
    let rels = this.entityRelations.filter(x => {
      return x.from.id == building.id.id
    })
    for (var rel of rels) {
      await this.removeRelation(rel.from, rel.to)
    }
    let url = '/api/customer/asset/' + building.id.id
    await this.http.delete(url).toPromise()
    this.reload()
  }

  async saveEntity (entity: Entity<any>, settings: any) {
    await this.createModel(this.types.get(entity.type), entity).saveSettings(settings)
  }
  async removeDeviceRelations (device: Device) {
    let url = `/api/relations/info?toId=${device.id.id}&toType=${device.id.entityType}`
    let deviceRelations = await this.http.get<IRelationResponse[]>(url).toPromise()
    for (var rel of deviceRelations) {
      if (rel.from.entityType == 'ASSET') {
        await this.removeRelation(rel.from, rel.to)
      }
    }
  }
  async createEntity (typename: string, settings: any) {
    let type = this.types.get(typename)
    let name = settings.label
    if (!name) throw new Error('new entities must provide a name')

    let auth = this.userService.getAuthUser()
    
    let url = '/api/device'
    if (type.entityType == EntityType.ASSET) {
      url = '/api/asset'
    }
    let body = {
      name: typename + '-' + shortId(),
      label: name,
      additionalInfo: {
        gateway: false,
        description: ""
      },
      customerId: null, // customerId,
      type: typename
    }
    let result = await this.http.post<Device|Asset>(url, body).toPromise()
    
    await this.saveEntity(result, settings)
    this.reload() // TODO: no need for full reload, just add the new object
    return result
  }
  
  async fetchSignupParams () {
    return await this.http.get<{captchaSiteKey: string}>("/api/noauth/selfRegistration/signUpSelfRegistrationParams").toPromise()
  }

  async signup (data: ISignupParams) {
    data = {...data, email: data.email.trim().toLowerCase()}
    return await this.http.post<any>('/api/noauth/signup', data).toPromise()
  }

  async sendActivationLink (email: string) {
    email = email.trim().toLowerCase()
    return await this.http.post<any>('/api/noauth/resendEmailActivation?email=' + email, {}).toPromise()
  }

  globalContext = new TelemetryContext(this.telemetry, 'GLOBAL', {
    timeWindow: (1000 * 60 * 60 * 24 * 1),
    interval: 1000 * 60 * 5,
    agg: AggregationType.AVG, mode: 'TIME-WINDOW', startTs: 0, endTs: 0
  })
}


class ModelQuery {

}

function isAssetEntity (x: Entity<any>): x is Asset {
  return x && x.id.entityType == EntityType.ASSET
}
function isDeviceEntity (x: Entity<any>): x is Device {
  return x && x.id.entityType == EntityType.DEVICE
}

@Injectable()
export class ModelService {
  queries: Index<ModelQuery> = {}
  models: Index<EntityModel<any, any>> = {}
  relations = new BehaviorSubject<IRelation[]>([])
  entities = new BehaviorSubject<Entity<any>[]>([])
  loading = new LoadingState()

  constructor (
    private entityService: EntityService,
    private http: HttpClient
  ) {}

  listEntities () {
    return this.entities.value
  }
  
  listAssets (): Asset[] {
    return this.listEntities().filter(x => isAssetEntity(x))
  }
  listDevices (): Device[] {
    return this.listEntities().filter(x => isDeviceEntity(x))
  }

  clear () {
    this.relations.next([])
    this.entities.next([])
  }

  async reload () {
    this.loading.loading(true)
    this.clear()
    try {
      let assetEntities = await this.entityService.getCustomerAssets()
      let deviceEntities = (await this.entityService.getDevicesByType()).data
      this.entities.next([].concat(assetEntities).concat(deviceEntities))
      let relations: IRelation[] = []
      for (var asset of this.listAssets()) {
        let url = `/api/relations/info?fromId=${asset.id.id}&fromType=ASSET`
        let deviceRelations = await this.http.get<IRelationResponse[]>(url).toPromise()
        relations = relations.concat(deviceRelations)
      }
      this.relations.next(relations)
      this.loading.success()
    } catch (err) {
      this.loading.error(err)
      throw err
    }
  }
}


export class EntityModel<T extends EntityType, S> {
  onUpdate: EventEmitter<Partial<S>> // = new EventEmitter<Partial<S>>()
  
  public loading = new LoadingState()
  public settings: Readonly<S>
  public ts: Index<number>
  public values: Index<ITelemetryValue<any>>
  
  constructor (private service: FarmerService, private type: FarmerType<T, S>, public entity: Entity<T>) {
    this.onUpdate = new EventEmitter<Partial<S>>()
    this.settings = this.attributes.defaultData()
    this.values = {}
    this.ts = {}
    this.service.onRefresh.subscribe(_ => {
      let entity = this.service.listEntities().find(x => x.id.id == this.entity.id.id)
      if (entity) {
        this.entity = entity
        this.applySettings({label: entity.label} as any)
      } else {
        console.warn('entity not found', this.entity.name)
      }
    })
  }
  get attributes () { return this.type.attributes }
  
  emit (values: Partial<S>) {
    this.onUpdate.emit(values)
  }

  async fetchQrValue () {
    let data = await this.service.attributeService.getEntityAttributes(
      this.entity.id, AttributeScope.SERVER_SCOPE, ['claimingData', 'subtype']
    ).toPromise()
    let secret = data.find(x => x.key == 'claimingData').value
    let subtype = data.find(x => x.key == 'subtype')?.value || 'TM'
    let value = this.entity.name + '\\::' + secret + '\\::' + subtype
    return value
  }

  isLoaded () { 
    //return Object.keys(this._loadedSettings).length > 0
    return this.loading.is_success()
  }

  getValues (keys: string[]) {
    let result: ITelemetryValue<any>[] = []
    for (let k of keys) {
      if (this.values[k]) result.push(this.values[k])
    }
    return result
  }

  getLatestTs (keys: string[]) {
    let values = keys.map(k => this.ts[k] || 0)
    return Math.max(...values)
  }

  async saveSettings (settings: Partial<S>, force=false) {
    let entityValues = {}
    this.attributes.entities().map(attr => {
      let value = settings[attr.key]
      let key = attr.key as string
      if (value && this.entity[key] != value) {
        entityValues[key] = value
      }
    })
    if (Object.keys(entityValues).length > 0) {
      let newDevice = Object.assign({}, this.entity, entityValues)
      await this.service.entityService.saveEntity(newDevice)
      Object.assign(this.settings, entityValues)
    }

    let serverAttributes: {key: string, value: any}[] = []
    let sharedAttributes: {key: string, value: any}[] = []
    let telemetryAttributes: {key: string, value: any}[] = []
    let relationAttributes: {type: string, value: string|null}[] = []
    
    let properties: IEntityAttribute<S>[] = []
    properties = this.attributes.all() // [].concat(this.attributes.attributes()).concat(this.attributes.telemetries())
    let updatedAttributes = properties.filter(attr => {
      return !attr.readonly && attr.scope != 'entity' && settings[attr.key] != null && (force || settings[attr.key] != this.settings[attr.key])
    })
    updatedAttributes.map(attr => {
      let attrValue = {key: attr.key, value: settings[attr.key]}
      if (attr.scope == 'server') {
        serverAttributes.push(attrValue as any)
      } else if (attr.scope == 'shared') {
        sharedAttributes.push(attrValue as any)
      } else if (attr.scope == 'telemetry') {
        telemetryAttributes.push(attrValue as any)
      } else if (attr.scope == 'relation') {
        relationAttributes.push({
          type: attr.type, value: settings[attr.key] as any
        })
      }
    })

    if (telemetryAttributes.length > 0) {
      await this.service.attributeService.saveEntityTimeseries(
        this.entity.id, 'scope', telemetryAttributes
      ).toPromise()
    }
    if (serverAttributes.length > 0) {
      await this.service.attributeService.saveEntityAttributes(
        this.entity.id, AttributeScope.SERVER_SCOPE, serverAttributes
      ).toPromise()
    }
    if (sharedAttributes.length > 0) {
      await this.service.attributeService.saveEntityAttributes(
        this.entity.id, AttributeScope.SHARED_SCOPE, sharedAttributes
      ).toPromise()
    }
    if (relationAttributes.length > 0) {
      for (var rel of relationAttributes) {
        await this.service.saveEntityRelation(this.entity.id, rel.type, rel.value)
      }
    }

    this.emit(entityValues)
    this.applySettings(settings)
    this.loadSettings()
  }

  loadedKeys: Index<boolean> = {}
  async saveDefaultData (data) {
    for (let k in data) {
      this.loadedKeys[k] = true
    }
    let unsavedData: any = {}
    for (let key in this.settings) {
      let attr = this.attributes.attributes().find(a => a.key == key)
      if (!this.loadedKeys[key] && attr && !attr.readonly && attr.required) {
        unsavedData[key] = this.settings[key]
      }
    }
    if (Object.keys(unsavedData).length > 0) {
      await this.saveSettings(unsavedData, true)
    }
  }

  load () {
    if (this.loading.is_initial()) {
      this.loading.loading(true)
      
      let attributes = this.attributes.attributes().map(k => k.key) as string[]
      let telemetry = this.attributes.telemetries().map(k => k.key) as string[]
      let allKeys = [].concat(attributes).concat(telemetry)

      this.loadSettings().add(() => {
        this.emit({...this.settings})
      })
      // NOTE: sometimes telemetry wont update from latest changes on assets, F variable is for reproducing this problem
      this.service.telemetry.latest(this.entity.id, {
        attributes: attributes, keys: telemetry
      }, {
        onData: (resp) => {
          let updatedData = {}
          for (var k in resp.data) {
            let value = resp.data[k][0][1]
            if (allKeys.includes(k as any)) {
              if (value != undefined) {
                updatedData[k] = value
                this.ts[k] = resp.data[k][0][0]
                this.values[k] = {key: k, ts: resp.data[k][0][0], value: resp.data[k][0][1]}
              }
            }
          }
          this.applySettings(updatedData)
          //this.onUpdate.emit({...updatedData})
          return true
        },
        onLoading: (isLoading) => {
          this.loading.loading(isLoading)
        }
      })
    }
    return this.onUpdate
  }

  _updateId: string
  _loadedSettings = {}
  private applySettings (settings: Partial<S>) {
    let updated: Partial<S> = {}
    for (var k in settings) {
      let value = settings[k] as any
      let attr = this.attributes.get(k)
      if (!attr) {
        console.warn('received unknown attribute:', k)
        continue
      }
      try {
        if (attr.type == 'number') {
          value = parseFloat(value)
        } else if (attr.type == 'boolean') {
          if (value == 'true') {
            value = true
          } else if (value == 'false') {
            value = false
          } else {
            value = Boolean(value)
          }
        } else if (attr.type == 'string') {
          value = value.toString()
        } else if (attr.type == 'json' && typeof value == 'string') {
          value = JSON.parse(value)
        } else if (attr.type == 'json' && typeof value == 'object') {
          
        } else throw new Error('unknown attribute type')
      } catch (err) {
        captureMessage('failed to load attribute', {extra: {'attribute': k}, level: 'info'})
        continue
      }
      
      if (!isEqual(value, this.settings[k])) {
        updated[k] = value
      } 
    }
    if (Object.keys(updated).length > 0) {
      this._updateId = shortId()
      Object.assign(this.settings, updated)
      Object.keys(updated).map(k => {
        this._loadedSettings[k] = true
      })
      this.emit(updated)
    }
  }

  // TODO: load telemetry and shared attributes also
  loadSettings () {
    let device = this.entity
    let serverAttrs = this.attributes.attributes()
      .filter(attr => attr.scope == 'server').map(attr => attr.key) as string[]
    return this.service.attributeService.getEntityAttributes(
        device.id, AttributeScope.SERVER_SCOPE, serverAttrs, 
        {ignoreLoading: true}).subscribe(serverAttributes => {
      let settings: Partial<S> = {}
      serverAttributes.map(attr => {
        settings[attr.key] = attr.value
      })
      let entities = this.attributes.entities()
      entities.map(attr => {
        settings[attr.key as any] = this.entity[attr.key as any]
      })
      this.saveDefaultData({...settings})
      this.applySettings(settings)
    })
  }
}

