import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalHttpInterceptor, RequestInterceptor } from './services/interceptors';
import { CommonModule } from '@angular/common';

import { AppRoutingModule, AuthGuard, NoAuthGuard } from './app-routing.module';
import { AdminPage, AppComponent, AppService, CustomErrorsHandler, SessionService } from './app.component';

import { TelemetryService, TelemetryWebsocketService } from './services/telemetry';
import { UnitService } from './services/unit';
import { reducers, metaReducers } from './state';
import { AGUserService } from './services/user.services';
import { AuthService } from './services/auth.service';
import { ConnectionService, FarmerService } from './services/device.service'
 
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FlexLayoutModule } from '@angular/flex-layout';


import { 
  FarmerDevicesComponent, FarmerDeviceDetailComponent, PaymentCardOverlay, EntityGrid
} from './components/farmer-devices/farmer-devices';

import { AgPlotComponent, AgPlotTooltip, AgTooltipService, FlotDirective } from './components/ag-plot/ag-plot';
import { SpearOverviewComponent, SpearPageComponent, SpearSettingsComponent } from './components/cellular-spear/spear-overview';
import { DevicesMapComponent, DevicesMapDirective, DevicesMapPage } from './components/device-map/devices-map';
import { AddMeasurementDialogComponent, MeasurementMapComponent, SuperProOverviewComponent, SuperProSettingsComponent } from './components/super-pro/super-pro';
import { FormsModule } from '@angular/forms';
import { Store, StoreModule } from '@ngrx/store';
import { ToastService } from './services/toast.service';
import { LocalStorageService } from './services/local-storage.service';
import { MainMenu, MenuService, PermissionErrorMessage, PermissionService, ProfileMenu, TimeQuerySettings } from './menus';

import { AttributeService, EntityService } from './services/entity.service';
import { AssetCommentsComponent } from './components/asset-comments/asset-comments';
import { LoginFormComponent } from './components/login-page/login-page';

//import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AlarmPageComponent, EntityAlarmGroup } from './components/alarm-page/alarm-page';
import { TestPlot } from './components/test-telemetry/test-telemetry';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationService } from './services/location.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { AlarmService } from './services/alarm.service';
import { AgConnectionDialog, AgLoadingComponent, AgSensorValue, AgTelemetryValue, AgTimeSince, AssetService, BatteryLevelComponent, CountrySelect, CropLabelPipe, FormatTimePipe, MapMarker, QrCodeGenerator, ReversePipe, SignalStrengthComponent, SinceNowPipe, SortLocale, TestComponents } from './components/base-components';
import { BillingPage } from './components/login-page/billing';
import { CheckoutPopover, InvoiceEstimate, PaymentService, PaymentSubscriptionForm, PaymentSuccessDialog } from './services/payment.service';
import { UserAgent } from '@ionic-native/user-agent/ngx';
//import { RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_BASE_URL } from 'ng-recaptcha';
import { ProfilePageComponent, ProfileService } from './components/login-page/profile-page';
import { BuildingSensorMap, DeviceSelector, FarmerBuildingDevicesPage, FarmerBuildingPage, FarmerBuildingSettings } from './components/farmer-building/farmer-building';
import { BuildingDeviceItem, BuildingListPage, EditBuildingMapDialog, FarmerBuildingItem } from './components/farmer-building/farmer-building-list';

import { ServiceWorkerModule, SwPush } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MessagingService } from './services/messaging.service';
import { FarmerSiloDevicesPage, FarmerSiloPage } from './components/farmer-silo/farmer-silo';
import { FarmerSiloSettings } from './components/farmer-silo/farmer-silo-settings';
import { FarmerSiloTable } from './components/farmer-silo/farmer-silo-table';
import { FarmerCablePlacementComponent, FarmerSiloTopDrawing, SelectSiloCable } from './components/farmer-silo/farmer-cable-placement';
import { FarmerSiloHistory, FarmerSiloHistoryPage } from './components/farmer-silo/farmer-silo-history';
import { SiloDrawing3D } from './components/farmer-silo/farmer-silo-3d';
import { FarmerBuildingHistory, FarmerBuildingHistoryPage, FarmerBuildingSpearTable } from './components/farmer-building/farmer-building-history';
import { QRScanDirective } from './components/farmer-devices/farmer-device-scanner';
import { AddDeviceDialogComponent, CameraService, FarmerDeviceConfig } from './components/farmer-devices/farmer-create-device';
import { FarmerTypes } from './services/types.service';
import { FarmerMeasurementItem } from './components/super-pro/super-pro.item';
import { FarmerCellularSpearItem } from './components/cellular-spear/spear-item';
import { DeviceChart } from './components/farmer-devices/farmer-device-chart';

import { createErrorHandler }  from "@sentry/angular";
import { NgxPullToRefreshModule } from 'ngx-pull-to-refresh';

import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

//import { FCM } from '@awesome-cordova-plugins/fcm/ngx'
// import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';

//import { DeeplinksOriginal, DeeplinkOptions, DeeplinkMatch } from '@awesome-cordova-plugins/deeplinks'

import { Deeplinks } from '@ionic-native/deeplinks/ngx';

const SERVICE_WORKER_FILE = 'combined-sw.js'

import { initializeApp } from 'firebase/app'
import { UpgradeNotification, UpgradeService } from './services/upgrade.service';
import { CustomerActivateComponent, ResetPasswordComponent } from './components/login-page/activate-user';
import { AgModal } from './util';
import { getAnalytics } from "firebase/analytics";
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { TypeaheadComponent } from './components/basic/typeahead';

import { Observable } from 'rxjs'
import { tap, filter, map } from 'rxjs/operators'
import { DeleteAccountPageComponent } from './components/login-page/delete-account-page';
import { CropCallibrationComponent } from './components/login-page/callibration';

import { DecimalPipe } from '@angular/common';
import { DEFAULT_TRANSLATIONS, TEXT } from './texts';
import { param } from 'jquery';
import { TestBuildingTelemetry } from './components/testing/test-building-telemetry';

const firebaseConfig = {
  apiKey: "AIzaSyDdudhxeo_LOpU3VWLvdQRI4O2bqH5cSig",
  authDomain: "agrologfarmer.firebaseapp.com",
  projectId: "agrologfarmer",
  storageBucket: "agrologfarmer.appspot.com",
  messagingSenderId: "75365923035",
  appId: "1:75365923035:web:3b93927fcbe0684439b4fe",
  measurementId: "G-FK8KS2YNNN", 
  databaseURL: "https://agrologfarmer-default-rtdb.europe-west1.firebasedatabase.app",
};
function initializeFirebase () {
  const app = initializeApp(firebaseConfig)
  if (environment.analytics) {
    const analytics = getAnalytics(app);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}


class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return DEFAULT_TRANSLATIONS[params.key] || params.key
  }
}

export class CustomLoader implements TranslateLoader {
  constructor(private session: SessionService) {}

  getTranslation(lang: string): any {
    return this.session.messages.pipe(
      tap(x => console.error(' customer load messager', x)),
      filter(x => x.translations != null),
      map(x => x.translations)
    )
  }
}

@NgModule({
  declarations: [
    TestBuildingTelemetry,
    AppComponent, AgLoadingComponent, AgSensorValue, BillingPage,
    QrCodeGenerator, AgConnectionDialog, ResetPasswordComponent, 
    PermissionErrorMessage, UpgradeNotification, CheckoutPopover,
    PaymentCardOverlay, EntityGrid, InvoiceEstimate, EntityAlarmGroup,
    BuildingDeviceItem, EditBuildingMapDialog, AdminPage,
    SinceNowPipe, CropLabelPipe, AgTimeSince, AgTelemetryValue, SortLocale,
    FarmerMeasurementItem, FarmerCellularSpearItem, PaymentSuccessDialog,
    FarmerDevicesComponent, FarmerDeviceDetailComponent, DeviceChart,
    AddMeasurementDialogComponent, MeasurementMapComponent,
    AddDeviceDialogComponent, TestPlot, LoginFormComponent,
    AgPlotComponent, FlotDirective, SpearOverviewComponent, SpearPageComponent,
    SpearSettingsComponent, AssetCommentsComponent, AlarmPageComponent,
    DevicesMapDirective, DevicesMapComponent, SuperProOverviewComponent,
    SuperProSettingsComponent, AgPlotTooltip, CustomerActivateComponent,
    ProfilePageComponent, TestComponents, MapMarker,
    LoginFormComponent, TimeQuerySettings, MainMenu, ProfileMenu, DevicesMapPage,
    QRScanDirective, BatteryLevelComponent, SignalStrengthComponent,
    FarmerBuildingPage, BuildingSensorMap, DeviceSelector, BuildingListPage,
    FarmerBuildingItem, FarmerBuildingSettings, FarmerBuildingDevicesPage,
    FarmerSiloPage, FarmerSiloSettings, FarmerSiloDevicesPage, FarmerSiloTable,
    FarmerCablePlacementComponent, FarmerSiloTopDrawing,
    SelectSiloCable, FarmerSiloHistory, FarmerSiloHistoryPage,
    SiloDrawing3D, ReversePipe, FarmerDeviceConfig, 
    FarmerBuildingHistoryPage, FarmerBuildingHistory, FarmerBuildingSpearTable,
    PaymentSubscriptionForm, FormatTimePipe,
    TypeaheadComponent, CountrySelect, DeleteAccountPageComponent,
    CropCallibrationComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    CommonModule, HttpClientModule, FlexLayoutModule,
    LeafletModule, FormsModule, 
    //RecaptchaFormsModule, RecaptchaModule, 
    NgxPullToRefreshModule,
    StoreModule.forRoot(reducers,
      { metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true
        }}
    ),
    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
      /*loader: {
        provide: TranslateLoader,
        useFactory: (service: SessionService) => new CustomLoader(service),
        deps: [SessionService],
      }*/
    })
    //ServiceWorkerModule.register(SERVICE_WORKER_FILE, { enabled: environment.production })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeFirebase,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorsHandler,
    },
    SessionService, DecimalPipe, ProfileService,
    PermissionService, CameraService,
    AuthGuard, NoAuthGuard,  AgModal, OpenNativeSettings,  //FCM,
    ConnectionService, //LocalNotifications,
    FarmerTypes, AssetService, //DeeplinksOriginal, 
    SwPush, MessagingService, Deeplinks,
    StatusBar, AppService,
    SplashScreen,
    Title, AgTooltipService,
    AndroidPermissions,
    PaymentService, 
    Geolocation, Camera,
    LocalStorageService, //BarcodeScanner,
    TelemetryService, 
    UnitService, AuthService, ToastService, AttributeService,
    LocationService, UserAgent, EntityService,
    AGUserService, FarmerService, TelemetryWebsocketService, UnitService,
    MenuService, AlarmService, UpgradeService,
    Store, 
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    //{ provide: MyErrorHandler, useValue: createErrorHandler() },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    /*{
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js'
    }*/
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
