import { Injectable } from "@angular/core"
import { agTypes } from "../ag-types"
import { CROP_TYPE } from "../constant"
import { EntityId, EntityType } from "../models/entity.model"
import { TEXT } from "../texts"
import { MEASUREMENT_PROFILES } from "../util"


export enum SENSOR_TYPE {
  TEMP_AND_HUM='temperature-humidity',
  TEMP='temperature', HUM='humidity'
}

export interface ISensorType {
  id: SENSOR_TYPE, name: string
}

export const TEMPERATURE_SENSOR: ISensorType = {
  id: SENSOR_TYPE.TEMP, name: TEXT.general.temperature
}
export const MOISTURE_SENSOR: ISensorType = {
  id: SENSOR_TYPE.HUM, name: TEXT.general.moisture
}
export const HUMIDITY_SENSOR: ISensorType = {
  id: SENSOR_TYPE.HUM, name: TEXT.general.humidity
}

export function temperatureSensorType () { return SENSOR_TYPE.TEMP }
export function humiditySensorType () { return SENSOR_TYPE.HUM }
export function tempHumSensorType () { return SENSOR_TYPE.TEMP_AND_HUM }

export interface ISensorData {
  key: string, name: string, type: SENSOR_TYPE
}


export class Attribute {

}
type AttributeType = 'server' | 'shared' | 'entity' | 'telemetry' | 'relation'
type AttributeDatatype = 'number' | 'json' | 'string' | 'boolean' | string
export interface IEntityAttribute<T> {
  key: keyof T, default: any,
  type: AttributeDatatype
  scope: AttributeType
  name: string, readonly?: boolean, required: boolean
}
export class AttributeList<T extends {}> {
  
  constructor (private _attributes: IEntityAttribute<T>[]) {}

  defaultData () {
    let result: any = {}
    this._attributes.map(attr => {
      result[attr.key] = attr.default
    })
    return result as T
  }
  get (key: string) {
    return this._attributes.find(x => x.key == key)
  }
  keys () { return this._attributes.map(attr => attr.key) }

  list (keys: (keyof T)[]) { return this._attributes.filter(attr => keys.includes(attr.key)) }
  attributes () { return this._attributes.filter(x => x.scope == 'server' || x.scope == 'shared') }
  telemetries () { return this._attributes.filter(x => x.scope == 'telemetry') }
  entities () { return this._attributes.filter(x => x.scope == 'entity') }
  all () { return this._attributes }
}

function Attr<T>(key: keyof T, type, scope, _default, writable=true, required=false, name=''): IEntityAttribute<T> {
  let defaultName = (key as string)
  defaultName = defaultName[0].toUpperCase() + defaultName.slice(1)
  return {key: key, type: type, scope: scope, default: _default, required: required,
    name: name || defaultName, readonly: !writable}
}



export interface ISpearSettings {
  name: string, label?: string, crop_type?: CROP_TYPE, sensors?: ISensorData[],
  latitude?: number, longitude?: number
  sample_alarm_max_temperature?: number, sample_alarm_min_temperature?: number
  sample_alarm_max_emc?: number, sample_alarm_min_emc?: number
  ambient_alarm_max_temperature?: number, ambient_alarm_min_temperature?: number
  ambient_alarm_max_emc?: number, ambient_alarm_min_emc?: number
  disable_alarms?: boolean
  logging_interval?: number, report_interval?: number
  pos_x?: number, pos_y?: number
  signal_strength?: number, battery_level?: number
  
  checkout_id?: string,
  //paid_status?: string, plan_id?: string, invoice_line_id?: string
  //subscription_id?: string, is_free?: boolean
  //mode?: 'TRIAL' | 'ACTIVE' | 'CANCELLED', trial_end?: number
}
/*function Attr(key: keyof ISpearSettings, type, scope, _default, writable=true): IEntityAttribute<any> {
  return {key: key, type: type, scope: scope, default: _default, name: key, readonly: !writable}
}*/
export const SpearAttributes = new AttributeList<ISpearSettings>([
  Attr('label', 'string', 'entity', ''),
  //Attr('paid_status', 'string', 'server', null),
  //Attr('subscription_id', 'string', 'server', null),
  //Attr('checkout_id', 'string', 'server', null),
  //Attr('mode', 'string', 'server', null),
  //Attr('trial_end', 'number', 'server', 0),

  //Attr('is_free', 'boolean', 'server', false),
  Attr('pos_x', 'number', 'telemetry', 0),
  Attr('pos_y', 'number', 'telemetry', 0),
  Attr('crop_type', 'string', 'server', ''),
  Attr('latitude', 'number', 'telemetry', null),
  Attr('longitude', 'number', 'telemetry', null),
  Attr('sensors', 'json', 'server', [], false),
  Attr('disable_alarms', 'boolean', 'server', false),
  Attr('sample_alarm_max_temperature', 'number', 'server', 60, true, true),
  Attr('sample_alarm_min_temperature', 'number', 'server', -20, true, true),
  Attr('sample_alarm_max_emc', 'number', 'server', 100, true, true),
  Attr('sample_alarm_min_emc', 'number', 'server', 0, true, true),
  Attr('ambient_alarm_max_temperature', 'number', 'server', 60, true, true),
  Attr('ambient_alarm_min_temperature', 'number', 'server', -20, true, true),
  Attr('ambient_alarm_max_emc', 'number', 'server', 100, true, true),
  Attr('ambient_alarm_min_emc', 'number', 'server', 0, true, true),
  Attr('logging_interval', 'number', 'shared', null),
  Attr('report_interval', 'number', 'shared', null),
  Attr('signal_strength', 'number', 'telemetry', null, false),
  Attr('battery_level', 'number', 'telemetry', null, false)
])


export interface ITMSSettings {
  label?: string, battery_level?: number, signal_strength?: number
}
const TMSAttributes = new AttributeList<ITMSSettings>([
  Attr('label', 'string', 'entity', ''),
  Attr('battery_level', 'number', 'telemetry', 0),
  Attr('signal_strength', 'number', 'telemetry', -1)
])



export interface ISiloSettings {
  label?: string
  height?: number, eave_height?: number, diameter?: number
  hopper?: boolean, hopper_height?: number, hopper_angle?: number
  crop_type?: CROP_TYPE, crop_level?: number, disable_alarms?: boolean
  latitude?: number, longitude?: number
  disabled_sensors?: any,

  sample_alarm_max_temperature?: number, sample_alarm_min_temperature?: number
  sample_alarm_max_emc?: number, sample_alarm_min_emc?: number
  //ambient_alarm_max_temperature?: number, ambient_alarm_min_temperature?: number
  //ambient_alarm_max_emc?: number, ambient_alarm_min_emc?: number
  
  current_min_temperature?: number, current_avg_temperature?: number
  current_max_temperature?: number, current_min_emc?: number
  current_avg_emc?: number, current_max_emc?: number

}
const SiloAttributes = new AttributeList<ISiloSettings>([
  Attr('label', 'string', 'entity', ''),
  Attr('latitude', 'number', 'telemetry', 0), 
  Attr('longitude', 'number', 'telemetry', 0),
  Attr('height', 'number', 'server', 20),
  Attr('eave_height', 'number', 'server', 15),
  Attr('diameter', 'number', 'server', 10),
  Attr('hopper', 'boolean', 'server', false),
  Attr('hopper_height', 'number', 'server', 10),
  Attr('hopper_angle', 'number', 'server', 10),
  Attr('disabled_sensors', 'json', 'server', null),

  //Attr('latitude', 'number', 'server', null),
  //Attr('longitude', 'number', 'server', null),

  Attr('crop_type', 'string', 'server', ''),
  Attr('disable_alarms', 'boolean', 'server', false),
  Attr('sample_alarm_max_temperature', 'number', 'server', 60, true, true),
  Attr('sample_alarm_min_temperature', 'number', 'server', -20, true, true),
  Attr('sample_alarm_max_emc', 'number', 'server', 100, true, true),
  Attr('sample_alarm_min_emc', 'number', 'server', -20, true, true),
  /*Attr('ambient_alarm_max_temperature', 'number', 'server', 50),
  Attr('ambient_alarm_min_temperature', 'number', 'server', 20),
  Attr('ambient_alarm_max_emc', 'number', 'server', 80),
  Attr('ambient_alarm_min_emc', 'number', 'server', 20),*/
  Attr('current_min_emc', 'number', 'telemetry', null, false, false, 'Min Emc'),
  Attr('current_avg_emc', 'number', 'telemetry', null, false, false, 'Avg Emc'),
  Attr('current_max_emc', 'number', 'telemetry', null, false, false, 'Max Emc'),
  Attr('current_min_temperature', 'number', 'telemetry', null, false, false, 'Min Temp'),
  Attr('current_avg_temperature', 'number', 'telemetry', null, false, false, 'Avg Temp'),
  Attr('current_max_temperature', 'number', 'telemetry', null, false, false, 'Max Temp')
])






export interface ISiloCableSettings {
  silo?: string
  length?: number, label?: string
  pos_x?: number, pos_y?: number, pos_z?: number
  sensor_count?: number, uid?: number
  line_num?: number, sensor_distance?: number
  
  disable_alarms?: boolean
  sample_alarm_max_temperature?: number, sample_alarm_min_temperature?: number
  sample_alarm_max_emc?: number, sample_alarm_min_emc?: number
  //ambient_alarm_max_temperature?: number, ambient_alarm_min_temperature?: number
  //ambient_alarm_max_emc?: number, ambient_alarm_min_emc?: number
  
  current_min_temperature?: number, current_avg_temperature?: number
  current_max_temperature?: number, current_min_emc?: number
  current_avg_emc?: number, current_max_emc?: number

}
const SiloCableAttributes = new AttributeList<ISiloCableSettings>([
  Attr('label', 'string', 'entity', ''),

  Attr('silo', agTypes.farmerDeviceTypes.silo.value, 'relation', null),

  Attr('pos_x', 'number', 'server', 0),
  Attr('pos_y', 'number', 'server', 0),
  Attr('pos_z', 'number', 'server', 0),

  Attr('length', 'number', 'server', 20),
  Attr('sensor_count', 'number', 'server', 10),
  Attr('line_num', 'number', 'server', 0),
  Attr('sensor_distance', 'number', 'server', 3),
  Attr('uid', 'number', 'server', 0),
  
  Attr('disable_alarms', 'boolean', 'server', false),
  Attr('sample_alarm_max_temperature', 'number', 'server', 60),
  Attr('sample_alarm_min_temperature', 'number', 'server', -20),
  Attr('sample_alarm_max_emc', 'number', 'server', 100),
  Attr('sample_alarm_min_emc', 'number', 'server', 0),
  /*Attr('ambient_alarm_max_temperature', 'number', 'server', 50),
  Attr('ambient_alarm_min_temperature', 'number', 'server', 20),
  Attr('ambient_alarm_max_emc', 'number', 'server', 80),
  Attr('ambient_alarm_min_emc', 'number', 'server', 20),*/
  Attr('current_min_emc', 'number', 'telemetry', null, false, false, 'Min Emc'),
  Attr('current_avg_emc', 'number', 'telemetry', null, false, false, 'Avg Emc'),
  Attr('current_max_emc', 'number', 'telemetry', null, false, false, 'Max Emc'),
  Attr('current_min_temperature', 'number', 'telemetry', null, false, false, 'Min Temp'),
  Attr('current_avg_temperature', 'number', 'telemetry', null, false, false, 'Avg Temp'),
  Attr('current_max_temperature', 'number', 'telemetry', null, false, false, 'Max Temp')
])


export interface IBuildingSettings {
  width?: number, length?: number, label?: string
  crop_type?: CROP_TYPE, crop_level?: number, disable_alarms?: boolean
  latitude?: number, longitude?: number

  sample_alarm_max_temperature?: number, sample_alarm_min_temperature?: number
  sample_alarm_max_emc?: number, sample_alarm_min_emc?: number
  ambient_alarm_max_temperature?: number, ambient_alarm_min_temperature?: number
  ambient_alarm_max_emc?: number, ambient_alarm_min_emc?: number
  
  current_min_temperature?: number, current_avg_temperature?: number
  current_max_temperature?: number, current_min_emc?: number
  current_avg_emc?: number, current_max_emc?: number

}
const BuildingAttributes = new AttributeList<IBuildingSettings>([
  Attr('label', 'string', 'entity', ''),
  Attr('latitude', 'number', 'telemetry', 0), 
  Attr('longitude', 'number', 'telemetry', 0),
  Attr('length', 'number', 'server', 20),
  Attr('width', 'number', 'server', 10),
  Attr('crop_type', 'string', 'server', ''),
  Attr('disable_alarms', 'boolean', 'server', false),

  //Attr('latitude', 'number', 'server', null),
  //Attr('longitude', 'number', 'server', null),

  Attr('sample_alarm_max_temperature', 'number', 'server', 60, true, true),
  Attr('sample_alarm_min_temperature', 'number', 'server', -20, true, true),
  Attr('sample_alarm_max_emc', 'number', 'server', 100, true, true),
  Attr('sample_alarm_min_emc', 'number', 'server', 0, true, true),
  Attr('ambient_alarm_max_temperature', 'number', 'server', 60, true, true),
  Attr('ambient_alarm_min_temperature', 'number', 'server', -20, true, true),
  Attr('ambient_alarm_max_emc', 'number', 'server', 100, true, true),
  Attr('ambient_alarm_min_emc', 'number', 'server', -20, true, true),
  Attr('current_min_emc', 'number', 'telemetry', null, false, false, 'Min Emc'),
  Attr('current_avg_emc', 'number', 'telemetry', null, false, false, 'Avg Emc'),
  Attr('current_max_emc', 'number', 'telemetry', null, false, false, 'Max Emc'),
  Attr('current_min_temperature', 'number', 'telemetry', null, false, false, 'Min Temp'),
  Attr('current_avg_temperature', 'number', 'telemetry', null, false, false, 'Avg Temp'),
  Attr('current_max_temperature', 'number', 'telemetry', null, false, false, 'Max Temp')
])


export interface IMeasurementSettings {
  label?: string
}
const MeasurementAttributes = new AttributeList<IMeasurementSettings>([
  Attr('label', 'string', 'entity', '')
])

export interface ITypeConfig {
  profiles: string[]
}

export class FarmerType<S extends EntityType, T> {
  constructor (
    public config: ITypeConfig, 
    public entityType: S, 
    public attributes: AttributeList<T>, 
    public createAttributes: (keyof T)[]=[]) {}

  

  getCreateAttributes () {
    return this.attributes.list(this.createAttributes)
  }
  createEntityId (id: string) {
    if (this.entityType == EntityType.ASSET) {
      let result: EntityId<EntityType.ASSET> = {
        id: id, entityType: EntityType.ASSET
      }
      return result
    } else if (this.entityType == EntityType.DEVICE) {
      let result: EntityId<EntityType.DEVICE> = {
        id: id, entityType: EntityType.DEVICE
      }
      return result
    } else throw new Error('unknown entity type: ' + this.entityType)
  }
}

const PROFILES = agTypes.farmerDeviceTypes

@Injectable()
export class FarmerTypes {
  silo = new FarmerType(
    {profiles: [agTypes.farmerDeviceTypes.silo.value]}, EntityType.ASSET, 
    SiloAttributes, ['label', 'diameter', 'height', 'eave_height'])

  building = new FarmerType({profiles: [agTypes.farmerDeviceTypes.building.value]}, 
    EntityType.ASSET, BuildingAttributes, ['label', 'width', 'length'])

  celluarSpear = new FarmerType({profiles: [agTypes.farmerDeviceTypes.cellularSensorSpear.value]}, EntityType.DEVICE, 
    SpearAttributes, ['label'])

  cable = new FarmerType({profiles: [PROFILES.sltCable.value, PROFILES.slmCable.value]}, 
    EntityType.DEVICE, SiloCableAttributes, ['label', 'silo'])

  tsmGateway = new FarmerType({profiles: [PROFILES.tmsGateway.value]}, 
    EntityType.DEVICE, TMSAttributes, ['label'])
  
  measurement = new FarmerType({profiles: MEASUREMENT_PROFILES}, 
    EntityType.DEVICE, MeasurementAttributes, ['label'])

  private types: FarmerType<any,any>[] = [
    this.silo, this.building, this.celluarSpear, this.cable, this.tsmGateway, this.measurement
  ]
  
  constructor () {}
  
  get (key: string) {
    let type = this.types.find(t => t.config.profiles.includes(key))
    if (!type) throw new Error('no such entity type: ' + key)
    return type
  }

  getCreateAttributes (type: FarmerType<any, any>) {
    if (type == this.celluarSpear) {
      return this.celluarSpear.attributes.attributes().filter(x => x)
    }
  }
}
