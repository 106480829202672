import { Component, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../state";
import { ConnectionService } from "./device.service";
import { getDatabase, get, ref, onValue } from 'firebase/database'

import { environment } from '../../environments/environment';
import { ToastController } from "@ionic/angular";

import { interval, Subscription, Subject } from 'rxjs'

import build from 'src/build.json'
import { captureMessage } from "@sentry/angular";
import { Capacitor } from "@capacitor/core";


const VERSION_KEY = '__version__'
const LAST_REFRESH_TIME_KEY = '__refresh_time__'

//import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';


@Component({
  selector: 'ag-upgrade-dialog',
  template: `
<div *ngIf="show" class="container">
  <div>new version {{version}} available</div>
  <button *ngIf="!loading" (click)="update()" class="button small-button">UPDATE</button>
  <ion-spinner *ngIf="loading" class="loader-spinner" name="dots"></ion-spinner>
</div>
  `,
  styles: [`
.container {
  top: 70px;
  box-shadow: 0px 1px 6px 1px grey;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  padding: 5px 10px;
  border-radius: 5px;
  color: #f6f6f6;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  background: white;
  color: #504e4e;
  display: flex;
  align-content: center;
  align-items: center;
}
.button {
  margin: 10px;
}
  `]
})
export class UpgradeNotification {
  constructor (
    private upgradeService: UpgradeService
  ) {}
  
  loading = false
  version = ''
  show = false
  
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  ngOnInit () {
    this.listen(this.upgradeService.onVersionAvailable.subscribe((newVersion) => {
      this.version = newVersion
      this.show = true
    }))
  }

  async update () {
    this.loading = true
    try {
      await this.upgradeService.doUpdate()
    } finally {
      this.loading = false
    }
  }
}

@Injectable()
export class UpgradeService {
  codeVersion: string
  latestVersion: string
  versionLabel: string = ''
  app: string
  message: string
  onVersionAvailable = new Subject<string>()

  constructor (
    private connection: ConnectionService,
    private store: Store<AppState>,
    private toastController: ToastController
  ) {
    this.init()
  }

  private async _doFullRefresh () {
    await this.clearCache()
    window.localStorage.setItem(LAST_REFRESH_TIME_KEY, new Date().getTime().toString())
    try {
      const reload = window.location.reload as any
      reload(true)
    } catch (err) {
      console.warn('soft reload')
      window.location.reload()
    }
  }

  async clearCache () {
    if('caches' in window){
      let keys = await caches.keys()
      for (var k of keys) {
        await caches.delete(k)
      }
    }
  }

  async fullReload () {
    this.connection.subscribe(conn => {
      // TODO: do not check for conn.connected - user might not be connected if not logged in
      if (conn.online) { 
        this._doFullRefresh()
      }
    })
  }

  initWeb () {
    if (environment.key != 'dev' && !Capacitor.isNativePlatform()) {
      this.watchVersionFirebase('version-' + environment.key)
    } else {
      // for testing
      // this.watchVersionPoll('0.0.0')
    }
  }

  async nativeCheckUpdate () {
    /* TODO: 
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    this.notifyNewVersion(result.availableVersion)
    */
  }

  async doUpdate () {
    /* TODO:
    if (Capacitor.isNativePlatform()) {
      try {
        const result = await AppUpdate.getAppUpdateInfo();
        if (result.immediateUpdateAllowed) {
          await AppUpdate.performImmediateUpdate();
        } else {
          await AppUpdate.openAppStore();
        }
      } catch (err) {
        console.error('failed to perform update, will try to open app store')
        await AppUpdate.openAppStore();
      }
    } else {
      this.fullReload()
    }*/
  }

  initNative () {
    this._poll_subscriber = interval(1000 * 60 * 10).subscribe(x => {
      this.nativeCheckUpdate()
    })
    this.nativeCheckUpdate()
  }

  init () {
    this.codeVersion = build.version 
    this.app = environment.app 
    this.versionLabel = environment.name + ' v' + this.codeVersion
    this.message = '' 
    if (Capacitor.isNativePlatform()) {
      this.initNative()
    } else {
      this.initWeb()
    }
  }

  _poll_subscriber: Subscription
  watchVersionPoll (version?) {
    version = version || build.version
    if (this._poll_subscriber) this._poll_subscriber.unsubscribe()
    this._poll_subscriber = interval(1000 * 10).subscribe(x => {
      this.shouldUpdate(version).then(newVersion => {
        if (newVersion) this.notifyNewVersion(newVersion)
        else this._toast?.dismiss()
      })
    })
  }

  async shouldUpdate (version) {
    let result = await fetch('/build.json')
    let config = JSON.parse(await result.text())
    if (config.version != version) {
      return config.version
    }
    return null
  }

  watchVersionFirebase (key: string) {
    let db = getDatabase()
    const versionRef = ref(db, key);
    onValue(versionRef, (snapshot) => {
      const version = snapshot.val();
      this.latestVersion = version
      let isLatest = version == this.codeVersion
      this.versionLabel = environment.name + ' v' + this.codeVersion + (isLatest ? ' (latest)' : ' (old)')
      
      if (version && version != this.codeVersion) {
        this.watchVersionPoll()
      }
    });
    
  }
  _toast = null
  _message: string = null
  async showFatalError (msg: string) {
    if (msg == this._message) return
    
    try {
      this._toast?.dismiss()
      this._toast?.getTop()?.dismiss()
      this._toast = null
    } catch (err) {
      captureMessage('failed to close fatal message dialog', 'warning')
    }
    
    this._toast = await this.toastController.create({
      message: msg,
      duration: 999999*99, 
      position: 'top',
      cssClass: 'checkout-toast',
      buttons: [
        {
          side: 'end',
          icon: 'refresh',
          text: 'Refresh',
          handler: () => {
            this.fullReload()
          }
        }
      ]
    });
    await this._toast.present();
    this._message = msg
  }

  async notifyNewVersion (version: string) {
    this.onVersionAvailable.next(version)
    //await this.showFatalError('New version available: ' + version)
  }
}

