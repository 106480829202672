import { EventEmitter } from "@angular/core"
import { captureException } from "@sentry/angular"
import { CROP_TYPE } from "src/app/constant"
import { Device } from "src/app/models/entity.model"
import { FarmerService } from "src/app/services/device.service"
import { ITelemetryData } from "src/app/services/telemetry"
import { IMeasurementSettings } from "src/app/services/types.service"
import { Index } from "src/app/util"

export const CPRO_KEYS = [
  "crop_name", "latitude", "longitude",
  "moisture_content", "temperature", "timestamp",
  "measurement", "comment"
]

export interface IMeassurementData {
  ts: number
  comment?: string
  calibration_offset?: string, meter_uuid?: string
  crop_name?: string, moisture_content?: string
  id?: string, temperature?: string, time_of_transfer?: string
  latitude?: string, timestamp?: string, type?: string
  longitude?: string, measurement?: string
}

export function LoadMeasurementTelemetry (data: ITelemetryData) {
  let values: Index<IMeassurementData> = {}
  CPRO_KEYS.map(key => {
    if (data.data[key]) {
      data.data[key].map(v => {
        if (!values[v[0]]) {
          values[v[0]] = {ts: v[0]}
        }
        values[v[0]][key] = v[1]
      })
    }
  })
  let items = Object.values(values)
      .map(x => LoadMeasurementItem(x))
      .filter(x => x != null).sort((m1, m2) => m2.ts - m1.ts)
  return items
}


function parseDeviceValue (value): number | null {
  let x = parseFloat(value)
  if (isNaN(x)) return null
  return x
}

export function LoadMeasurementItem (data: IMeassurementData): IDeviceMessurement | null {
  if (data.measurement) {
    try {
      let result: IDeviceMessurement = JSON.parse(data.measurement)
      result.ts = data.ts
      return result
    } catch (err) {
      captureException(err)
      return null
    }
  } else if (data.moisture_content || data.temperature) {
    let ts = data.ts
    if (!ts && data.time_of_transfer) {
      ts = parseInt(data.time_of_transfer) * 1000
    } else if (!ts && data.timestamp) {
      ts = parseInt(data.timestamp) * 1000
    } else if (!ts) return null

    let result: IDeviceMessurement = {
      comment: data.comment, cropType: data.crop_name as CROP_TYPE,
      latitude: parseDeviceValue(data.latitude), 
      longitude: parseDeviceValue(data.longitude),
      moistureContent: parseDeviceValue(data.moisture_content),
      temperatureContent: parseDeviceValue(data.temperature),
      ts: ts
    }
    return result
  } else {
    console.warn('cannot load measurement:', data)
  }
  return null
}

export interface IDeviceMessurement {
moistureContent: number | null, temperatureContent: number | null
  cropType: CROP_TYPE, comment: string
  ts: number, longitude: number | null, latitude: number | null
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export interface SAMoistureMeasurement {
  moisture_content: number;
  crop_name: string;
  calibration_offset: number; // calibration
  temperature: number; // temperature measurement

  meter_uuid?: string; // device id received from physical device
  device_id?: string; // device-id same as entity id
  
  measurement_uuid: string; // uuidv4
  measurement_type: 'avocado_moisture' | 'app_moisture';
  //measurement_meter_id: number; // id recevied from physical device, set -1 i guess
  description: string; // any description
  comment: string;
  image?: string;

  //location number type should be 32bit float for latitude and longitude
  longitude: number;
  latitude: number;

  //timestamp should be unix/epoch int
  timestamp: number; // seconds
  time_of_transfer: number; // seconds
}


export class MessurementSensor {
  constructor (
    private farmer: FarmerService, private entity: Device) {}

  onDataUpdate = new EventEmitter<MessurementSensor>()
  value: IDeviceMessurement
  latestSubscriber
  subscribe () {
    //let messurementKey = 'measurement'
    if (this.latestSubscriber) {
      this.latestSubscriber.unsubscribe()
    }
    this.latestSubscriber = this.farmer.globalContext.latest(this.entity.id, {
      keys: CPRO_KEYS
    }, {
      onData: (resp) => {
        
        let items = LoadMeasurementTelemetry(resp)
        if (items && items.length > 0) {
          this.value = items[0]
        }
        /*for (var key in resp.data) {
          let data = resp.data[key]
          let ts = data[0][0]
          if (key == messurementKey) {
            let value = JSON.parse(data[0][1]) as IDeviceMessurement
            if (value && typeof value == 'object') {
              this.value = value
              this.value.ts = ts
            }
          }
        }*/
        this.onDataUpdate.emit(this)
        return true
      }, 
      onLoading () {

      }
    })
  }
}

export class FarmerMeasurementDevice {
  onUpdate = new EventEmitter<FarmerMeasurementDevice>()
  load () { return this.onUpdate }
  model = this.farmer.createModel(this.farmer.types.measurement, this.entity)
  get settings () { return this.model.settings }
  
  constructor (public farmer: FarmerService, public entity: Device) {}
  _messurement: MessurementSensor
  messurement () {
    if (!this._messurement) {
      this._messurement = new MessurementSensor(this.farmer, this.entity)
      this._messurement.subscribe()
    }
    return this._messurement
  }
  async saveSettings (settings: Partial<IMeasurementSettings>) {
    await this.model.saveSettings(settings)
  }
  set_entity (entity: Device) {}

  subscribe (cb: ()=>void) {
    cb()
    return this.onUpdate.subscribe(cb)
  }
}