import { Component, Directive, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment-es6";
import { AuthService } from "src/app/services/auth.service";
import { IDeviceLine, ISubscriptionAddon, PaymentService, PaymentStatus } from "src/app/services/payment.service";
import { TEXT } from "src/app/texts";
import { deviceImage, Index, TIME_UNIT } from "src/app/util";



@Component({
  selector: 'ag-billing-page',
  template: `

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title [translate]="text.payment.billing"></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class='dialog-content' style="margin: 10px; padding-bottom: 15px;">
    <div class='billing-header' [translate]="text.payment.current_subscriptions"></div>
    <div class='subscriptions-list'>
      <div class='subscription-item' >
        <div *ngFor="let sub of subscriptions" class='subscription' [class.paid]="sub.status == 'PAID'" [class.notpaid]="sub.status == 'NOT_PAID'">
          
          <div class='subscription-content'>
            <div class='subscription-name'>{{sub.name}} <span *ngIf="sub.quantity > 0"> x {{sub.quantity}}</span></div>
            <!--  [translate]="text.payment.billed_every" [translateParams]="{period: sub.period, unit: sub.periodUnit}"-->
            <div class='subscription-period'>{{text.payment.billed_every|translate}} {{ {unit: sub.periodUnit, value: sub.period} | formatTime }}</div>
            <div class='subscription-info'>{{sub.info}}</div>
            <div class='subscription-status' [class.paid]="sub.status == 'PAID'">{{text.payment.status[sub.status.toLowerCase()]|translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class='billing-footer'>
      <ion-button mode="outline" (click)="openManager()" [translate]="text.payment.manage_subscriptions"></ion-button>
    </div>
  </div>
</ion-content>


  `,
  styles: [`
 
  .subscription {
    display: flex;
    padding: 10px;
    border: 1px solid #a1a1a1;
    border-radius: 10px;
    margin: 20px 10px;
    box-shadow: 1px 3px 7px 0px lightgrey;
  }

  .subscription-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .subscription-name {
    font-weight: bold;
    padding: 4px 0px;
  }
  .subscription-period {
    text-transform: uppercase;
    font-size: 12px;
  }
  .subscription-info {
    _text-transform: uppercase;
    font-size: 12px;
  }
  .subscription-status {
    position: absolute;
    right: 10px;
    top: 30%;
    display: inline-block;
    font-weight: bold;
    font-size: 20px;
  }

  .subscription.paid {
    border: 1px solid #22bedc;
  }
  .subscription.paid > .subscription-content > .subscription-status {
    color: #22bedc;
  }
  .subscription.notpaid {
    border: 1px solid #d53d0e;
  }
  .subscription.notpaid > .subscription-content > .subscription-status {
    color: #d53d0e;  
  }
  

  .device-pic {
    max-height: 60px;
  }
  .device-content {
    padding: 0px 10px;
  }
  .device-name {
    padding: 5px;
  }
  .device-status {
    padding: 5px;
  }
  .img-container {
    margin: 0px 10px;
  }

  .billing-footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
  .billing-header {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0px;
  }
  `]
})
export class BillingPage {
  subscriptions: IBillingSubscription[] = []
  text = TEXT

  constructor (
    private payment: PaymentService,
    private translate: TranslateService
  ) {}
  ngOnInit () {
    this.load()
  }
  async load () {
    await this.payment.fetch()
    let prices: Index<ISubscriptionAddon> = {}
    this.payment.plans.map(p => p.addons.map(a => prices[a.id] = a))
    this.subscriptions = []
    this.payment.subscriptions.map(s => {
      s.subscription_items.map(item => {
        let price = prices[item.price_id] // this.payment.plans.find(x => x.plan.id == s.price_id)
        if (!price) return
        let status: BILLING_STATUS
        let color = 'gray'
        if (s.due_invoices_count > 0) {
          status = 'NOT_PAID'
        } else {
          status = 'PAID'
        }
        let endOfTerm = moment(s.current_term_end * 1000).format("MMM DD, YYYY")
        let info = ''
        if (s.status == 'active') {
          if (item.scheduled_quantity == 0) {
            info = this.translate.instant(this.text.payment.cancel_on, {date: endOfTerm})
          } else if (item.scheduled_quantity != item.quantity) {
            info = this.translate.instant(
              this.text.payment.quantity_changed,
              {quantity: item.quantity, scheduled: item.scheduled_quantity, date: endOfTerm}
            )
          } else {
            info = this.translate.instant(this.text.payment.renews_on, {date: endOfTerm}) // 'Renews on ' + endOfTerm
          }
        } else if (s.status == 'non_renewing') {
          info = this.translate.instant(this.text.payment.not_renewing_info, {date: endOfTerm}) // 'Will Cancel at end of term'
        } else if (s.status == 'cancelled') {
          info = this.translate.instant(this.text.payment.cancelled) 
          status = 'CANCELLED'
          //period = ''
        } else {
          info = s.status
        }
        //let renewal = formatDate(new Date(s.next_billing_at * 1000), 'MMM d, y')
        let r: IBillingSubscription = {
          periodUnit: s.billing_period_unit.toLowerCase() as TIME_UNIT, 
          info: info, color: color, period: s.billing_period,
          name: price.external_name, quantity: item.quantity, status: status
        }
        this.subscriptions.push(r)
      })
    })
  }
  subscriptionStatus (device: IDeviceLine) {
    if (!device.subscription_id) return "UNSUBSCRIBED"
    return device.subscription_status
  }
  getDeviceImg (deviceType) {
    return deviceImage(deviceType)
  }
  async openManager () {
    await this.payment.openBilling()
  }
}

type BILLING_STATUS = 'PAID' | 'NOT_PAID' | 'CANCELLED'
interface IBillingSubscription {
  name: string, quantity: number, period: number, periodUnit: TIME_UNIT,
  info: string, color: string, status: BILLING_STATUS
}

/*
interface IBillingSettings {
  country?: string, taxid?: {value: string, type: string}
}


@Component({
  selector: 'ag-billing-form',
  template: `

<ion-content>
  <ion-list>

  <ion-item>
  <ion-select [(ngModel)]="settings.country" (ngModelChange)="onSelectCountry()" placeholder="Country" interface="popover" ok-text="Okay" cancel-text="Cancel">
    <ion-select-option *ngFor="let country of countries" [value]="country.code">{{country.country}}</ion-select-option>
  </ion-select>
</ion-item>

<ion-item>
  <ion-input placeholder="Name"></ion-input>
</ion-item>
<ion-item>
  <ion-input placeholder="E-Mail"></ion-input>
</ion-item>
<ion-item>
  <ion-input placeholder="Address 1"></ion-input>
</ion-item>
<ion-item>
  <ion-input placeholder="Address 2 (Optional)"></ion-input>
</ion-item>
<ion-item>
  <ion-input placeholder="City"></ion-input>
  <ion-input placeholder="Postcode"></ion-input>
</ion-item>

<ion-item *ngIf="settings.taxid">
  <ion-select [(ngModel)]="settings.taxid.type" placeholder="Tax Type" interface="popover" ok-text="Okay" cancel-text="Cancel">
    <ion-select-option *ngFor="let tax of listValidTaxTypes()" [value]="tax.type">{{tax.description}}</ion-select-option>
  </ion-select>
  <ion-input [(ngModel)]="settings.taxid.value" [placeholder]='getTaxType().example' interface="popover"></ion-input>
</ion-item>

<ion-item>
  <div stripeSubscription style="width: 100%;" ></div>
</ion-item>

</ion-list>

<ion-item>
  <ion-button>Save</ion-button>
</ion-item>
</ion-content>
`,
  styles: []
})
export class BillingForm {

  countries = [
    {"country": 'Denmark', "code": 'DK'}
  ]
  taxes = [
    {"country": "DK", "type": "eu_vat", "description": "European VAT number", "example": "DK12345678"}
  ]
  settings: IBillingSettings = {}

  onSelectCountry () {
    this.settings.taxid = {
      type: this.listValidTaxTypes()[0].type,
      value: ''
    }
  }

  getTaxType () { return this.taxes.find(x => x.type == this.settings.taxid.type) }
  listValidTaxTypes () {
    return this.taxes.filter(t => t.country == this.settings.country)
  }

}
*/