import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { agTypes } from "src/app/ag-types";
import { Asset, Device, Entity, IAlarm } from "src/app/models/entity.model";
import { AlarmService } from "src/app/services/alarm.service";
import { FarmerService } from "src/app/services/device.service";
import { deviceHasCrop, deviceIcon, deviceImage, LoadingState } from "src/app/util";
import { FarmerMeasurementDevice, IDeviceMessurement } from "./super-pro.model";




@Component({
  selector: 'farmer-measurement-item',
  template: `

<div *ngIf="isLoaded" class="card-container" >  
  <div (click)="onClick.emit()" fxFlex fxLayout="column"  class="card-content flex-auto" > 
    <div class="device-section flex-auto" style="flex: 0 !important" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex fxLayout="column" class="card-header-label">
        <div class="device-label">{{device.label}}</div>
      </div>
      <!--<ion-icon *ngIf="getDeviceIcon()" class="ag-device-icon" [src]="getDeviceIcon()"></ion-icon>-->
      <img *ngIf="getDeviceIcon()" class="ag-device-icon" [src]="getDeviceIcon()" />
    </div>

    <div class="flex-auto" fxFlex fxLayout="row" > 
      <div fxFlex fxLayout="column"  fxLayoutAlign="center start" style="padding: 10px;"> 
        <div fxLayout="row" *ngIf="messurement" class="moisture-row">
          <ion-icon class="icon" [ngStyle]="{fill: colors.minColor}" src="/assets/svg/iconset/ag-moisture.svg" ></ion-icon>  
          <span class="moisture-value">{{messurement.moistureContent | number:'1.1-1'}}%</span>
        </div>
        <div fxLayout="row" *ngIf="messurement && messurement.temperatureContent != null" class="moisture-row">
          <ion-icon class="icon" [ngStyle]="{fill: colors.maxColor}" src="/assets/svg/iconset/ag-thermometer.svg" ></ion-icon>  
          <span class="moisture-value">{{messurement.temperatureContent | number:'1.1-1'}}°</span>
        </div>
        <div *ngIf="messurement && deviceHasCrop()" class="moisture-crop">{{messurement.cropType | cropLabel | async}}</div>
      </div>
      <div class="device-pic-container" style="margin-right: 20px;" fxFlex fxLayout="row" fxLayoutAlign="end center">
        <img [src]="getDevicePic()" class="device-pic"> <!---->
      </div>
    </div>
  
  </div>
  
  <div class="device-section" fxLayout="row">
    <div *ngIf="messurement && messurement.ts" class="time-value">
      <ag-time-since [ts]="messurement.ts"></ag-time-since>
    </div>
  </div>
</div>

  `,
  styleUrls: ['../farmer-devices/farmer-device-item.scss']
})
export class FarmerMeasurementItem {
  @Input() device: Entity<any>
  @Output() onClick = new EventEmitter<Asset>();
  loadingState = new LoadingState()
  
  ctrl: FarmerMeasurementDevice
  colors = agTypes.colors
  alarm: IAlarm | null = null
  messurement: IDeviceMessurement
  sub: Subscription
  isLoaded = false
  
  constructor (private farmer: FarmerService, private alarmService: AlarmService) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  ngOnInit () {
    this.ctrl = this.farmer.getMeasurementDevice(this.device as Device)
    this.listen(this.ctrl.subscribe(() => this.initDevice()))
    this.listen(this.loadingState.events.subscribe(x => {
      this.isLoaded = this.loadingState.is_success()
    }))
  }
  initDevice () {
    if (this.sub) this.sub.unsubscribe()
    let sensor = this.ctrl.messurement()
    this.sub = sensor.onDataUpdate.subscribe(sensor => {
      this.updateMessurement()
    })
    this.updateMessurement()
    // TODO: should we have a new subscription list for initDevice?
    this.listen(this.alarmService.subscribe(alarms => {
      this.alarm = alarms.find(a => a.originator.id == this.device.id.id) || null
    }))
    this.loadingState.success()
  }

  updateMessurement () {
    let sensor = this.ctrl.messurement()
    this.messurement = sensor.value
  }
  
  getDeviceIcon() { return deviceIcon(this.device.type) }
  getDevicePic() { return deviceImage(this.device.type) }
  deviceHasCrop () { return deviceHasCrop(this.device.type) }
}