
export enum EntityType {
  TENANT = 'TENANT',
  TENANT_PROFILE = 'TENANT_PROFILE',
  CUSTOMER = 'CUSTOMER',
  USER = 'USER',
  DASHBOARD = 'DASHBOARD',
  ASSET = 'ASSET',
  DEVICE = 'DEVICE',
  DEVICE_PROFILE = 'DEVICE_PROFILE',
  ALARM = 'ALARM',
  RULE_CHAIN = 'RULE_CHAIN',
  RULE_NODE = 'RULE_NODE',
  ENTITY_VIEW = 'ENTITY_VIEW',
  WIDGETS_BUNDLE = 'WIDGETS_BUNDLE',
  WIDGET_TYPE = 'WIDGET_TYPE'
}

export enum AliasEntityType {
  CURRENT_CUSTOMER = 'CURRENT_CUSTOMER',
  CURRENT_TENANT = 'CURRENT_TENANT',
  CURRENT_USER = 'CURRENT_USER',
  CURRENT_USER_OWNER = 'CURRENT_USER_OWNER'
}

export interface EntityId<T extends EntityType | AliasEntityType> {
  id: string
  entityType: T;
}

export interface PageData<T> {
  data: Array<T>;
  totalPages: number;
  totalElements: number;
  hasNext: boolean;
}

export interface Entity<T extends EntityType> {
  createdTime?: number;
  id: EntityId<T>;
  name: string;
  label: string;
  type: string
}


export interface Index<T> { [key: string]: T }
export type ALARM_TYPE = 'NO_TELEMETRY_ALARM'
export type ALARM_SERVERITY = "CRITICAL"
export type ALARM_STATUS = "ACTIVE_UNACK" | "CLEARED_UNACK" | "ACTIVE_ACK" | "CLEARED_ACK"

export interface IAlarm {
  ackTs: number
  clearTs: number
  createdTime: number
  details: {message: string, value?: number} | Index<string>
  endTs: number
  id: EntityId<EntityType.ALARM>
  name: ALARM_TYPE
  originator: EntityId<EntityType.DEVICE | EntityType.ASSET>
  originatorName: string // device name
  propagate: boolean
  propagateRelationTypes: any[]
  severity: ALARM_SERVERITY
  startTs: number
  status: ALARM_STATUS
  tenantId: EntityId<EntityType.TENANT>
  type: ALARM_TYPE
}


export interface Device extends Entity<EntityType.DEVICE> {
  tenantId?: EntityId<EntityType.TENANT>;
  customerId?: EntityId<EntityType.CUSTOMER>;
  name: string;
  type: string;
  label: string;
  deviceProfileId?: EntityId<EntityType.DEVICE_PROFILE>;
  deviceData?: any; //DeviceData;
  additionalInfo?: any;
}

export interface Asset extends Entity<EntityType.ASSET> {
  name: string;
  type: string;
  label: string;
  
}

export enum EntityKeyType {
  ATTRIBUTE = 'ATTRIBUTE',
  CLIENT_ATTRIBUTE = 'CLIENT_ATTRIBUTE',
  SHARED_ATTRIBUTE = 'SHARED_ATTRIBUTE',
  SERVER_ATTRIBUTE = 'SERVER_ATTRIBUTE',
  TIME_SERIES = 'TIME_SERIES',
  ENTITY_FIELD = 'ENTITY_FIELD',
  ALARM_FIELD = 'ALARM_FIELD'
}

export interface EntityKey {
  type: EntityKeyType;
  key: string;
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface EntityDataSortOrder {
  key: EntityKey;
  direction: Direction;
}

export interface EntityDataPageLink {
  pageSize: number;
  page: number;
  textSearch?: string;
  sortOrder?: EntityDataSortOrder;
  dynamic?: boolean;
}
export interface TsValue {
  ts: number;
  value: string;
}

export interface EntityData {
  entityId: EntityId<any>;
  latest: {[entityKeyType: string]: {[key: string]: TsValue}};
  timeseries: {[key: string]: Array<TsValue>};
}

export enum Authority {
  SYS_ADMIN = 'SYS_ADMIN',
  TENANT_ADMIN = 'TENANT_ADMIN',
  CUSTOMER_USER = 'CUSTOMER_USER',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  ANONYMOUS = 'ANONYMOUS'
}

export interface User extends Entity<EntityType.USER> {
  tenantId: EntityId<EntityType.TENANT>;
  customerId: EntityId<EntityType.CUSTOMER>;
  email: string;
  authority: Authority;
  firstName: string;
  lastName: string;
  additionalInfo: any;
}


