// copied from https://ionicframework.com/docs/api/select - last example

import { Component, Input, Output, EventEmitter } from '@angular/core';
import type { OnInit } from '@angular/core';

export interface Item {
  text: string;
  value: string;
  searchString: string
};

@Component({
  selector: 'app-typeahead',
  template: `
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <!--<ion-buttons slot="end">
      <ion-button (click)="confirmChanges()">Done</ion-button>
    </ion-buttons>-->
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar (ionInput)="searchbarInput($event)"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-list id="modal-list" [inset]="true">
    <ion-item *ngFor="let item of filteredItems; trackBy: trackItems">
      <ion-label (click)="onSelect(item.value)">{{ item.text }}</ion-label>
      <!--<ion-checkbox
        [value]="item.value" 
        [checked]="isChecked(item.value)"
        (ionChange)="checkboxChange($event)"
      ></ion-checkbox>-->
    </ion-item>
  </ion-list>
</ion-content>
  `,
})
export class TypeaheadComponent implements OnInit {
  @Input() items: Item[] = [];
  @Input() selectedItems: string[] = [];
  @Input() title = 'Select Items';

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<string>();
  
  filteredItems: Item[] = [];
  workingSelectedValues: string[] = [];
  
  ngOnInit() {
    this.filteredItems = [...this.items];
    this.workingSelectedValues = [...this.selectedItems];
  }

  onSelect (value) {
    this.selectionChange.emit(value)
  }
  
  trackItems(index: number, item: Item) {
    return item.value;
  }
  
  cancelChanges() {
    this.selectionCancel.emit();
  }
  
  //confirmChanges() { this.selectionChange.emit(this.workingSelectedValues); }
  
  searchbarInput(ev) {
    this.filterList(ev.target.value);
  }
  
  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredItems = [...this.items];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase(); 
      this.filteredItems = this.items.filter(item => {
        return item.searchString.includes(normalizedQuery);
      });
    }
  }

  isChecked(value: string) {
    return this.workingSelectedValues.find(item => item === value);
  }
  
  checkboxChange(ev) {
    const { checked, value } = ev.detail;
    
    if (checked) {
      this.workingSelectedValues = [
        ...this.workingSelectedValues,
        value
      ]
    } else {
      this.workingSelectedValues = this.workingSelectedValues.filter(item => item !== value);
    }
  }
}