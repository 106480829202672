import QrScanner from 'qr-scanner';
// deprecated: QrScanner.WORKER_PATH = "/assets/qr-scanner-worker.min.js"

import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { TEXT } from 'src/app/texts';

export interface IScanResult {
  name: string, secretKey: string
}

function toScanResult (result: string): IScanResult {
  let parts = result.split('\\::')
  if (parts[0] == undefined || parts[1] == undefined) {
    let parts = result.split('/')
    if (parts.length > 1) {
      return {name: parts[0], secretKey: parts[1]}
    }
  }
  return {name: parts[0], secretKey: parts[1]}
}

@Directive({
  selector: '[agQrScan]'
})
export class QRScanDirective {
  @Output('onResult') onResult: EventEmitter<IScanResult> = new EventEmitter<IScanResult>()
  @Output('onError') onError: EventEmitter<string> = new EventEmitter<string>()
  @Output('onLog') onLog: EventEmitter<string> = new EventEmitter<string>()
  @Input('cameraId') cameraId: string

  private foundCodes: string[] = []
  private qrScanner
  private hasError = false
  constructor(
    public element: ElementRef,
    private translate: TranslateService
  ) {}
  
  async scanTest () {
    let result = await QrScanner.scanImage(this.element.nativeElement)
    this.emit(result)
  }

  emitError (string) {
    if (this.hasError) return // only emit one error per scan
    this.log('ERROR:' + string)
    this.onError.emit(string)
    this.hasError = true
  }

  emit (result: string) {
    if (this.foundCodes.includes(result)) {
      return
    }
    let scanResult = toScanResult(result)
    if (!scanResult.name || !scanResult.secretKey) {
      this.emitError(this.translate.instant(TEXT.claim.not_valid_qr))
      return false
    } else {
      this.onResult.emit(scanResult)
      return true
    }
  } 

  log (msg: string) {
    this.onLog.emit(msg)
  }

  async startNativeScan () {
    await BarcodeScanner.checkPermission({ force: true });
    BarcodeScanner.hideBackground();
    document.querySelector('body').classList.add('scanner-active');
    try {
      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        this.emit(result.content)
      } else {
        this.emitError(this.translate.instant(TEXT.claim.no_qr_found))
      }
    } catch (err) {
      this.emitError(err.toString())
    } finally {
      document.querySelector('body').classList.remove('scanner-active');
    }
  }

  async startWebcamScan () {
    if (this.qrScanner) {
      await this.qrScanner.stop()
    }
    this.log('starting webcam qr scanner')
    this.qrScanner = new QrScanner(this.element.nativeElement, result => {
      if (this.emit(result.data)) {
        this.log('stopping qr scanner')
        this.qrScanner.stop()
      }
    }, {preferredCamera: this.cameraId});
    this.qrScanner.start();
  }

  startScan () {
    try {
      if (Capacitor.isNativePlatform()) {
        this.startNativeScan()
      } else {
        this.startWebcamScan()
      }
    } catch (err) {
      this.emitError(err.toString())
    }
  }


  ngOnChanges (changes) {
    if (this.qrScanner) {
      this.qrScanner.setCamera(this.cameraId)
    }
  }
  ngOnInit () {
    this.hasError = false
    this.startScan()
  }
  ngOnDestroy () {
    if (this.qrScanner) this.qrScanner.stop()
    if (Capacitor.isNativePlatform()) {
      document.querySelector('body').classList.remove('scanner-active');
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
    }
  }
}
