import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface IToastMessage {
  message: string, type: 'error' | 'warning' | 'info', duration?: number
}

@Injectable()
export class ToastService {
  private notifications = new EventEmitter()

  constructor (
    private translate: TranslateService
  ) {}

  notify (data: IToastMessage) {
    if (data.message)
      data.message = this.translate.instant(data.message)
    this.notifications.emit(data)
  }
  subscribe (fn: (msg: IToastMessage) => void) {
    this.notifications.subscribe(fn)
  }
}
