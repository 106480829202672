import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Component, EventEmitter, Injectable, Input } from "@angular/core";
import { AlertController, ModalController, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { map, switchMap, tap } from "rxjs/operators";
import { AppState } from "../state";
import { AgModal, deviceImage, LoadingState, onAuthenticationChange, onConnectionState, onResume, TIME_UNIT } from "../util";
import { AuthService } from "./auth.service";

import { FarmerService } from "./device.service";
import * as Sentry from "@sentry/angular";
import { TimeseriesSubsciption } from "./telemetry";
import moment from "moment-es6";
import { Asset, Device } from "../models/entity.model";
import { agTypes } from "../ag-types";
import { UpgradeService } from "./upgrade.service";

import { captureException, captureMessage } from "@sentry/angular";
import { environment } from "src/environments/environment";

import { Subscription, interval, fromEvent } from 'rxjs';
import { take } from 'rxjs/operators'

import { Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Capacitor } from "@capacitor/core";
import { TEXT } from "../texts";
import { TranslateService } from "@ngx-translate/core";
import { COUNTRIES, ENTITIES, ICountry } from "../constant";


export interface ICreateSubscription {
  token: string
  coupon?: string, plan?: string
  email?: string, customerId?: string
}

export interface IPortalResponse {
  id: string, url: string, returnUrl: string
}
export interface IInvoiceEstimate {
  currency_code: string
  line_items: {
    amount: number, customer_id: string, date_from: number, date_to: number
    description: string, unit_amount: number, tax_amount: number
    tax_rate: number, subscription_id: string, is_taxed: boolean
    id: string, quantity: number
  }[]
  taxes: {
    amount: number, description: string, name: string
  }[]
  sub_total: number, total: number
}
export interface IEstimate {
  invoice_estimate: IInvoiceEstimate
}



export interface ICheckoutProfile {
  first_name?: string, last_name?: string, email?: string, vat_number?: string
  country_code?: string, is_company?: boolean, company_name?: string
}

export interface ICheckoutRequest {
  plan_id: string
  devices: string[]
  payment_method: PaymentMethod
  profile: ICheckoutProfile | null
  accepted_estimate: boolean
  is_profile_confirmed: boolean
  redirect_url?: string
}
export interface ICheckoutPage {
  type: 'CHECKOUT_PAGE'
  hosted_page: IHostedPage
}
function isCheckoutPage (x: any): x is ICheckoutPage {
  return x && x.type == 'CHECKOUT_PAGE'
}
export interface IConfirmPage {
  type: 'CONFIRM_PAGE'
  estimate: IEstimate
}
function isConfirmPage (x: any): x is IConfirmPage {
  return x && x.type == 'CONFIRM_PAGE'
}
interface ICheckoutCountry {
  country: string, code: string, name: string
}
interface ITaxType {
  country: string, type: string, prefix: string, description: string
  country_code: string
}

interface IFieldError {
  field: string, message: string
}
export interface ISetupPage {
  type: 'SETUP_PAGE'
  profile: ICheckoutProfile
  countries: ICheckoutCountry[]
  tax_types: ITaxType[]
  errors: IFieldError[]
  vat_countries: string[]
}
function isSetupPage (x: any): x is ISetupPage {
  return x && x.type == 'SETUP_PAGE'
}

interface ISelectPlanPage {
  type: 'SELECT_PLAN_PAGE'
  available_plans: ISubscriptionPlan[]
  available_payment_methods: PaymentMethod[]
}
function isSelectPlanPage (x: any): x is ISelectPlanPage {
  return x && x.type == 'SELECT_PLAN_PAGE'
}

export interface IPaymentState {
  site: string
  subscriptions: ISubscription[]
  devices: IDeviceLine[]
  ts: number
  prices: ISubscriptionPlan[]
}

export interface ICheckoutResponse {
  success: boolean, message: string, page?: ICheckoutPage | IConfirmPage
  estimate?: IEstimate, payment_state?: IPaymentState // TODO: make interface for payment state
}

enum PaymentMethod {
  CARD = 'CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
  OFFLINE = 'OFFLINE'
}

export interface IHostedPage {
  url: string, embed?: boolean
}



export interface ISubscriptionPlan {
  plan: ISubscriptionAddon, addons: ISubscriptionAddon[]
  available_payment_methods: string[]
}

export interface ISubscriptionAddon {
  id: string
  name: string
  currency_code: string
  period_unit: 'year' | 'month'
  period: number
  price: number
  pricing_model: 'per_unit'
  external_name: string
}

export interface IDeviceLine {
  device_id: string, device_name: string, 
  subscription_id: string, subscription_status: string
  checkout_id: string, 
  checkout_complete_id: string,
  is_free: boolean
  mode: 'ACTIVE' | 'TRIAL', period_end: number
  subtype: 'T' | 'TM', trial_end: number, claim_ts: number
  invoice_status: string, quote: string
}

export type SubscriptionStatus = 'future' | 'in_trial' | 'active' | 'non_renewing' | 'paused' | 'cancelled'
export interface ISubscription {
  id: string
  activated_at: number, current_term_end: number, current_term_start: number,
  next_billing_at: number, price_id: string, quantity: number,
  started_at: number, status: SubscriptionStatus
  billing_period: number, billing_period_unit: string
  due_invoices_count: number, due_since: number | null, total_dues: number | null
  subscription_items: ISubscriptionItem[]
}
export interface ISubscriptionItem {
  price_id: string
  quantity: number
  scheduled_quantity: number
}

export enum PaymentStatus {
  PAID='PAID', NOT_PAID='NOT_PAID', UNSUBSCRIBED='UNSUBSCRIBED',
  UNCONFIRMED='UNCONFIRMED', UNKNOWN='UNKNOWN'
}


@Component({
  selector: 'payment-complete-dialog',
  template: `
<div (click)="dismiss()" class='payment-complete-dialog'> 
  <div class='complete-header-image'>
    <img src="/assets/svg/check_success.svg" />
  </div>  
  <div class='complete-header-text'>{{text.payment.confirmed|translate}}!</div>
  <div class='complete-text'>
    {{text.payment.confirmed_info|translate}}
  </div>
</div>
  `,
  styles: [`
  
.complete-header-image {
  justify-content: center;
  display: flex;
  padding: 10px;
}
.complete-header-image > img {
  width: 90px;
}
.complete-header-text {
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  display: flex;
  padding: 10px;
}
.complete-text {
  font-size: 16px;
  padding: 10px 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  color: gray;
}

  `]
})
export class PaymentSuccessDialog {
  constructor (private modalRef: ModalController) {}

  text = TEXT
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  ngOnInit () {
    this.listen(
      interval(1000 * 15).pipe(take(1)).subscribe(value => this.dismiss())
    )
    this.listen(fromEvent(window, 'click', {capture: true}).subscribe(x => this.dismiss()))
    this.listen(fromEvent(window, 'mousedown', {capture: true}).subscribe(x => this.dismiss()))
    this.listen(fromEvent(window, 'touchstart', {capture: true}).subscribe(x => this.dismiss()))
  }
  async dismiss () {
    try {
      await this.modalRef.dismiss()
    } catch (err) {
      captureMessage('could not dismiss payment succes dialog', 'warning')
    }
  }
}

interface IDisplayPlan {
  period: number, period_unit: TIME_UNIT, 
  id: string, mmr: number, currency_code: string
}

enum CheckoutPage {
  SELECT_PAYMENT_TYPE = 'SELECT_PAYMENT_TYPE',
  SELECT_PLAN = 'SELECT_PLAN',
  //OFFLINE_SETUP = 'OFFLINE_SETUP',
  CONFIRM = 'CONFIRM',
  SETUP = 'SETUP_PAGE',
  REDIRECT = 'REDIRECT'
}

interface IPaymentMethod {
  id: PaymentMethod, title: string, description: string
}

const PAYMENT_METHODS: IPaymentMethod[] = [
  {id: PaymentMethod.CARD, title: TEXT.payment.card_method_title, description: TEXT.payment.card_method_info},
  {id: PaymentMethod.BANK_TRANSFER, title: TEXT.payment.bank_method_title, description: TEXT.payment.bank_method_info}
]

@Component({
  selector: 'invoice-estimate',
  styles: [`
.item-line {
  display: flex;
  margin: 5px 0;
}
.line-quantity {
  margin-right: 5px;
  font-size: 14px;
}
.line-description {
  font-size: 14px;
  flex-grow: 1;
}
.line-price {
  
}
.final-price {
  font-weight: bold;
}
  `],
  template: `

  <div *ngFor="let line of estimate.invoice_estimate.line_items" class='item-line'>
    <div class='line-quantity'>({{line.quantity}})</div>
    <div class='line-description'>{{line.description}}</div>
    <div class='line-price'>{{line.amount/100 | currency:estimate.invoice_estimate.currency_code}}</div>
  </div>
  <div class='item-line'>
    <div class='line-description'>{{text.payment.sub_total|translate}}:</div>
    <div class='line-price'>{{estimate.invoice_estimate.sub_total/100 | currency:estimate.invoice_estimate.currency_code}}</div>
  </div>
  <div *ngFor="let line of estimate.invoice_estimate.taxes" class='item-line'>
    <div class='line-description'>{{line.description}}</div>
    <div class='line-price'>{{line.amount/100 | currency:estimate.invoice_estimate.currency_code}}</div>
  </div>
  <div class='item-line'>
    <div class='line-description'>{{text.payment.total}}</div>
    <div class='line-price final-price'>{{estimate.invoice_estimate.total/100 | currency:estimate.invoice_estimate.currency_code}}</div>
  </div>
  `
})
export class InvoiceEstimate {
  @Input('estimate') estimate: IEstimate
  text = TEXT
}

@Component({
  selector: 'subscribe-form',
  template: `
<ion-header>
    <ion-toolbar>
    <ion-title [translate]="text.general.checkout"></ion-title>
    <ion-buttons slot="primary">
        <ion-button (click)="dismiss()" >
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="!loading.is_success()" class="dialog">
  <ag-loading [loading]="loading" [canRetry]="false" message="{{text.general.loading|translate}}" ></ag-loading>
</ion-content>
<ion-content *ngIf="loading.is_success()" class="dialog">
  <div class="dialog-background"></div>

  <div class="dialog-content" style="background: rgba(255,255,255,0.9);margin: 20px; padding-bottom: 15px;">
    <div class='checkout-cart'>
      <img class='image' [src]="image" />
      <div class='description'>
        <div class='amount'>{{devices.length}}x</div>
        <div class='name'>{{deviceType.name|translate}}</div>
      </div>
    </div>

    <div *ngIf="page == CheckoutPage.SETUP">
    <div class='checkout-header' [translate]="text.payment.billing_information"></div>
      <ion-list style="background: none;" >
        <ion-item>
          <ion-label position="stacked">{{text.payment.billing_email|translate}}</ion-label>
          <ion-input [(ngModel)]="profile.email" placeholder="{{text.payment.billing_email|translate}}"></ion-input>
        </ion-item>
           
          <select-country [value]="profile.country_code" [options]="countries" (onChange)="onCountryChanged($event)"></select-country>
          <!--
          <ion-label position="stacked" [translate]="text.payment.country"></ion-label>
          <ion-select (ionChange)="onCountryChanged()" [(ngModel)]="profile.country_code" placeholder="{{text.payment.select_country|translate}}" interface="popover" >
            <ion-select-option *ngFor="let country of countries" [value]="country.code">{{country.name|translate}}</ion-select-option>
          </ion-select>-->
          
        <ion-item>
          <ion-label [translate]="text.payment.iam_company"></ion-label>
          <!-- style="margin: 25px 10px;" -->
          <ion-checkbox name="is_company" [(ngModel)]="profile.is_company" slot="end"></ion-checkbox>
        </ion-item>
        <ion-item *ngIf="profile.is_company">
          <ion-label position="stacked" [translate]="text.payment.company_name"></ion-label>
          <ion-input [(ngModel)]="profile.company_name" placeholder="{{text.payment.company_name|translate}}"></ion-input>
        </ion-item>
        <ion-item *ngIf="profile.is_company && vatRequired">
          <ion-label position="stacked" [translate]="text.payment.vat_number"></ion-label>
          <div class="vat-input-container">
            <span *ngIf="getTaxType(profile.country_code)?.prefix" class="tax-prefix">{{getTaxType(profile.country_code)?.prefix}}</span>
            <ion-input [(ngModel)]="profile.vat_number" placeholder="{{text.payment.vat_number|translate}}"></ion-input>
          </div>
        </ion-item>
      </ion-list>
      <div class='input-error' *ngFor="let error of errors">
        {{error.message}}
      </div>
      <div class='checkout-footer'>
        <ion-button [disabled]="!isSetupValid()" (click)="confirmSetup()" [translate]="text.general.continue"></ion-button>
      </div>
    </div>

    <div *ngIf="page == CheckoutPage.SELECT_PAYMENT_TYPE">
      <div class='checkout-header' [translate]="text.payment.select_payment_method"></div> 
      <div class='checkout-content'>
      <div class='checkout-plans-container'>

        <div class='checkout-plan' style="background: white;" 
            (click)="selectPaymentMethod(paymentMethod)" *ngFor="let paymentMethod of availablePaymentMethods" 
            [class.active]="activePaymentMethod && activePaymentMethod == paymentMethod.id">
          <div *ngIf="activePaymentMethod && activePaymentMethod == paymentMethod.id" class='plan-active-indicator'>
            <div class='indicator-inner'></div>
          </div>
            <div class='payment-method-title'>{{paymentMethod.title|translate }}</div>
            <div class='payment-method-description'>
              {{paymentMethod.description|translate:{period: bank_transfer_period} }}
            </div>
          </div>
        </div>
      </div>
      <div class='checkout-note'>
        {{text.payment.checkout_notice|translate}}
      </div>
      <div class='checkout-footer'>
        <ion-button (click)="confirmPaymentMethod()" [translate]="text.general.continue"></ion-button>
      </div>
    </div>

    <div *ngIf="page == CheckoutPage.SELECT_PLAN" >
      <div class='checkout-content'>
        <div class='checkout-plans-header' [translate]="text.payment.choose_plan"></div>
        
        <div class='checkout-plans-container'>
          <div class='checkout-plan' style="background: white;" (click)="selectPlan(plan)" *ngFor="let plan of plans" 
              [class.active]="activePlan && activePlan.id == plan.id">
            <div *ngIf="activePlan && activePlan.id == plan.id" class='plan-active-indicator'>
              <div class='indicator-inner'></div>
            </div>
              <!-- billed every {{plan.period == 1 ? 'year': plan.period + ' years'}} -->
              <div class='checkout-plan-name' >{{text.payment.billed_every|translate}} {{ {unit: plan.period_unit, value: plan.period} | formatTime }}</div>
              <div class='checkout-plan-price'>
                <span class='price-amount'>{{plan.mmr | number:"0.2-2"}}</span>
                <span style="margin: 0 8px;" class='price-currency'>{{currencySymbol(plan.currency_code)}}</span>
                <span class='price-info'>/ {{text.payment.per_unit_month|translate}}</span>
              </div>
          </div>
        </div>
      </div>
      <div class='checkout-footer'>
        <ion-button (click)="checkout()" [disabled]="!activePlan" [translate]="text.general.checkout"></ion-button>
      </div>
    </div>

    <div *ngIf="page == CheckoutPage.CONFIRM" >
      <div class='checkout-header'>{{text.payment.accept_payment|translate}}:</div>    
      <div class='checkout-content'>
      <invoice-estimate [estimate]="this.estimate"></invoice-estimate>
      </div>
      <div class='checkout-footer'>
        <ion-button (click)="confirm()" [disabled]="!activePlan" [translate]="text.general.accept"></ion-button>
      </div>
    </div>

    <div *ngIf="page == CheckoutPage.REDIRECT">
      <div class='checkout-header' [translate]="text.payment.checkout_redirect_header"></div>
      <div class='checkout-content'>
        <div *ngIf="this.checkoutURL">
          <div [translate]="text.payment.checkout_redirect_info"></div>
          <a class="checkout-link" [href]="this.checkoutURL" [translate]="text.payment.checkout_redirect_link"></a>
        </div>
      </div>
      <div class='checkout-footer'>

      </div>
    </div>
    
  </div>
</ion-content>
`, styles: [`


.vat-input-container {
  display: flex;
  padding: 5px 0;
}
.tax-prefix {
  display: flex;
  align-self: center;
  padding: 5px 10px;
  background: #f4f4f4;
  border-radius: 5px;
  margin-right: 10px;
}

.input-error {
  color: red;
  align-self: center;
  text-align: center;
  padding: 10px;
}
.payment-method-title {
  padding: 5px 0px;
  font-size: 18px;
}
.payment-method-description {
  text-transform: uppercase;
  font-size: 12px;
}
.active {
  border: 1.5px solid #007aa3 !important;
}
.plan-active-indicator {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
  height: 15px;
  border: 1.5px solid #007aa3;
  border-radius: 45px;
}
.indicator-inner {
  background: #007aa3;
  width: 7px;
  height: 7px;
  border-radius: 45px;
  margin: 2.5px;
}
.checkout-plan {
  position: relative;
  padding: 20px 10px;
  border: 1.5px solid #cecece;
  border-radius: 5px;
  margin: 0 0 15px 0px;
}

.checkout-plan-name {
  text-transform: uppercase;
  font-size: 12px;
}

.checkout-plan-price {
  padding: 5px 0px;
  font-size: 18px;
}
.price-currency {
}
.price-amount {
}
.checkout-plan-description {
  font-size: 12px;
  font-weight: bold;
}

.checkout-header {
  _padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}
.checkout-plans-header {
  font-weight: bold;
  padding: 10px 0px;
}
.checkout-note {
  font-size: 14px;
  padding: 10px;
  margin: 5px 0px;
  background: #ffffff;
  border-radius: 5px;
}
.estimate-container {
  padding: 10px;
  background: white;
  border-radius: 5px;
  margin-bottom: 10px;
}
.estimate-header {
  font-size: 12px;
  font-weight: bold;
}

.checkout-content {
  padding: 10px;
}
.checkout-footer {
  display: flex;
  justify-content: center;
}
.checkout-cart {
  display: flex;
  margin-bottom: 10px;
}
.checkout-cart > .description {
  display: flex;
}
.checkout-cart .amount {
  font-size: 20px;
  font-weight: bold;
  align-self: center;
  margin-left: 15px;
}
.checkout-cart .image {
  max-height: 80px;
}
.checkout-cart .name {
  align-self: center;
  margin-left: 10px;
  margin-top: 3px;
  margin-left: 15px;
}
.checkout-link {
  display: block;
  text-align: center;
  width: 100%;
  padding: 20px;
}

`]
})
export class PaymentSubscriptionForm {
  activePlan: IDisplayPlan
  plans: IDisplayPlan[] // ISubscriptionPlan[]
  devices: IDeviceLine[]
  loading = new LoadingState()
  state = 'INPUT'
  profile: ICheckoutProfile = {}
  page: CheckoutPage
  estimate: IEstimate
  is_profile_confirmed = false
  errors: IFieldError[] = []
  tax_types: ITaxType[] = []
  text = TEXT
  vatRequired: boolean = true
  vatCountries: string[] = []
  checkoutURL: string | null = null

  bank_transfer_period = moment.duration(3, 'years').humanize()
  // NOTE: this does not work in older browsers
  //bank_transfer_period = new Intl.NumberFormat(this.translate.currentLang, { 
  //  style: 'unit', unit: 'year', unitDisplay: 'long' }).format(3)

  // TODO: support other types than CSS
  deviceType = ENTITIES.find(x => x.key == agTypes.farmerDeviceTypes.cellularSensorSpear.value)

  /*paymentMethods: IPaymentMethod[] = [
    {id: PaymentMethod.CARD, title: TEXT.payment.card_method_title, description: TEXT.payment.card_method_info},
    {id: PaymentMethod.BANK_TRANSFER, title: TEXT.payment.bank_method_title, description: TEXT.payment.bank_method_info}
  ]*/
  availablePaymentMethods: IPaymentMethod[] = []
  availablePlans: IDisplayPlan[] = []
  activePaymentMethod: PaymentMethod = PaymentMethod.CARD
  countries: ICountry[] = [] // ICheckoutCountry[] = []
  subscriptionPlans: ISubscriptionPlan[] = []

  constructor (
    private paymentService: PaymentService,
    private modalRef: ModalController,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {
  }

  getFieldError (field: string) {
    return this.errors.find(x => x.field == field)
  }

  getTaxType (countryCode: string) {
    return this.tax_types.find(x => x.country_code == countryCode)
  }

  makeCheckoutRequest (opt: Partial<ICheckoutRequest>) {
    let req: ICheckoutRequest
    let deviceIds = this.devices.map(d => d.device_id)
    //let profile = {...this.profile}
    
    // TODO: find a better way to test redirect on phone
    let redirect_url = environment.url
    if (Capacitor.isNativePlatform() && !environment.production) {
      redirect_url = 'https://app.agrolog.io'
    }
    req = {
      plan_id: this.activePlan?.id || null, devices: deviceIds, profile: null,
      payment_method: this.activePaymentMethod, accepted_estimate: false,
      is_profile_confirmed: this.is_profile_confirmed,
      redirect_url: redirect_url, ...opt
    }
    return req
  }

  showRedirectPage (page: ICheckoutPage) {
    this.page = CheckoutPage.REDIRECT
    this.checkoutURL = page.hosted_page.url
  }

  showConfirmPage (page: IConfirmPage) {
    this.estimate = page.estimate
    this.page = CheckoutPage.CONFIRM
  }
  onCountryChanged (country: ICountry) {
    if (!country) return
    this.profile.country_code = country.code
    this.vatRequired = this.vatCountries.includes(this.profile.country_code)
  }
  showSetupPage (page: ISetupPage) {
    this.page = CheckoutPage.SETUP
    this.profile = {...page.profile}
    this.countries = page.countries.map(x => {
      return {...x, name: x.country}
    }).filter(x => x && x.code && x.name).sort((a,b) => a.name.localeCompare(b.name))
    this.errors = page.errors
    this.vatCountries = page.vat_countries || []
    this.tax_types = page.tax_types
    
    this.onCountryChanged(this.countries.find(x => x.code == this.profile.country_code))

  }
  showSelectPlanPage (page: ISelectPlanPage) {
    this.page = CheckoutPage.SELECT_PAYMENT_TYPE
    this.plans = []
    
    this.availablePaymentMethods = PAYMENT_METHODS.filter(x => page.available_payment_methods.includes(x.id))
    
    // TODO: support plans other than year ?
    this.subscriptionPlans = page.available_plans.slice(0).filter(x => x.plan.period_unit == 'year')

    if (this.availablePaymentMethods.length == 1) {
      this.selectPaymentMethod(this.availablePaymentMethods[0])
      this.confirmPaymentMethod()
    }

    
  }
  

  isSetupValid () {
    let p = this.profile
    if (!p.email || !p.country_code) return false
    if (p.is_company && this.vatRequired && !p.vat_number) return false
    if (p.is_company && !p.company_name) return false
    return true
  }
  async confirmSetup () {
    this.is_profile_confirmed = true
    await this.doRequest(this.makeCheckoutRequest({profile: this.profile}))
  }

  async confirm () {
    await this.doRequest(this.makeCheckoutRequest({accepted_estimate: true}))
  }
  async confirmOffline () {
    await this.doRequest(this.makeCheckoutRequest({
      plan_id: this.plans.find(x => x.period == 3).id,
      payment_method: PaymentMethod.OFFLINE
    }))
  }
  async checkout () {
    await this.doRequest(this.makeCheckoutRequest({}))
  }
  async reset () {
    this.activePlan = null
    this.activePaymentMethod = PaymentMethod.CARD
    this.devices = []
    this.errors = []
    this.tax_types = []
    return await this.doRequest(this.makeCheckoutRequest({}))
  }

  selectPaymentMethod (method: IPaymentMethod) {
    this.activePaymentMethod = method.id
    
  }
  async confirmPaymentMethod () {
    // TODO: should check that the devices we're buying has any supported plan
    
    this.plans = this.subscriptionPlans.filter(x => x.available_payment_methods.includes(this.activePaymentMethod)).map(plan => {
      let p: IDisplayPlan = {
        id: plan.plan.id, currency_code: plan.plan.currency_code,
        mmr: this.planMMR(plan.addons[0]), period: plan.plan.period,
        period_unit: plan.plan.period_unit as TIME_UNIT
      }
      return p
    })
    if (!this.plans || this.plans.length == 0) {
      console.error('NO PLANS:', this.activePaymentMethod)
      throw new Error('no plans available')
    }
    if (!this.activePlan && this.plans.length > 0) {
      this.activePlan = this.plans[0]
    }
    
    if (this.page == CheckoutPage.SELECT_PAYMENT_TYPE) {
      if (this.plans.length == 1) {
        this.activePlan = this.plans[0]
        await this.checkout()
      } else {
        this.openSelectPlan()
      }
    }
  }

  async openSelectPlan () {
    this.page = CheckoutPage.SELECT_PLAN
  }

  get CheckoutPage () { return CheckoutPage }

  

  reload () {}

  // TODO: support multiple device types
  image = deviceImage('cellular_sensor_spear')

  $init = this.store.select(state => state).pipe(
    tap(x => this.loading.loading(true, 'initial loading')),
  )
  
  planMMR (plan: ISubscriptionAddon) {
    let months = 12
    if (plan.period_unit == 'month') months = 1
    let cents = Math.ceil(plan.price/months/plan.period)
    return cents / 100
  }

  currencySymbol (currency: string) {
    if (currency == 'EUR') return '€'
    return currency
  }
  
  async load () {
    this.estimate = null
    this.loading.loading(true)
    try {
      await this.reset()
      this.devices = this.paymentService.listUnsubscribedDevices()
      
      this.store.select(state => state.auth).subscribe(x => {
        if (!x.userDetails) {
          return this.loading.error('not authenticated')
        }
        if (!this.profile.first_name) {
          this.profile.first_name = x.userDetails.firstName
        }
        if (!this.profile.last_name) {
          this.profile.last_name = x.userDetails.lastName
        }
        if (!this.profile.email) {
          this.profile.email = x.userDetails.email
        }
      })
      if (!this.devices.length) return this.dismiss()
      this.loading.success()
    } catch (err) {
      captureMessage('failed to initialize checkout page', 'error')
      captureException(err)
      console.error(err)
      this.loading.error()
    }
  }

  dismiss () { 
    setTimeout(() => this.modalRef.dismiss(), 100)
  }

  selectPlan (plan) {
    this.activePlan = plan
    this.state = 'READY'
  }

  async doRequest (req: ICheckoutRequest) {
    this.loading.loading(true)
    try {
      
      let checkout: ICheckoutResponse = await this.paymentService._request('POST', 'checkout', req)
      if (checkout.payment_state) {
        this.paymentService.onPaymentData(checkout.payment_state)
      }
      if (!checkout.success) {
        if (checkout.message) {
          await this.paymentService.showCheckoutErrorMessage(checkout.message)
          this.dismiss()
        } else {
          await this.paymentService.handleChargebeeError(new Error('chargebee unsuccessful response: ' + JSON.stringify(checkout)))
        }
        return
      } else if (isCheckoutPage(checkout.page)) {
        this.loading.success()
        this.showRedirectPage(checkout.page)
        try {
          await this.paymentService.openCheckoutDialog(checkout.page.hosted_page)
        } catch {
          if (!checkout.page.hosted_page?.url) {
            this.dismiss()
          }
        }
        return
      } else if (isConfirmPage(checkout.page)) {
        return this.showConfirmPage(checkout.page)
      } else if (isSetupPage(checkout.page)) {
        return this.showSetupPage(checkout.page)
        } else if (isSelectPlanPage(checkout.page)) {
        return this.showSelectPlanPage(checkout.page)
      }
      if (checkout.message) {
        await this.paymentService.showCheckoutErrorMessage(checkout.message)
      }
      this.dismiss()
    } catch (err) {
      await this.paymentService.handleChargebeeError(err)
      //this.loading.error('Failed to checkout, please try again')
      this.dismiss()
    } finally {
      this.loading.success()
    }
    
  }

  ngOnInit () {
    this.load()    
    this.listen(fromEvent(document, 'visibilitychange').subscribe(x => {
      if (document.hidden && this.page == CheckoutPage.REDIRECT) {
        this.dismiss()
      }
    }))
  }
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
}

export interface IDevicePaymentState {
  active: boolean, isActivating: boolean, isSubscribed: boolean,
  status: string, canCheckout: boolean, message: string
  mode?: string, trial?: boolean
}


@Injectable()
export class PaymentService {
  onUpdate = new EventEmitter()
  host: string
  status: PaymentStatus = PaymentStatus.PAID
  paymentHosts: {devhost: string, host: string, testhost: string}
  paymentHost: string
  private paymentDialog: HTMLIonModalElement
  public isCheckoutOpen = false
  private completeDialog: HTMLIonModalElement

  plans: ISubscriptionPlan[] = []
  devices: IDeviceLine[] = []
  subscriptions: ISubscription[] = []
  chargebee
  checkoutToast: HTMLIonToastElement
  online = false

  async handleChargebeeError (err, msg?) {
    if (!msg) msg = this.translate.instant(this.text.payment.checkout_failed)
    Sentry.captureException(err);
    let errDialog = await this.alertController.create({
      message: msg,
      buttons: [
        {
          text: this.translate.instant(this.text.general.ok), 
          handler: () => {
            window.location.reload()
          }
        }
      ]
    })
    await errDialog.present()
    console.error(err)
  }
  
  async openSuccessDialog () {
    let url = new URL(location.href)
    this.updateActivatingState()
    if (url.searchParams.get('state') != 'succeeded') {
      return
    }
    url.searchParams.set('state', 'activating')
    window.history.replaceState({}, document.title, url.href);

    this.completeDialog = await this.modalController.create({
      component: PaymentSuccessDialog,
      cssClass: 'center-modal', showBackdrop: true, swipeToClose: true,
      componentProps: {}, backdropDismiss: true
    });
    try {
      await this.completeDialog.present()
      // TODO: need to fetch latest data to ensure we get the latest checkout_id for devices
      //       this should be replaced with something else
      await this.fetch() 
      await this.completeDialog.onDidDismiss()
    } finally {
      // remove url stuff
    }
  }

  async openPaymentDialog () {
    this.paymentDialog = await this.modalController.create({
      component: PaymentSubscriptionForm,
      
      componentProps: {}//, backdropDismiss: false
    });
    this.isCheckoutOpen = true
    this.onUpdate.emit()
    try {
      //await this.showCheckoutMessage()
      const { data } = await this.agModal.openModal(this.paymentDialog)
    } finally {
      this.isCheckoutOpen = false
      this.onUpdate.emit()
      //await this.showCheckoutMessage()
    }
  }

  private enabled: boolean = false
  isEnabled () {
    return this.enabled
    //return new URL(location.href).searchParams.has('payment')
  }

  public events = new EventEmitter<boolean>()

  private renderer: Renderer2;
  text = TEXT
  constructor (
    private http: HttpClient, 
    private store: Store<AppState>,
    private modalController: ModalController,
    private toastController: ToastController,
    private alertController: AlertController,
    private farmer: FarmerService,
    private auth: AuthService,
    private system: UpgradeService,
    private agModal: AgModal,
    private translate: TranslateService,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document
    ) {
      this.paymentHost = environment.payment
      this.enabled = true
      this.renderer = rendererFactory.createRenderer(null, null);
      this.initScript()
      this.openSuccessDialog()
      onResume().subscribe(x => this.openSuccessDialog())
      
      this.translate.onTranslationChange.subscribe(x => {
        this.onUpdate.emit()
        //this.resetCheckoutMessage()
      })
  }
  
  ChargebeeAPI: any
  onScriptLoaded (evt) {
    if (!window['Chargebee']) {
      console.error('failed to load chargebee')
      Sentry.captureMessage('user failed to load chargebee', 'error')
      setTimeout(() => this.onScriptLoaded(evt), 10 * 1000)
    } else {
      this.ChargebeeAPI = window['Chargebee']
      this.initialize()
    }
  }

  public initScript () {
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.chargebee.com/v2/chargebee.js'
    script.addEventListener('load', evt => this.onScriptLoaded(evt))
    script.addEventListener('error', evt => console.error('failed to load script', evt))
    //document.getElementsByTagName('head')[0].appendChild(script);
    this.renderer.appendChild(this._document.head, script);
  }

  async showCheckoutErrorMessage (message: string) {
    let dialog = await this.alertController.create({
      message: message
    })
    await dialog.present()
  }

  async teardown () {
    //this.closeCheckoutMessage()
    this.paymentDialog?.dismiss()
    this.onUpdate.emit()
  }  

  async _request (method: "POST" | "DELETE" | "GET" | "HEAD" | "JSONP" | "OPTIONS", path: string, data?) {
    return this.auth.getAuth().pipe(
      switchMap(auth => {
        let token = AuthService.getJwtToken()
        if (!token) throw new Error('token not available')
        let url = this.paymentHost + "/" + path
        return this.http.request(new HttpRequest(method, url, data, {
          
          headers: new HttpHeaders({
            
            'X-Authorization': token,
            //'FarmerHost': "farmerdev.agrolog.io",
            'Accept': "application/json"
          })
        })).pipe(
          map(x => x['body']) // TODO: handle corrently
        )
      })
    ).toPromise()
  }

  async sendDeletionRequest () {
    await this._request('POST', 'delete-account', {})
  }
  async updateCustomer (locale: string | null) {
    await this._request('POST', 'update-customer', {locale: locale})
  }

  async requestCheckout (req: ICheckoutRequest) {
    return await this._request('POST', 'checkout', req)
  }
  async requestAcceptEstimate (req: ICheckoutRequest) {
    return await this._request('POST', 'accept', req)
  }

  listen (callback) {
    if (!this.paymentHost || !this.isEnabled()) {
      setTimeout(() => this.listen(callback), 1 * 1000)
      return
    }
    // TEST TOKEN EXPIRE
    /*
    setTimeout(() => {
      localStorage.setItem('jwt_token_expiration', "0")
      localStorage.setItem('jwt_token', "fewfefwe")
    }, 10000)*/
    
    let host = new URL(this.paymentHost).hostname
    let url = 'wss://' + host + '/ws'
    let _this = this
    let state: {socket: WebSocket, token: string} = {
      socket: null, token: ''
    }
    function connect () {
      if (!state.token) {
        state.token = AuthService.getJwtToken()
      }
      if (!_this.online || !state.token) {
        window.setTimeout(() => connect(), 1000)
        return
      }
      const socket = new WebSocket(url);
      state.socket = socket
        
      const send = (type, data) => {
        socket.send(JSON.stringify({type: type, payload: data}))
      }
      function refreshToken () { 
        _this.auth.refreshJwtToken().toPromise().then(resp => {
          authenticate()
        }).catch(err => {
          console.error(err)
          console.error('failed to refresh token')
          state.token = ''
          socket.close()
        })
      }
      function authenticate () {
        let token = AuthService.getJwtToken()
        state.token = token
        if (token) {
          send('AUTHENTICATE', {token: token})
        } else {
          setTimeout(() => refreshToken(), 2000)
        }
      }
      socket.addEventListener('message', ev => {
        let data = JSON.parse(ev.data)
        if (data.type == 'DATA') {
          callback(data.result)
        } else if (data.type == 'ERROR') {
          if (data.code == 401) {
            refreshToken()
          }
        }
      });
      socket.addEventListener('open', e => {
        authenticate()
      })
      socket.addEventListener('close', e => {
        //console.error('SOCKET CLOSED', e)
        setTimeout(() => connect(), 2 * 1000)
      })
      socket.addEventListener('error', e => {
        //console.error('payment websocket error')
        socket.close()
      })
      return socket
    }

    onAuthenticationChange(this.store).subscribe(x => {
      if (x) {
        state.token = AuthService.getJwtToken()
        if (!state.socket) {
          connect()
        }
      } else {
        state.token = ''
        state.socket?.close()
      }
    })
  }

  paymentSubscription: TimeseriesSubsciption = null
  async initialize () {
    onConnectionState().subscribe((online) => {
      this.online = online
    })
    this.listen((msg) => {
      this.onPaymentData(msg)
    })
    //this.host = environment.api
    
    /*
    this.store.select((state) => state.host).subscribe(host => {
      this.host = host.api
      this.paymentHost = environment.payment
      this.enabled = true
    })*/
    this.store.select((state) => state.auth).subscribe(auth => {
      let customerId = auth.authUser?.customerId
      if (customerId) {
        let paymentData = localStorage.getItem('PAYMENT-' + customerId)
        if (paymentData) {
          this.onPaymentData(JSON.parse(paymentData))
        }
      } else {
        this.clear()
      }
    })
  }
  clear () {
    this.plans = []
    this.devices = []
    this.subscriptions = []
    this.teardown()
  }
  savePaymentState (state: IPaymentState) {
    this.store.select((state) => state.auth).subscribe(auth => {
      let customerId = auth.authUser?.customerId
      if (customerId) {
        const key = 'PAYMENT-' + customerId
        try {
          localStorage.setItem(key, JSON.stringify(state))
        } catch (err) {
          captureMessage('failed to save payment-state in local storage', 'error')
          localStorage.removeItem('PAYMENT-' + customerId)
        }
      }
    })
  }

  

  loaded = false
  onPaymentData (result: IPaymentState) {
    try {
      this.farmer.markDirty(result.ts)
    } catch (err) {
      captureMessage('failed to mark entities as dirty', 'error')
    }
    this.savePaymentState(result)
    try {
      this.loadSite(result.site)
      this.plans = result.prices
      this.devices = result.devices
      this.subscriptions = result.subscriptions
      this.updateActivatingState()
      this.loaded = true
      this.onUpdate.emit()
    } catch (err) {
      captureException(err)
      console.error(err)
      this.system.showFatalError('failed to load payment system')
    }
  }
  
  subscribe (cb: () => void) {
    cb()
    return this.onUpdate.subscribe(cb)
  }

  checkoutInProgress: string[] = []
  listUnsubscribedDevices () {
    let deviceIds = this.farmer.deviceEntities.map(x => x.id.id)
    return this.devices.filter(x => deviceIds.includes(x.device_id) && this.stateFromId(x.device_id).canCheckout)
  }
  updateActivatingState () {
    let url = new URL(location.href)
    let checkoutId = url.searchParams.get('id')
    let checkoutState = url.searchParams.get('state')
    
    this.checkoutInProgress = []
    if (checkoutId) {
      this.checkoutInProgress = [checkoutId]
      this.onUpdate.emit() // NOTE: ui need to refresh based on new checkout in progress
      let device = this.devices.find(x => x.checkout_complete_id == checkoutId)
      if (!device) return console.warn('no device with checkout-id')
      let state = this.stateFromId(device.device_id)
      if (!state.isActivating) {
        this.checkoutInProgress = []
        url.searchParams.delete('id')
        url.searchParams.delete('state')
        window.history.replaceState({}, document.title, url.href);
      }
    }
  }

  isTypeSubscrible (type: string) {
    return [agTypes.farmerDeviceTypes.cellularSensorSpear.value].includes(type)
  }
  canAccessEntity (entity: Device | Asset) {
    return this.getEntityState(entity)?.active
  }
  getEntityState (entity: Asset | Device) {
    if (!this.loaded) return null
    if (entity.id.entityType == 'ASSET') return this.getAssetState(entity as Asset)
    else return this.getDeviceState(entity as Device)
  }
  getAssetState (asset: Asset) {
    let devices = this.farmer.getBuildingDevices(asset)

    let validState: IDevicePaymentState = {
      active: true, canCheckout: false, isActivating: false, isSubscribed: true,
      message: '', status: ''
    }
    if (devices.length == 0) return validState
    let device = devices.find(x => {
      if (!this.isTypeSubscrible(x.type)) return false
      if (!this.canAccessEntity(x)) return true
      return false
    })
    if (!device) {
      device = devices.find(x => this.getDeviceState(x)?.trial)
    }
    if (device) {
      let result = this.getDeviceState(device, 'ASSET')
      return result
    }
    else return validState
  }
  getDeviceState (entity: Device, type='DEVICE') {
    let validState: IDevicePaymentState = {
      active: true, canCheckout: false, isActivating: false, isSubscribed: true,
      message: '', status: ''
    }
    if (!this.isTypeSubscrible(entity.type)) return validState
    return this.stateFromId(entity.id.id, type=type)
    
  }
  private stateFromId (id: string, type='DEVICE') {
    let device = this.devices.find(x => x.device_id == id)
    if (!device) return null
    
    let msg = ""
    let subscription = null
    if (device.subscription_id)
      subscription = this.subscriptions.find(x => x.id == device.subscription_id)

    let activeStatus = ['active', 'non_renewing']
    let isSubscribed = !!(device.subscription_id)
    let inProgress = device.subscription_status == 'future' || device.invoice_status == 'requested' || (device.quote && device.invoice_status == 'quoted') // device.invoice_status == 'progress'
    let isInvoiced = !!(device.invoice_status) && device.invoice_status != 'voided'
    let invoicePaid = !device.invoice_status || (isInvoiced && device.invoice_status == 'paid')
    let isPaid = device.is_free || (isSubscribed && invoicePaid && activeStatus.includes(device.subscription_status))
    let isActivating = (!isPaid && !isInvoiced) && this.checkoutInProgress.includes(device.checkout_id)
    let canCheckout = (!isInvoiced || invoicePaid) && !isSubscribed && !isActivating && !isPaid && !inProgress
    let isActive = isPaid || device.mode == 'TRIAL' 
    if (isActivating) {
      msg = this.translate.instant(this.text.payment.activating)
    } else if (!isPaid && device.mode == 'TRIAL') {
      let prefix = ''
      if (isInvoiced && !invoicePaid) {
        prefix = this.translate.instant(this.text.payment.awaiting_payment) + ' - ' // [' + device.invoice_status + ']'
      }
      msg = prefix + this.translate.instant(this.text.payment.trials_end_at, {date: moment(device.trial_end * 1000).format("DD/MM/YY")})
    } else if (isInvoiced && !invoicePaid) {
      msg = this.translate.instant(this.text.payment.awaiting_payment) // [' + device.invoice_status + ']'
    } else if (canCheckout) {
      msg = this.translate.instant(this.text.payment.device_checkout_required)
      if (type == 'ASSET') {
        msg = this.translate.instant(this.text.payment.building_checkout_required)
      }
    } else if (device.subscription_status == 'cancelled') {
      msg = this.translate.instant(this.text.payment.subscription_cancelled)
    } else if (device.subscription_status == 'non_renewing' && subscription && subscription.current_term_end) {
      msg = this.translate.instant(this.text.payment.cancel_scheduled, {date: moment(subscription.current_term_end * 1000).format("MM/DD/YY")})
    }
    
    let state: IDevicePaymentState = {
      active: isActive, isActivating: isActivating, trial: device.mode == 'TRIAL',
      status: device.subscription_status, canCheckout: canCheckout,
      message: msg, isSubscribed: isSubscribed
    }
    return state
  }

  loadSite (site) {
    if (!this.chargebee) {
      this.chargebee = this.ChargebeeAPI.init({
        site: site, 
        enableRedirectMode: true
        //iframeOnly: true
      });
    }
  }

  async fetch () {
    if (!this.isEnabled()) return
    let result = await this._request('POST', 'refresh')
    this.onPaymentData(result)
  }

  async openBilling () {
    let portal = await this._request('GET', 'portal')
    let url = portal?.access_url
    if (url)
      window.location.href = url
  }

  async openCheckoutDialog (page: IHostedPage) {
    try {
        this.chargebee.openCheckout({
          hostedPage: () => {
            return Promise.resolve(page)
          },
          loaded: () => {
          },
          success: (hostedPageId) => {
          },
          close: () => {
          }, 
          error: (err) => { 
            console.error('checkout page error', err)
            if (err == "Already another checkout is in progress") {
              console.warn('checkout already in progress will be ignored')
              return
            }
            //reject('failed to open checkout: ' + err) 
          },
          step (stepId) {  }
        });
      } catch (err) {
        console.error('CHECKOUT ERROR')
        console.error(err)
        throw err
      }
  }
  

}



@Component({
  selector: 'ag-checkout-popover',
  template: `
<div *ngIf="show" (click)="this.onClick()" class="container">
  <div class='section' [translate]="text.payment.unsubscribed_message"></div>
  <div class='section' style="color: #ed6d05">
    <ion-icon style="font-size: 20px;" name="cart" ></ion-icon>  
    <ion-label style="text-transform: uppercase;" [translate]="text.general.checkout"></ion-label>
  </div>
</div>
  `,
  styles: [`
.container {
  max-width: 240px;
  width: 240px;
  z-index: 20004 !important;

  display: flex;
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  color: #f6f6f6;
  background: white;
  color: #d7d7d7;
  
  background: #333333;
  cursor: pointer;
}
.section {
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
  `]
})
export class CheckoutPopover {
  constructor (
    public paymentService: PaymentService
  ) {}

  text = TEXT
  show = false
  
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  ngOnInit () {
    this.listen(
      this.paymentService.subscribe(() => this.onPaymentStateUpdate() )
    )
  }

  onClick () {
    this.paymentService.openPaymentDialog()
  }

  onPaymentStateUpdate () {
    let isEnabled = this.paymentService.isEnabled()
    let isCheckoutOpen = this.paymentService.isCheckoutOpen
    let haveUnsubscribedDevices = this.paymentService.listUnsubscribedDevices().length > 0
    this.show = isEnabled && haveUnsubscribedDevices && !isCheckoutOpen
  }
}